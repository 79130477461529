import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import * as CallICON from '@lib/mir-akhter-phone.json';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import {white} from '@assets/css/globalStyleVars';

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const CallButton = ({targetSection}) => {
  const [offset, setOffset] = useState(90);

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth > 767) {
        const containerElement = document.querySelector('.container');
        if (containerElement) {
          setOffset(containerElement.offsetLeft + 15);
        }
      }
    };

    updateOffset();

    // Update the offset when the window is resized
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, []);


  // animation
  const themedark = {
    loop: true,
    autoplay: true,
    animationData: CallICON,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  return (
    <StyledCallButton>
      <div className="call-button"
        style={{
          cursor: 'pointer',
          position: 'fixed',
          bottom: '40px',
          zIndex: 2,
          right: `${offset}px`,
        }}

      >
        <a href={'tel:00110011'}>
          <Lottie options={themedark}
            height={30}
            width={30}/>
        </a>
      </div>
    </StyledCallButton>
  );
};
const StyledCallButton = styled.div`

    .call-button {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: ${white};
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(24, 29, 36, 0.3) 5px 10px 30px;
    }
`;

export default CallButton;
