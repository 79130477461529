import React from 'react';
import styled from 'styled-components';

const UpIcon = () => {
  return (
    <StyledUpIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Group_18588" data-name="Group 18588" transform="translate(-423 -1309)">
          <rect id="Rectangle_6525" data-name="Rectangle 6525" width="30" height="30" rx="5" transform="translate(423 1309)" fill="#fff"/>
          <g id="Group_18517" data-name="Group 18517" transform="translate(1041.606 2929.26) rotate(-135)">
            <path id="Path_9568" data-name="Path 9568" d="M0,6,6,0l6,6" transform="translate(1567.904 714.277) rotate(180)" fill="none" stroke="#293a8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_9569" data-name="Path 9569" d="M0,12V0" transform="translate(1561.904 702.277)" fill="none" stroke="#293a8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          </g>
        </g>
      </svg>

    </StyledUpIcon>
  );
};

const StyledUpIcon = styled.div`
    #Rectangle_6525, #Group_18503 {
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;

    }

    &:hover {

        #Rectangle_6525 {
            rx: 30px;
            //transform: translate(388px, 2181px) rotate(-90deg);
        }

        #Group_18503 {
        }

    }
`;

export default React.memo(UpIcon);
