import { useEffect, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { useLocation } from 'react-router-dom';

// Register plugins with gsap
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const useSmoothScroll = (mainRef) => {
  const smoother = useRef(null);
  const location = useLocation();

  useIsomorphicLayoutEffect(() => {
    const isMobile = window?.innerWidth < 992;

    const ctx = gsap.context(() => {
      if (!ScrollTrigger.isTouch) {
        smoother.current = ScrollSmoother.create({
          wrapper: '#smooth-wrapper',
          content: '#smooth-content',
          smooth: isMobile ? 0 : 3,
          effects: isMobile ? false : true,
          normalizeScroll: {
            allowNestedScroll: isMobile ? false : true,
          },
          ignoreMobileResize: isMobile ? false : true,
          preventDefault: true,
        }, mainRef.current);
      }
    }, mainRef.current);

    if (location.hash) {
      setTimeout(() => {
        smoother.current.scrollTo(location.hash, 3);
      }, 500);
    }

    return () => {
      ctx.revert();
    };
  }, [location.pathname, location.hash, mainRef]);

  return smoother;
};

export default useSmoothScroll;
