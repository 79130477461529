import React, {useEffect} from 'react';
import styled from 'styled-components';
import gsap, {CSSPlugin} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts} from '../../api/redux/services';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
import ServicesListBox from './services-list-box/ListBox';
import ServicesOverviewList from './services-overview-list/OverviewList';
import {ApiParam} from '../../api/network/apiParams';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../lib/PageAnimation';
import ServiceBanner from '@pages/service/service-banner';
import ServicesCta from '@pages/service/services-cta';

gsap.registerPlugin(CSSPlugin, ScrollTrigger, SplitText);


const Insights = () => {

  const dispath = useDispatch();
  const location = useLocation();



  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'services',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));

  }, [dispath, location?.pathname]);

  let getPost = useSelector(store => store.services);

  let page_data = getPost?.posts?.data?.page_data;


  const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'services-banner');
  const financial = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'financial-services');
  const servicesList = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'services-list-services');
  const cta = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'cta-services');


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'188'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledSection>
        <ServiceBanner noPara title={banner?.section_data?.subtitle}
          imageSrc={banner?.images?.list?.[0]?.full_path}/>

        <ServicesListBox data={financial}/>


        {
          servicesList?.posts?.list && servicesList?.posts?.list?.length > 0 &&
          servicesList?.posts?.list?.map((e, index) => {
            if (index === 0) {
              return (
                // eslint-disable-next-line react/jsx-key
                <ServicesOverviewList key={index} first_data={servicesList} data={e}/>
              );
            }
            if (index % 2 === 1) {
              return (
                // eslint-disable-next-line react/jsx-key
                <ServicesOverviewList key={index} notfirst reverse data={e} padding={'pb-150'}/>

              );
            }
            if (index % 2 === 0) {
              return (
                // eslint-disable-next-line react/jsx-key
                <ServicesOverviewList key={index} notfirst padding={'pb-150'} data={e}/>

              );
            }
          })
        }

        <ServicesCta cta={cta}/>

      </StyledSection>
    </HelmetProvider>

  );
};

const StyledSection = styled.section`
    .single-item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .logo-brand {
        opacity: 1 !important;
        visibility: visible !important;
    }

`;

export default Insights;
