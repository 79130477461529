import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import {Col, Container, Row} from 'react-bootstrap';
import CloseIcon from '@components/mir-ui/svg/CloseIcon';
import reactHtmlParser from 'react-html-parser';

const TextPopup = ({
  show,
  handleClose,
  no_img,
  item,
  title,
  description,
  data,
  subtitle,
  img
}) => {


  const popupData = data;

  return (

    <StyledModal>
      <Modal
        show={show}
        item={item}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="gph_modal popup-version-one"
      >
        <div className="noise"></div>

        <div className="main_scroll" style={{height: '100vh'}}>
          <Modal.Body>

            <Container>
              <Row className={'for-close'}>
                <div onClick={handleClose} className="modal-close ">
                  <CloseIcon/>
                </div>

              </Row>
            </Container>
            <Container>
              <Row>
                <div className="modal-data modal-main-content d-flex">
                  <Col sm={10} className="">
                    <div className="profile-details">

                      {
                        reactHtmlParser(popupData?.data?.description ? popupData?.data?.description : popupData?.data?.body)
                      }
                    </div>
                  </Col>


                </div>
              </Row>
            </Container>
          </Modal.Body>
        </div>
      </Modal>
    </StyledModal>

  );
};


const StyledModal = styled.div`

    .modal-dialog {
        margin: 0;
    }

    h4 {
        letter-spacing: 1.5px;
    }

`;


export default React.memo(TextPopup);