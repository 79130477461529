import React, {useEffect} from 'react';
import styled from 'styled-components';
import gsap, {CSSPlugin} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {fetchPosts} from '@api/redux/services';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
import {ApiParam} from '@api/network/apiParams';
import {motion} from 'framer-motion';
import {PageAnimation} from '@lib/PageAnimation';
import reactHtmlParser from 'react-html-parser';
import ThemeContainer from '../../theme/container';

gsap.registerPlugin(CSSPlugin, ScrollTrigger, SplitText);


const Insights = () => {

  const dispath = useDispatch();
  const location = useLocation();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'privacy-policy',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));

  }, [dispath, location?.pathname]);

  let getPost = useSelector(store => store.services);

  let page_data = getPost?.posts?.data?.page_data;



  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'188'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledSection>

        <div className="details">
          <ThemeContainer colProps={{md:12}}>
            <h2>{reactHtmlParser(page_data?.title)}</h2>
            {
              reactHtmlParser(page_data?.description)
            }
          </ThemeContainer>
        </div>

      </StyledSection>
    </HelmetProvider>

  );
};

const StyledSection = styled.section`

    padding-top: 200px;
    padding-bottom: 120px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);

    @media(max-width: 767px){
        
    }

    p {
        margin-bottom: 10px;
    }

    h2 {
        margin-bottom: 40px;
    }

    h3, h4, h5 {
        margin-bottom: 15px;
    }

    ul {
        padding-left: 25px;
        margin-bottom: 15px;

        li {
            list-style: disc;
        }
    }

    .single-item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .logo-brand {
        opacity: 1 !important;
        visibility: visible !important;
    }

`;

export default Insights;
