import React, {useRef} from 'react';
import styled from 'styled-components';

export const ImageParallax = ({src, alt, no_para, objectFit, position, height, width, banner, left, margin, right, top, bottom}) => {
  // const [imageSrc, setImageSrc] = useState(null);
  const imgRef = useRef(null);

  const DefaultBanner = '/images/static/blurbg.svg';



  // const handleIntersection = (entries, observer) => {
  //   entries.forEach((entry) => {
  //     if (entry.isIntersecting) {
  //       // Image is in the viewport
  //       if (imageSrc !== src) {
  //         // Check if src is not already set
  //         setImageSrc(src);
  //       }
  //       observer.unobserve(entry.target); // Stop observing once the image is in view
  //     }
  //   });
  // };
  //
  // useEffect(() => {
  //   const options = {
  //     root: null, // viewport
  //     rootMargin: '0px',
  //     threshold: 0.1, // 10% of the image must be in the viewport
  //   };
  //
  //   const observer = new IntersectionObserver(handleIntersection, options);
  //
  //   if (imgRef.current) {
  //     observer.observe(imgRef.current);
  //   }
  //
  //   return () => {
  //     // Cleanup the observer when the component unmounts
  //     observer.disconnect();
  //   };
  // }, [src]);


  return (
    <StyledImg className="global-image parallax" position={position} objectFit={objectFit} margin={margin}  left={left}
      right={right} top={top}
      bottom={bottom} height={height} width={width}>
      {
        no_para ?

          <img ref={imgRef}
            src={src || DefaultBanner}
            alt={alt || ''}/>
          :
          <img ref={imgRef}
            src={src || DefaultBanner}
            alt={alt || ''} data-speed={'0.2'}/>

      }

    </StyledImg>
  );
};

const StyledImg = styled.div`
    position: ${props => props.position || 'absolute'};
    height: ${props => props.height || '100%'};
    width: ${props => props.width || '100%'};
    top: ${props => props.top || 0};
    left: ${props => props.left || 0};
    bottom: ${props => props.bottom || 0};
    right: ${props => props.right || 0};
    margin: ${props => props.margin || 0};

    img {
        width: 100%;
        height: 100%;
        object-fit: ${props => props.objectFit || 'cover'};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}`;
