import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {text, white} from '@assets/css/globalStyleVars';


const ButtonLink = ({
  onSubmit,
  text,
  src,
  img,
  hoverImg,
  fontSize,
  fontWeight,
  color,
  letterSpacing,
  lineHeight,
  margin,
  background,
  borderRadius,
  border,
  width,
  height,
  hoverBackground,
  target,
  borderColor, link, hoverTextcolor
}) => {

  return (
    <StyledBtn className={'dc-btn'}
      hoverTextcolor={hoverTextcolor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      background={background}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      margin={margin}
      border={border}
      img={img}
      borderRadius={borderRadius}
      width={width}
      hoverImg={hoverImg}
      hoverBackground={hoverBackground}
      height={height}
      borderColor={borderColor}
      target={target}
    >
      {!target ? (
        <Link target={target || '_self'} to={src || '/'}>
          {text}
        </Link>

      ) : (
        <a target={target || '_self'} href={src || '/'}>{text}</a>
      )}
    </StyledBtn>
  );
};

const StyledBtn = styled.div`
    &.dc-btn {
        margin: ${props => props.margin || '0'};
        width: fit-content;
        cursor: pointer;
        min-width: 150px;
        text-align: center;

        a {
            display: flex;
            align-items: center;
            color: ${p => p.color || white};
            font-size: ${props => props.fontSize || '14'}px;
            font-weight: ${props => props.fontWeight || 500};
            margin: 0;
            padding: 20px 30px;
            line-height: ${props => props.lineHeight || '21'}px;
            text-transform: capitalize;
            height: 50px;
            background-color: ${props => props.background || text};
            letter-spacing: ${props => props.letterSpacing || '0'};
            position: relative;
            border-radius: ${props => props.borderRadius || '30px'};
            z-index: 0;
            transition: all .5s cubic-bezier(.54, .32, .05, .96);
            border: 1px solid ${props => props.borderColor || white};
            overflow: hidden;
            text-align: center;
            justify-content: center;

            &:after {
                content: '';
                background: ${props => props.hoverBackground ? props.hoverBackground : white};
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 30px;
                width: 25px;
                height: 25px;
                margin: auto;
                z-index: -1;
                opacity: 0;
                transition: all 1.3s cubic-bezier(.54, .32, .05, .96);

            }

            a {
                &:after {
                    display: none;
                }

                &:before {
                    display: none;
                }
            }


            &:hover {
                color: ${props => props.hoverTextcolor ? props.hoverTextcolor : text} !important;

                border: 1px solid ${props => props.hoverBackground ? props.hoverBackground : white};


                &:after {
                    opacity: 1;
                    transform: scale(15);
                }
            }


        }
    }

    @media (max-width: 768px) {
        a {
            //width: 165px !important;
            justify-content: center !important;

            &:before {
                //width: 100% !important;
            }

            &:after {
                display: none;
            }
        }
    }



`;


export default React.memo(ButtonLink);
