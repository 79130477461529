import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {white} from '@assets/css/globalStyleVars';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import VideoDetails from '@components/mir-ui/single-parts/VideoDetails';
import SocialShare from '@components/social-icons/SocialShare';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import reactHtmlParser from 'react-html-parser';
import moment from 'moment/moment';

gsap.registerPlugin(ScrollTrigger);
const Details = ({data}) => {

  const sectionSingle = useRef();
  const detailsRef = useRef();

  useEffect(() => {
    if (typeof window === 'undefined') return; // Ensure code runs only on client-side

    if (window.innerWidth > 767 && detailsRef?.current && sectionSingle?.current) {
      // Clear any previous ScrollTriggers to avoid conflicts
      ScrollTrigger.getById('whois')?.kill();

      gsap.to(detailsRef.current, {
        scrollTrigger: {
          trigger: sectionSingle.current,
          start: 'top+=-100 top',
          end: 'bottom-=300px top',
          pin: true,
          pinSpacing: false,
          pinReparent: true,
          scrub: 1, // Smooth scrolling effect
          anticipatePin: 1,
          id: 'whois',
          toggleActions: 'play none none reverse',
          invalidateOnRefresh: true, // Updates pin positions on resize
        },
        ease: 'elastic.out(1, 0.3)', // Optional easing for smoothness
      });
    }

    // Cleanup on component unmount or re-renders
    return () => {
      ScrollTrigger.getById('whois')?.kill();
    };
  }, [data]);


  const banner = data?.images?.list.find(f => f?.banner === 'on');
  const video_banner = data?.images?.list.find(f => f?.background === 'on');


  return (
    <StyledDetails className="details-wrapper">
      <Container>

        <Row>
          <Container>
            <Row>
              <Col md={{span: 10, offset: 2}}>
                <ul className={'breadcrumb'}>
                  <li>News</li>
                  <li>{moment(data?.data?.date ? data?.data?.date : '27, Sep 2021').format('DD MMMM YYYY')}</li>
                </ul>
                <h1> {data?.data?.title ? reactHtmlParser(data?.data?.title) : ''}
                </h1>
              </Col>
            </Row>
          </Container>

        </Row>


      </Container>
      {/*mir-banner*/}

      <Container fluid>
        <Row>
          <Col md={12} className=" banner-wrapper">
            <ImgLazyLoad src={banner?.full_path ? banner?.full_path : ''} alt={data?.data?.title}/>
          </Col>
        </Row>
      </Container>
      <Container ref={detailsRef}>

        <Row>
          <Container>
            <Row>
              <Col ref={sectionSingle} md={2} className={'social-pin-wrapper'}>
                <div className="social-wrapper-main">

                  <SocialShare direction/>
                </div>
              </Col>
              <Col md={{span: 10}}>
                {
                  data?.data?.body ?
                    reactHtmlParser(data?.data?.body) : ''
                }

                {
                  data?.data?.link &&
                  <VideoDetails image={video_banner?.full_path} link={data?.data?.link}/>

                }
              </Col>
            </Row>
          </Container>

        </Row>


      </Container>
    </StyledDetails>
  );
};

const StyledDetails = styled.section`
    padding: 100px 0;
    background: ${white};

    h1 {
        font-size: 40px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: 0.2px;
        @media (min-width: 1800px) {
            font-size: 48px;
            line-height: 60px;
        }
        @media (max-width: 767px) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    //mir-banner 

    .banner-wrapper {
        padding-top: calc(505 / 1366 * 100%);
        position: relative;
        margin-top: 40px;
        margin-bottom: 60px;
        @media (max-width: 767px) {
            padding-top: calc(220 / 375 * 100%);

        }
    }

    h3 {
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        margin-top: 40px;

        &.half {
            width: 50%;
            margin: 30px 0 40px;
            float: left;
            display: inline-flex;

            &:nth-of-type(even) {
                padding-right: 15px;
            }

            &:nth-of-type(odd) {
                padding-left: 15px;
            }
        }
    }


    .breadcrumb {
        padding: 0;
        margin: 0 0 20px;
        background: transparent;

        li {
            position: relative;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: rgba(147, 148, 152, 0.5);
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }

            &:after {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: rgba(147, 148, 152, 0.5);
                position: absolute;
                right: -17.5px;
                top: 7.5px;
                content: '';
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

`;

export default React.memo(Details);




