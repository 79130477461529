import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts} from '../../api/redux/career';
import {useDispatch, useSelector} from 'react-redux';
import {SplitText} from 'gsap/SplitText';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {useLocation} from 'react-router-dom';
import CareerOverview from '../career/career-overview/Overview';
import CareerForm from './career-form/CareerForm';
import Gallery from './career-gallery/Gallery';
import ListBox from '../career/career-list-box/ListBox';
import {ApiParam} from '../../api/network/apiParams';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../lib/PageAnimation';
import CareerBanner from '@pages/career/career-banner';
import CareerCta from '@pages/career/career-cta';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const ABOUT = () => {


  const dispath = useDispatch();
  const location = useLocation();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'career',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));

  }, [dispath, location?.pathname]);


  let getPost = useSelector(store => store.career);

  let page_data = getPost?.posts?.data?.page_data;


  const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'career-banner');
  const overview = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'career-overview');
  const form = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'career-form');
  const benefits = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'benefits');
  const culture = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-culture');
  const career = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'cta-career');


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'181'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>
        <CareerBanner noPara  title={banner?.section_data?.subtitle}
          imageSrc={banner?.images?.list?.[0]?.full_path}/>

        <CareerOverview data={overview}/>
        <CareerForm data={form}/>
        <ListBox  benefit data={benefits}/>
        <Gallery data={culture}/>
        <CareerCta cta={career}/>
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
    .container:first-child {
        margin-bottom: 0;
    }
`;

export default ABOUT;
