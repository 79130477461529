import React from 'react';
import Footer from '@components/layouts/Footer';
// eslint-disable-next-line react-hooks/rules-of-hooks


const MainLayout = ({children}) => (
  <div>
    <main>{children}</main>
    <Footer/>
  </div>
);
export default MainLayout;
