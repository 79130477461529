import React, {useEffect} from 'react';
import {gray, hover, text, white} from '@assets/css/globalStyleVars';
import styled from 'styled-components';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {postForm} from '@api/redux/newsletter';


const SubscribeForm = () => {


  const dispatch = useDispatch();
  const responseData = useSelector(state => state.newsletter);

  const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

  // --- mir-form submit
  const success = (msg) => toast.success(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  const error = (msg) => toast.error(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  // const handleBlur = () => {
  // };

  // const handleChange = () => {
  // };


  const onSubmit = (e) => {

    let api_services = apiEndPoints.ADDSUBSCRIP;

    var formData = new FormData();
    formData.append('email', e?.email);

    if (e.email !== '') {
      dispatch(postForm([api_services, formData]));
      // success('Successfully Submitted')
      reset();
    }
  };
  let count = 0;
  const onError = (errors) => {
    Object.values(errors).forEach((error) => {
      count++;
    });
    if (count > 0) {
      toast.error('please fill out the correct inputs');
    }
    count = 0;
  };

  useEffect(() => {

    if (responseData && responseData?.error !== '') {
      error(responseData?.error?.message);
    }
    if (responseData && responseData?.success !== '') {
      success(responseData?.success?.message);
    }

  }, [responseData]);

  return (
    <StyledBtn className={'mir-form'}>
      <Form className={'subscribe-mir-form'} onSubmit={handleSubmit(onSubmit, onError)}>
        <Form.Group className="form-group" controlId="formBasicEmail">

          <Form.Control autoComplete="off"
            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
            {...register('email', {
              required: {
                value: true, message: 'please enter your email'
              }, pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'please enter a valid email address'
              }
            })}
            type="email" placeholder="Your Email Address"/>
          <div className="dc-btn-submit" onClick={handleSubmit(onSubmit, onError)}>
            <div className={'dc-btn'}>
              Subscribe
            </div>
          </div>
        </Form.Group>

      </Form>

    </StyledBtn>
  );
};

const StyledBtn = styled.div`
    padding: 35px 30px;
    background: ${white};
    border-radius: 10px;
    box-shadow: 5px 10px 30px rgba(24, 29, 36, 0.3);

    .form-group {
        position: relative;
        display: flex;
        border: 1px solid ${gray};
        border-radius: 10px;
        height: 60px;
        align-items: center;
        margin: 0;

        .form-control {
            height: 50px;
            background-color: transparent;
            border-radius: 0;
            autocomplete: off;
            padding: 0 20px 0;
            font-size: 12px; /* 15px / 15px = 1rem */
            font-weight: 500;
            line-height: 50px; /* 20px / 15px = 1.3333rem */
            color: ${text};
            border: none !important;

            ::placeholder {
                font-size: 12px; /* 15px / 15px = 1rem */
                font-weight: 500;
                line-height: 21px; /* 20px / 15px = 1.3333rem */
                color: ${text};
                outline: none;
                border: none;

            }

            :focus {
                outline: none;
                outline-color: transparent;
                border-color: white;
            }
        }

        .dc-btn-submit {
            cursor: pointer;
            margin: 0 5px;

            .dc-btn {
                display: flex;
                align-items: center;
                color: ${white};
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                padding: 20px 30px;
                line-height: 21px;
                text-transform: capitalize;
                height: 50px;
                background-color: ${gray};
                position: relative;
                border-radius: 5px;
                z-index: 0;
                transition: all .5s cubic-bezier(.54, .32, .05, .96);
                border: 1px solid ${gray};
                overflow: hidden;
                text-align: center;
                justify-content: center;

                &:after {
                    content: '';
                    background: ${hover};
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border-radius: 30px;
                    width: 25px;
                    height: 25px;
                    margin: auto;
                    z-index: -1;
                    opacity: 0;
                    transition: all 1.3s cubic-bezier(.54, .32, .05, .96);

                }

                a {
                    &:after {
                        display: none;
                    }

                    &:before {
                        display: none;
                    }
                }


                &:hover {
                    color: ${white} !important;

                    border: 1px solid ${hover};


                    &:after {
                        opacity: 1;
                        transform: scale(15);
                    }
                }


            }
        }

    }

    @media (max-width: 767px) {
        padding: 30px 15px;
    }
`;


export default React.memo(SubscribeForm);
