import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Mousewheel, Navigation, Pagination} from 'swiper';
import {softGray, testimonial} from '@assets/css/globalStyleVars';
import ThemeContainer from '../../../theme/container';
import NavigationIconBold from '@components/mir-ui/navigaiton/NavigationIconBold';
import ThemeRow from '../../../theme/row';


const Testimonial2 = ({padding, data}) => {

  let [offset, setOffset] = useState(90);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 15);
    }
  }, [data]);


  const sectionData = data?.section_data;
  const testmonialList = data?.posts?.list;

  return (
    <StyledTestimonial className={`${padding ? padding : 'pt-150 pb-150'}`}>
      <ThemeContainer colClassName={'testimonial__left'} colProps={{md: 12}}>
        <div className="border-wrapper">
          <h2 className={'split-up'}>{sectionData?.title ? sectionData?.title : ''}</h2>

          {
            testmonialList && testmonialList?.length > 0 &&
            <div className="slider-nav">
              <NavigationIconBold prev_id={'testimonial-prev'} next_id={'testimonial-next'}/>

            </div>

          }
        </div>
      </ThemeContainer>

      <ThemeRow fluid={true} colStyles={{paddingLeft: offset ? offset + 'px' : '90px'}} rowClass={'m-0'}>
        <div className="slider-wrap testimonial__right">
          {/*testimonial slider */}


          {
            testmonialList && testmonialList?.length > 0 &&
            <Swiper loop={true}
              slidesPerView={1}
              spaceBetween={15}
              speed="1500"
              autoplay
              slidesPerGroup={1}
              modules={[Autoplay, Pagination, Navigation, Mousewheel]}
              navigation={{
                prevEl: '#testimonial-prev',
                nextEl: '#testimonial-next',
              }}
              breakpoints={{
                767: {
                  autoplay: false,
                  spaceBetween: 70,

                }
              }}>

              {/*testimonial slider items*/}

              {
                testmonialList && testmonialList?.length > 0 &&
                testmonialList.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="slider_item">
                        <div className="slider_item__img">
                          <div className="slider_item__img__text">
                            <div className="testimonial-text">
                              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="37.175" viewBox="0 0 40 37.175">
                                <g id="quotation-right-mark" transform="translate(40 49.502) rotate(180)" opacity="0.3">
                                  <path id="Path_9614" data-name="Path 9614" d="M22.778,47.862V41.188a1.639,1.639,0,0,1,1.639-1.639c3.229,0,4.986-3.312,5.23-9.85h-5.23a1.64,1.64,0,0,1-1.639-1.639V13.967a1.639,1.639,0,0,1,1.639-1.639H38.361A1.64,1.64,0,0,1,40,13.967V28.06a36.2,36.2,0,0,1-.937,8.549,20.566,20.566,0,0,1-2.91,6.766,14.2,14.2,0,0,1-4.945,4.519A14.079,14.079,0,0,1,24.416,49.5,1.64,1.64,0,0,1,22.778,47.862ZM1.639,39.548A1.639,1.639,0,0,0,0,41.187v6.676A1.639,1.639,0,0,0,1.639,49.5a14.075,14.075,0,0,0,6.79-1.607,14.183,14.183,0,0,0,4.946-4.519,20.547,20.547,0,0,0,2.911-6.768,36.182,36.182,0,0,0,.937-8.548V13.966a1.639,1.639,0,0,0-1.639-1.639H1.639A1.639,1.639,0,0,0,0,13.966V28.059A1.639,1.639,0,0,0,1.639,29.7H6.8C6.555,36.236,4.823,39.548,1.639,39.548Z" transform="translate(0)" fill="#c7c4b8"/>
                                </g>
                              </svg>
                              {
                                item?.data?.description &&
                                ReactHtmlParser(item?.data?.description)

                              }
                            </div>
                            <div className="testimonial-designation">
                              <img src={item?.images?.[0]?.full_path} alt=""/>
                              <div className="info">
                                <h5>{item?.data?.title}</h5>
                                <p>{item?.data?.subtitle}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </SwiperSlide>

                  );

                })
              }

            </Swiper>
          }

        </div>
      </ThemeRow>

    </StyledTestimonial>
  );
};

const StyledTestimonial = styled.section`
    position: relative;
    background: ${softGray};

    .container-fluid {
        padding: 0;

        @media (max-width: 767px) {
            padding-left: 15px !important;
            .swiper-slide {
                margin-right: 15px !important;
            }
        }
    }

    .row {
        max-width: 100%;
    }

    //swiper slider 

    .swiper-button-prev, .swiper-button-next {
        display: none;
    }

    .swiper-initialized {
        padding-right: 35%;
        @media (max-width: 767px) {
            padding-right: 15px !important;
        }
    }

    .nav-wrap {
        position: relative;
    }


    //navigation icon 

    .slider-nav {
        z-index: 2;
        width: fit-content;
    }

    .testimonial__left {
        .border-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .testimonial__right {
        margin-top: 50px;
        display: block;
        width: 100%;

        .slider_item {
            position: relative;
            padding-right: 1px;
            height: 100%;

            .slider_item__img {
                position: relative;
                overflow: hidden;
                height: 100%;

                &__text {
                    width: 100%;
                    height: 100%;
                    background-color: ${testimonial};
                    padding: 60px 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    border: none;
                    border-radius: 10px;

                    .testimonial-text {
                        position: relative;

                        svg {
                            margin-bottom: 0;
                            position: absolute;
                            top: -15px;
                            left: -15px;
                        }

                        h4 {
                            margin-bottom: 30px;
                            @media (max-width: 767px) {
                                margin-bottom: 25px;
                            }
                        }

                        p {
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 21px;
                        }

                    }

                    .testimonial-designation {
                        position: relative;
                        display: flex;
                        margin-top: 55px;
                        align-items: center;

                        img {
                            height: 50px;
                            width: 50px;
                            border-radius: 10px;
                            margin-right: 10px;
                        }

                        .info {
                            h3 {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 21px;
                                margin-bottom: 8px;
                            }

                            p {
                                font-size: 14px;
                                font-weight: 300;
                                line-height: 21px;
                                margin: 0 !important;
                            }
                        }

                    }

                }
            }

            a {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: 4;
            }

        }
    }


    @media (max-width: 767px) {
        .testimonial-designation {
            margin-top: 30px;
        }
    }





`;

export default React.memo(Testimonial2);




