import React from 'react';
import styled from 'styled-components';

const MenuClose = () => {
  return (
    <StyledMenuClose>
      <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="13.415"
        viewBox="0 0 13.414 13.415">
        <g id="Group_18632" data-name="Group 18632" transform="translate(-1243.05 -123.05)">
          <path id="Path_9629" data-name="Path 9629" d="M0,16.971V0"
            transform="translate(1243.757 135.757) rotate(-135)" fill="none"
            stroke="#293a8a" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1"/>
          <path id="Path_9630" data-name="Path 9630" d="M0,16.971V0"
            transform="translate(1243.757 123.757) rotate(-45)" fill="none"
            stroke="#293a8a" strokeLinecap="round" strokeLinejoin="round"
            strokeWidth="1"/>
        </g>
      </svg>

    </StyledMenuClose>
  );
};

const StyledMenuClose = styled.div`


`;

export default React.memo(MenuClose);
