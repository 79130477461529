import PropTypes from 'prop-types';
import useDeviceType from '@hooks/useDeviceType';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {themeGet} from '@styled';
// import Text from '@components/mir-ui/text';
import ButtonLink from '@components/mir-ui/buttons/ButtonLink';
import reactHtmlParser from 'react-html-parser';

const SlideOne = ({title,description ,bannerLarge, bannerSmall, buttonLabel, buttonLink, buttonTarget}) => {

  const {isMobile} = useDeviceType();


  return (
    <>
      <div className="slide-inner">
        {
          isMobile ?
            <div className="mobile">
              <ImgLazyLoad alt={title} src={bannerSmall ? bannerSmall : bannerLarge}/>
            </div> :
            <div className="desktop">
              <ImgLazyLoad alt={title} src={bannerLarge ? bannerLarge : null}/>
            </div>

        }

        <Container>
          <Row>
            <Col>
              <div className="slide-inner__info">
                {
                  title &&
                  // <Text h2 classname={'swiper__title'} marginResponsive={'0 0 20px'} text={title} lineHeight={60} fontSize={60} fontWeight={themeGet('fontWeights.light')} fontSizeResponsive={42} lineHeightResponsive={48} color={themeGet('colors.white')} margin={'0,0,20px'}/>
                  <h2>{reactHtmlParser(title)}</h2>

                }
                {
                  description &&
                  <p>{reactHtmlParser(description)}</p>
                  // <Text p  marginResponsive={'0'} text={description} lineHeight={21} fontSize={14} fontWeight={themeGet('fontWeights.medium')} fontSizeResponsive={14} lineHeightResponsive={21} color={themeGet('colors.white')} margin={'0'}/>
                }

                {
                  buttonLink &&
                  <ButtonLink
                    src={buttonLink ? buttonLink : '/'}
                    background={'transparent'} margin={'40px 0 0'} color={themeGet('colors.white')}
                    hoverBackground={'#293A8A'} borderColor={'#fff'}
                    hoverTextcolor={'white'}
                    text={buttonLabel ? buttonLabel : 'Explore'}/>

                }

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

SlideOne.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  thumb: PropTypes.string
};


export {SlideOne};