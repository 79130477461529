import React from 'react';
import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import {serviceBox, serviceBoxBlue, softGray, white} from '@assets/css/globalStyleVars';
import ServiceBig from '@components/mir-ui/single-parts/ServiceBig';
import ServiceSmall from '@components/mir-ui/single-parts/ServiceSmall';
import ThemeRow from '../../../theme/row';

const ServicesBox = ({containerGap, data}) => {

  const servicesSingle = data?.posts?.list;


  return (
    <StyledServicesBox className={`${containerGap ? containerGap : 'pt-150 pb-150'}`}>
      <ThemeRow>
        <Col md={8}>
          <ServiceBig img={servicesSingle?.[0]?.images?.[0]?.full_path} link={servicesSingle?.[0]?.data?.short_desc} label={servicesSingle?.[0]?.data?.subtitle} title={servicesSingle?.[0]?.data?.title}/>
        </Col>
        <Col md={4}>
          <div className={'d-flex flex-column services-single-mir-parts-wrapper'}>
            <ServiceSmall icon={servicesSingle?.[1]?.images?.[0]?.full_path} bgColor={softGray} link={servicesSingle?.[1]?.data?.subtitle} title={servicesSingle?.[1]?.data?.title}/>
            <ServiceSmall icon={servicesSingle?.[2]?.images?.[0]?.full_path} bgColor={serviceBox} link={servicesSingle?.[2]?.data?.subtitle} title={servicesSingle?.[2]?.data?.title}/>
            <ServiceSmall icon={servicesSingle?.[3]?.images?.[0]?.full_path} bgColor={serviceBoxBlue} link={servicesSingle?.[3]?.data?.subtitle} title={servicesSingle?.[3]?.data?.title}/>
          </div>
        </Col>
      </ThemeRow>
    </StyledServicesBox>
  );
};

const StyledServicesBox = styled.section`
    background-color: ${white};
    .d-flex{
        gap: 20px;
    }
    .services-box-wrapper {
        .service-small-box {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 992px) {
        .col-md-8, .col-md-4 {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .col-md-8 {
            margin-bottom: 20px;
        }

        .services-box-wrapper {
            display: flex;
            flex-direction: unset !important;
            justify-content: space-between;
            flex-flow: wrap;
            max-width: 100%;

            .service-small-box {
                margin: 0 !important;

            }
        }

        .service-small-box:nth-of-type(1), .service-small-box:nth-of-type(2) {
            flex: 0 0 calc(50% - 10px);
            max-width: calc(50% - 10px);

        }

        .service-small-box:nth-of-type(3) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 20px !important;
        }
    }`;

export default React.memo(ServicesBox);




