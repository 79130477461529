import React from 'react';
import styled from 'styled-components';
import {white,hover} from '@assets/css/globalStyleVars';

const Logo = () => {
  return (
    <StyledLogo>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.005" viewBox="0 0 24 24.005">
        <path id="search" d="M19.5,9.75A9.726,9.726,0,0,1,17.626,15.5l5.935,5.939a1.5,1.5,0,1,1-2.124,2.124L15.5,17.626a9.752,9.752,0,1,1,4-7.875ZM9.75,16.5A6.75,6.75,0,1,0,3,9.75,6.75,6.75,0,0,0,9.75,16.5Z" fill={white}/>
      </svg>

    </StyledLogo>
  );
};

const StyledLogo = styled.div`
    cursor: pointer;
    position: relative;

    path {
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;
    }

    &:hover {
        path {
            fill: ${hover};

        }
    }

`;

export default React.memo(Logo);
