import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Error from '../pages/404';
import Home from '../pages/home';
import About from '../pages/about';
import Service from '../pages/service';
import Download from '../pages/downloads';
import Research from '../pages/research';
import Career from '../pages/career';
import News from '../pages/news';
import NewsSingle from '../pages/news/single';
import Contacts from '../pages/contacts';
import Search from '../pages/search';
import MainLayout from '../theme/layout1';
import PrivacyPolicy from '@pages/privacy-policy';
import TermsAndConditions from '@pages/terms-and-conditions';

const Index = () => {


  return (

    <Routes>
      <Route
        path="/"
        element={
          <MainLayout
          >
            <Home/>
          </MainLayout>
        }
      />
      <Route exact
        path="/about"
        element={
          <MainLayout
          >
            <About/>
          </MainLayout>
        }
      />
      <Route exact
        path="/services"
        element={
          <MainLayout
          >
            <Service/>
          </MainLayout>
        }
      />
      <Route exact
        path="/downloads"
        element={
          <MainLayout
          >
            <Download/>
          </MainLayout>
        }
      />
      <Route exact
        path="/research"
        element={
          <MainLayout
          >
            <Research/>
          </MainLayout>
        }
      />
      <Route exact
        path="/career"
        element={
          <MainLayout
          >
            <Career/>
          </MainLayout>
        }
      />
      <Route exact
        path="/news"
        element={
          <MainLayout
          >
            <News/>
          </MainLayout>
        }
      />

      <Route exact
        path="/news/:slug"
        element={
          <MainLayout
          >
            <NewsSingle/>
          </MainLayout>
        }
      />
      <Route exact
        path="/contacts"
        element={
          <MainLayout
          >
            <Contacts/>
          </MainLayout>
        }
      />
      <Route
        path="/search"
        element={
          <MainLayout
          >
            <Search/>
          </MainLayout>
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <MainLayout
          >
            <PrivacyPolicy/>
          </MainLayout>
        }
      />

      <Route
        path="/terms-and-conditions"
        element={
          <MainLayout
          >
            <TermsAndConditions/>
          </MainLayout>
        }
      />

      {/* Other routes */}
      <Route path="*" element={
        <MainLayout
        >
          <Error/>
        </MainLayout>
      }/>
    </Routes>

  );
};

export default Index;