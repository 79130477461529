import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ErrorBoundary} from 'react-error-boundary';
import App from '@app/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from '@api/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import HasprCursor from 'haspr-cursor'; // Import Wrapper
import 'haspr-cursor/dist/cursor.css'; // Import Style sheet

// Error fallback component
function ErrorFallback({error, resetErrorBoundary}) {
  const errorStyle = {
    padding: '20px',
    backgroundColor: '#f8d7da',
    color: '#721c24',
  };

  return (
    <div role="alert" style={errorStyle}>
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Optional: You can reset global state or navigate to a safe screen
        window.location.reload(); // Reload the page or reset state logic
      }}
    >
      <BrowserRouter>
        <Provider store={store}>
          <HasprCursor>
            <App/> {/* Render the main app component */}
          </HasprCursor>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals(); // Optional: Log performance metrics

// Register the service worker to enable PWA features (offline, faster load)
serviceWorker.register(); // Note: Comes with caveats; learn more at https://bit.ly/CRA-PWA
