import React, {useState} from 'react';
import styled from 'styled-components';
import {Modal} from 'react-bootstrap';
import {hover} from '@assets/css/globalStyleVars';
import {ImgLazyLoad} from '../images/ImgLazyLoad';

const VideoDetails = ({image, link}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <StyledComponent className={'video-modal'}>
        <div className="video-modal__img " onClick={handleShow}>
          <ImgLazyLoad src={image ? image : '/images/dynamic/mir-banner-video-poster@2x.jpg'}/>
          <div className="video-modal__img__click">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
              <g id="Group_20985" data-name="Group 20985" transform="translate(-743 -12500)">
                <circle id="Ellipse_84" data-name="Ellipse 84" cx="40" cy="40" r="40" transform="translate(743 12580) rotate(-90)" fill="#c7c4b8"/>
                <circle id="Ellipse_85" data-name="Ellipse 85" cx="40" cy="40" r="0" transform="translate(743 12580) rotate(-90)" fill={hover} opacity={'0'}/>
                <path id="Path_2069" data-name="Path 2069" d="M-7066.644,1176.645l-7.053-5.992-4.831-4.1v20Z" transform="translate(7856.527 11363.451)" fill="#fff"/>
              </g>
            </svg>

          </div>
        </div>
      </StyledComponent>

      {/*modal*/}
      <Modal className={'modal-video'} show={show} onHide={handleClose}>

        <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="13.426"
          height="13.423"
          viewBox="0 0 13.426 13.423">
          <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
            d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
            transform="translate(-11.285 -11.289)" fill="#fff"/>
        </svg>


        <Modal.Body>
          <iframe width="560" height="315"
            src={`https://www.youtube.com/embed/${link}?si=H1t21Ym3px2yQicH;controls=0&autoplay=1`}
            title="YouTube video player" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen/>
        </Modal.Body>

      </Modal>
    </>


  );
};

const StyledComponent = styled.section`
    overflow: hidden;


    .video-modal__img {
        padding-top: calc(520 / 970 * 100%);
        position: relative;
        cursor: pointer;
        overflow: hidden;

        img {
            margin: 0 !important;
        }

        &__click {
            position: absolute;
            inset: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;


            svg {
                position: relative;
                z-index: 2;

                #Ellipse_85 {
                    transition: 0.7s all ease;
                }
            }


        }

        &:hover {
            #Ellipse_85 {
                r: 40px;
                opacity: 1;
            }
        }

        @media (max-width: 767px) {
            padding-top: calc(220 / 345 * 100%);

        }
    }

    @media (max-width: 990px) {
        .title-fixed {
            transform: none;
        }
    }

    @media (max-width: 767px) {
        .video-modal__img {
            margin-left: -12px;
            margin-right: -15px;

            &__click {
                height: 50px;
                width: 50px;
            }
        }
    }

    @media (max-width: 767px) {
        .title-fixed {
            text-align: left;
        }
    }

`;

export default React.memo(VideoDetails);
