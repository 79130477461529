import React, {Fragment, useEffect} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPosts} from '@api/redux/home';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {ApiParam} from '@api/network/apiParams';
import gsap, {CSSPlugin} from 'gsap';
import {HelmetProvider} from 'react-helmet-async';
import {SplitText} from 'gsap/SplitText';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import HomeMain from '@pages/home/HomeMain';
import MetaTags from '@components/layouts/MetaTags';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const Home = () => {

  const dispath = useDispatch();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'mir-securities-ltd',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));

  }, [dispath]);


  let getPost = useSelector(state => state.home);
  let page_data = getPost?.posts?.data?.page_data;




  return (
    <Fragment>
      <HelmetProvider>
        <MetaTags pageData={page_data}/>
        <StyledSection id={'home_page'}>
          <HomeMain getPost={getPost}/>
        </StyledSection>
      </HelmetProvider>
    </Fragment>
  );
};

const StyledSection = styled.section`


`;

export default Home;
