import React from 'react';
import { Container, Row } from 'react-bootstrap';
import classNames from 'classnames';

const ThemeRow = ({
  children,
  rowClass,
  fluid = false,
  containerProps = {},
  rowProps = {},
  colStyles = {},
  rowStyles = {},
  rowRef = null, // Add ref as a prop
  containerClass = null,
  containerID = null
}) => {
  // Merge the custom class with the containerClassName
  const containerClassName = classNames(containerProps.className, {
    'container-fluid': fluid
  });

  return (
    <Container id={containerID ? containerID : ''} fluid={fluid} style={colStyles} className={containerClassName + ' '+containerClass} {...containerProps}>
      <Row style={rowStyles} className={rowClass} {...rowProps} ref={rowRef}> {/* Add ref to the Row */}
        {children}
      </Row>
    </Container>
  );
};

export default ThemeRow;
