import React from 'react';
import styled from 'styled-components';
import BannerCarousel from './banner/BannerCarousel';
import OurService from '@pages/home/home-services/OurService';
import HomeMake from '@pages/home/home-make';
import HomeJourney from '@pages/home/home-journey';
import useContainerOffset from '@hooks/useContainerOffset';
import HomeOverview from 'src/pages/home/home-overview';
import HomeCTA from '@pages/home/home-cta';
import HomeTestimonial from '@pages/home/home-testimonial';
import HomeProduct from '@pages/home/home-product/ProductBox';


// import data
const HomeMain =({getPost}) => {



  // declare variable
  const home_banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'banner-slider');
  const services = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'services-list');
  const our_services = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-services');
  const our_make = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'what-makes-us-stand-out');
  const financial = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'financial-product');
  const investement = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'navigate-your-financial-journey-with-our-investment-calculator');
  const testimonial = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'clients-voice');
  const cta = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'cta');


  const containerGap = useContainerOffset('.container');



  return (
    <>
      <StyledHome>
        <BannerCarousel containerGap={containerGap} data={home_banner}/>
        <HomeOverview services={services}/>
        <OurService data={our_services}/>
        <HomeMake data={our_make}/>
        <HomeProduct data={financial}/>
        <HomeJourney data={investement}/>
        <HomeTestimonial data={testimonial}/>
        <HomeCTA cta={cta}/>
      </StyledHome>
    </>
  );
};


/**
 *  Styled component for Home Page
 **/
const StyledHome = styled.section`
   
`;

export default HomeMain;