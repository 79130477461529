import React from 'react';
import ServicesBox from '@components/mir-ui/single-parts/ServicesBox';

const HomeServices = ({ services }) => {
  return (
    <div id="second-section">
      <ServicesBox containerGap="pt-150" data={services}/>
    </div>
  );
};

export default HomeServices;
