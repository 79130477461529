import React, {useEffect} from 'react';
import styled from 'styled-components';
import {ImgLazyLoad} from '../mir-ui/images/ImgLazyLoad';
import reactHtmlParser from 'react-html-parser';
import {gsap} from 'gsap';
import {white} from '@assets/css/globalStyleVars';
import ThemeContainer from '../../theme/container';

const InnerBanner = ({img, logo, subtext, title, uptitle, project, notFull, noPara}) => {
  useEffect(() => {
    // Use GSAP to animate opacity from 0 to 1
    gsap.to('.InnerBanner', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});
    gsap.to('.InnerBanner h1', {alpha: 1, visibility: 'visible', duration: 1, delay: 0.5});
    gsap.to('.InnerBanner p', {alpha: 1, visibility: 'visible', duration: 0.5, delay: 0.5});

    // You can customize the animation duration and other properties as needed
  }, [img]); // Run this effect only once when the component mounts

  return (
    <StyledInnerBanner notFull={notFull} project={project} className="InnerBanner">
      {
        noPara ?
          <ImgLazyLoad banner={true} alt={title} src={img ? img : '/images/static/blurbg.svg'}/>
          :
          <ImgLazyLoad alt={title} banner={true} src={img ? img : '/images/static/blurbg.svg'}/>

      }
      <ThemeContainer colProps={{md: 12}}>
        <h1>{title ? reactHtmlParser(title) : ''}</h1>
      </ThemeContainer>

    </StyledInnerBanner>
  );
};

const StyledInnerBanner = styled.section`
    padding-top: ${props => props.notFull ? '65vh' : '65vh'};
    position: relative;
    background-color: #DDD;
    height: ${props => props.notFull ? '65vh' : '65vh'};
    overflow: hidden;
    opacity: 0;
    visibility: hidden;

    &:after {
        //content: '';
        position: absolute;
        background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }

    @media (max-width: 1024px) and (min-width: 768px) {
        padding-top: 65vh;
        overflow: hidden;
        height: 65vh;

    }


    .container {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 80px;
        z-index: 2;

        h1 {
            margin-bottom: ${props => props.project ? '30px' : '15px'};
            font-size: 40px;
            font-weight: 500;
            line-height: 50px;
            letter-spacing: 0.2px;
            @media (min-width: 1800px) {
                font-size: 48px;
                line-height: 60px;
            }
            @media (max-width: 767px) {
                font-size: 32px !important;
                line-height: 40px !important;
            }

            transform: translateY(0);
            opacity: 0;
        }

        h1 {
            color: ${white};
        }

    }

    @media (max-width: 767px) {
        .container {
            bottom: 30px;
        }
    }


`;

export default InnerBanner;
