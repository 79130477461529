import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import {devices} from '@styled';

export const StyledMenu = styled.section`
    height: 100px;
    padding: 0;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: ${themeGet('transition')};
    align-items: center;
    justify-content: center;
    display: flex;


    .menu-desktop-top {
        position: relative;
        z-index: 9999;

        .main-menu__logo {
            svg {
                transition: ${themeGet('transition')};
                height: auto;
            }
        }

        .main-menu__menu {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .short-menu {
                display: flex;
                li {
                    margin-left: 35px;
                    a {
                        color: ${themeGet('colors.white')};
                        font-weight: ${themeGet('fontSizes.standard')}; /* 15px / 15px = 1rem */
                        font-weight: ${themeGet('fontWeights.medium')};;
                        line-height: 21px; /* 20px / 15px = 1.3333rem */
                        position: relative;
                        top: 2px;

                        &:hover {
                            color: ${themeGet('colors.hover')};
                        }

                        &.active {
                            color ${themeGet('colors.hover')};
                        }

                    }
                }
            }

            &__inner {
                padding: 0;
                cursor: pointer;
                height: fit-content;
                transition: ${themeGet('transition')};
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    margin-top: 1px;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: #F7F3F0;
                    line-height: 18px;
                    letter-spacing: 2px;
                    transition: ${themeGet('transition')};

                }

                .wrap {
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    margin-left: 0;
                }

                .line {
                    height: 2px;
                    background-color: white;
                    margin-left: auto !important;
                    border-radius: 5px;
                    transform-origin: right;
                    transition: ${themeGet('transition')};
                    width: 40px;

                }

                &:hover {
           

                }
            }

        }
    }


    .border-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${themeGet('colors.gray')};

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: ${themeGet('colors.gray')};
            width: calc(100% - 30px);
            margin: 0 auto;
            content: '';
        }
    }

    > div {
        width: 100%;
    }

    &.menu-is-open {
        transform: none !important;
    }

    ${devices.md} {
        display: none;
    }

    .logo {
        position: absolute;
        top: 40px;
        left: 98px;
        z-index: 5;
    }

    .sticky {
        display: none;
    }

    .main-menu__contact {
        height: fit-content;

        &__drop {
            width: 152px;
            height: 40px;
            background-color: black;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            display: flex;
            cursor: pointer;


            p {
                font-size: 20px;
                color: #ffffff;

                svg {
                    margin-left: 10px;
                }
            }
        }

        &__list {
            background-color: #F2EEE8;
            padding: 0px 30px 0 30px;
            width: 260px;
            display: none;
            height: 0;
            overflow: hidden;


            a {
                color: ${themeGet('colors.hover')};
                font-size: 16px;
                line-height: 24px;
                text-transform: none;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 23px;


                span {
                    display: inline-flex;
                    height: 24px;
                    width: 24px;
                    background: #000;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                }

                &:hover {
                    span:after {
                        height: 0;
                        width: 0;
                    }
                }
            }

            ul {
                li {
                    display: flex;
                    padding-bottom: 17px;
                    margin-bottom: 18px;
                    border-bottom: 1px solid rgba(178, 168, 159, 0.20);
                    flex-wrap: wrap;

                    a {
                        color: ${themeGet('colors.hover')};
                        font-size: 16px;
                        line-height: 20px;
                        display: flex;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-transform: lowercase;
                        transition: ${themeGet('transition')};

                        &:hover {
                            letter-spacing: 1px;
                            color: ${themeGet('colors.hover')};

                        }
                    }

                    span {
                        font-size: 12px;
                        line-height: 24px;
                        color: #B2A89F;
                        width: 100%;
                        padding-bottom: 2px;

                    }

                    &:nth-last-of-type(1) {
                        padding: 0;
                        margin: 0;
                        border: none;
                    }
                }
            }
        }

    }


    .main-menu__items {
        position: fixed;
        height: 0;
        width: 100vw;
        z-index: 999;
        top: 100px;
        left: 0;
        background: ${themeGet('colors.softGray')};
        display: block;

        .left-side-banner {
            background: ${themeGet('colors.white')};
            padding-right: 100px !important;
            display: flex;
            flex-direction: column;
            align-items: end;
            height: 100%;
            padding-left: ${props => props.offset + 0}px;;
            padding-right: 100px !important;
            justify-content: flex-end;

            .menu-mega-menu {
                width: 100%;

                .address {
                    display: flex;
                    gap: 30px;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    .singleItem{
                        flex: 0 0 33.3333%;
                    }

                    p {
                        font-weight: ${themeGet('fontSizes.standard')};
                        font-weight: ${themeGet('fontWeights.medium')};;
                        line-height: 20px;
                        margin-bottom: 10px;
                        color: ${themeGet('colors.gray')};
                    }

                    a {
                        font-weight: ${themeGet('fontSizes.standard')};
                        font-weight: ${themeGet('fontWeights.medium')};;
                        line-height: 25px;
                        color: ${themeGet('colors.text')};
                        &:hover{
                            color: ${themeGet('colors.hover')};
                        }
                    
                    }
                }

                .social-wrapper {
                    margin-bottom: 30px;

                    p {
                        font-weight: ${themeGet('fontSizes.standard')};
                        font-weight: ${themeGet('fontWeights.medium')};;
                        line-height: 20px;
                        margin-bottom: 10px;
                        color: ${themeGet('colors.gray')};
                    }
                }

                .menu-banner-wrap {
                    position: relative;
                    padding-top: calc(200 / 700 * 100%);
                    
                    @media(min-width: 1550px){
                        padding-top: calc(350 / 700 * 100%);
                        
                    }

                    .global-image {
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }

                }
            }
        }


        .d-flex {
            width: 100%;
            height: 100%;
        }


        &__img {
            position: relative;
            height: calc(100vh - 100px);
            overflow: hidden;
            background: #fff;

        }

        &__list {
            min-height: 100%;
            //padding-top: 65px;
            transform: translateY(40px);
            opacity: 0;


            .menu-col {
                padding-right: ${props => props.offset + 0}px;
            }

            ul {
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: center;

                li {
                    margin-bottom: 2rem;

                    a {
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: ${themeGet('fontWeights.medium')};;
                        color: ${themeGet('colors.text')};
                        text-align: right;
                        position: relative;
                        display: inline-block;
                        margin: 0;

                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            height: 1px;
                            background-color: ${themeGet('colors.hover')};
                            bottom: 0;
                            width: 0;
                            transition: ${themeGet('transition')};
                        }

                        &:hover {
                            &:before {
                                width: 100%;
                            }

                            color: ${themeGet('colors.hover')} !important;
                        }
                    }

                    &.active {
                        a {
                            color: ${themeGet('colors.hover')} !important;

                            pointer-events: none;

                            &:before {
                                width: 100%;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

        }

    }

    .mute-toggle {
        position: absolute;
        left: 200px;
        top: 10px;
        //bottom: 0;
        margin: auto;
        cursor: pointer;
        height: fit-content;
        display: none;
        //align-items: center;

        line {
            display: none;
        }

        svg {
            cursor: pointer;
        }

        &.sound {
            line {
                display: block;
            }
        }
    }


    .overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 100px;
        background: rgba(255, 255, 255, 0.3);
        width: 100%;
        display: block !important;
        z-index: -1;
        height: calc(100vh - 100px);
        opacity: 0;
        visibility: hidden;
        transition: all 0.6s cubic-bezier(0.25, 0.74, 0.22, 0.99) ;

    }

    .search-desktop {
        //height: 195px;
        background-color: ${themeGet('colors.white')};
        //display: flex;
        align-content: center;
        flex-wrap: wrap;
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        top: 95px;
        height: 0;
        display: none;
        opacity: 0;
        overflow: hidden;
        z-index: 9;
        box-shadow: 0 1px 6px rgb(0 0 0 / 10%);


        .clickto_close {
            cursor: pointer;

            svg {
                fill: black;
            }
        }

        .container {
            position: relative;
            opacity: 0;
        }

        position: absolute;

        svg {
            position: absolute;
            right: 15px;
            top: -40px;
            z-index: 3;

        }


        form {
            min-width: 100%;

            .form-group {
                position: relative;
                display: flex;
                border: 1px solid ${themeGet('colors.gray')};
                border-radius: 30px !important;
                height: 60px !important;
                align-items: center;
                margin: 0;

                .form-control {
                    height: 50px !important;
                    background-color: transparent;
                    border-radius: 0;
                    autocomplete: off;
                    padding: 0 20px 0;
                    font-weight: ${themeGet('fontSizes.standard')}; /* 15px / 15px = 1rem */
                    font-weight: 300;
                    line-height: 50px; /* 20px / 15px = 1.3333rem */
                    color: ${themeGet('colors.gray')};
                    border: none !important;

                    ::placeholder {
                        font-weight: ${themeGet('fontSizes.standard')}; /* 15px / 15px = 1rem */
                        font-weight: 300;
                        line-height: 21px; /* 20px / 15px = 1.3333rem */
                        color: ${themeGet('colors.gray')};
                        outline: none;
                        border: none;

                    }

                    :focus {
                        outline: none;
                        outline-color: transparent;
                        border-color: white;
                    }
                }

            }

            button {
                display: flex;
                align-items: center;
                color: ${themeGet('colors.white')};
                font-weight: ${themeGet('fontSizes.standard')};
                font-weight: ${themeGet('fontWeights.medium')};;
                margin: 0 5px 0 0;
                padding: 20px 30px;
                line-height: 21px;
                text-transform: capitalize;
                height: 50px;
                background-color: ${themeGet('colors.gray')};
                position: relative;
                border-radius: 30px;
                z-index: 0;
                transition: ${themeGet('transition')};
                border: 1px solid ${themeGet('colors.gray')};
                overflow: hidden;
                text-align: center;
                justify-content: center;

                &:after {
                    content: '';
                    background: ${themeGet('colors.hover')};
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border-radius: 30px;
                    width: 25px;
                    height: 25px;
                    margin: auto;
                    z-index: -1;
                    opacity: 0;
                    transition: ${themeGet('transition')};

                }

                a {
                    &:after {
                        display: none;
                    }

                    &:before {
                        display: none;
                    }
                }


                &:hover {
                    color: ${themeGet('colors.white')} !important;

                    border: 1px solid ${themeGet('colors.hover')};


                    &:after {
                        opacity: 1;
                        transform: scale(15);
                    }
                }


            }

            .search-input {

                span {
                    position: absolute !important;
                    bottom: 18px;
                }


            }

            &__menu {
                margin-top: 23px;
                min-width: 100%;

                p {
                    font-size: 12px;
                    color: #ed1b34;
                    font-weight: 600;
                    line-height: 18px;
                    margin-bottom: 13px;

                }

                ul {
                    display: inline-flex;

                    li {
                        a {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 22px;
                            color: #221f1f;
                            display: flex;
                            margin-right: 30px;
                        }

                        &:nth-last-child(1) {
                            a {
                                margin-right: 0;
                            }
                        }
                    }
                }

            }

        }





`;
