import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const ShortMenu = ({title, value, linkUrl, openInNewTab}) => {
  const router = useLocation();
  return (
    <ul className={'short-menu'}>
      <li>
        <Link className={`${router.pathname === '/about' ? 'active' : ''}`} to={'/about'}>
          About Us
        </Link>
      </li>
      <li>
        <Link className={`${router.pathname === '/services' ? 'active' : ''}`}
          to={'/services'}>
          Services
        </Link>
      </li>
      <li>
        <Link className={`${router.pathname === '/research' ? 'active' : ''}`}
          to={'/research'}>
          Research Center
        </Link>
      </li>

    </ul>
  );
};

export default ShortMenu;
