export const text = '#181D24';
export const hover = '#293A8A';
export const gray = '#939498';
export const white = '#FFFFFF';
export const softGray = '#C7C4B8';
export const downloadBg = '#F8F8F8';
export const serviceBg = '#C7C4B8';
export const whiteSoft = '#FAFAFA';
export const serviceBox = '#293A8A';
export const serviceBoxBlue = '#04133F';
export const testimonial = '#E6E5DF';
export const body = '"Gilroy", Arial, Helvetica, freesans, sans-serif ';
export const title = '"Gilroy", Arial, Helvetica, freesans, sans-serif ';