import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {CSSPlugin, gsap, TimelineLite} from 'gsap';
gsap.registerPlugin(CSSPlugin);


const useHideMenu = () => {

  const location = useLocation();
  let tll = new TimelineLite();

  useEffect(() => {
    const display ='none' ;
    const displayflex = 'none' ;
    const opacity =  '0';
    const y ='100%';
    const height =  '0' ;

    if (window.safari !== undefined) {
      document.body.classList.remove('overflow');
    }

    tll.to('.main-menu__items', {
      height: height,
      opacity: opacity,
      duration: 0.6,
      ease: 'power2.inOut'
    })
      .to('.main-menu__items__img', {
        opacity: opacity,
        duration: 0.3,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items .left-side-mir-banner', {
        opacity: opacity,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5').to('.main-menu__items .left-side-mir-banner .menu-mega-menu', {
        display: display,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items__list ul', {
        height: height,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items .d-flex-menu-mir-parts', {
        display: displayflex,
        duration: 0.5,
        paddingTop: 65,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items__list', {
        y: y,
        alpha: opacity,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5').to('.main-menu__items__list ul li', {
        display: display,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5');

    document.querySelector('.main-menu')?.classList.remove('menu-is-open');
    document.querySelector('body')?.classList.remove('menu-is-open');


  }, [location?.pathname]);
};

export default useHideMenu;
