import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import DownloadBox from '@components/mir-ui/single-parts/DownloadBox';


const DownloadList = ({data}) => {

  return (
    <StyledDownloadList className="pt-150 pb-150">
      <Container>
        <Row>

          {
            data?.list
            && data?.list?.length > 0 &&
            data?.list.map((item, index) => {
              return (
                <Col md={4} key={index}>
                  <DownloadBox link={item?.full_path} date={item?.short_desc} title={item?.short_title}/>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </StyledDownloadList>
  );
};

const StyledDownloadList = styled.section`

    .download_box {
        margin-bottom: 30px;
        @media (max-width: 767px) {
            margin-bottom: 30px;

        }
    }

`;

export default React.memo(DownloadList);




