import React from 'react';
import styled from 'styled-components';
import {white} from '@assets/css/globalStyleVars';
import {Img} from '../images/Img';
import {Link} from 'react-router-dom';
import {BLUR} from '@api/network/baseUrl';
import UpIconGray from '@components/mir-ui/svg/UpIconGray';

const ProductSingle = ({title, des, reverse, img, link}) => {


  return (

    <StyledProductSingle className={'hover-single-mir-parts '}>
      <Link to={link ? link : '#'} className={'hover-single-mir-parts-link'}>
        <div className="image-wrapper">
          <Img radius={'10px'} src={img ? img : BLUR}/>
          <div className="box-wrapper">
            <h2>{title ? title : 'SMS'}</h2>

            <div className="icon">
              <UpIconGray/>
            </div>
          </div>
        </div>

      </Link>
    </StyledProductSingle>

  );
};


const StyledProductSingle = styled.div`
    border-radius: 10px;
    overflow: hidden;

    .image-wrapper {
        position: relative;
        padding-top: 100%;
        overflow: hidden;

        .global-image {
            transition: all cubic-bezier(.74, 0, .24, .99) 0.7s;
            transform: scale(1.01);
        }
    }

    .box-wrapper {
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        padding: 0;
        margin: 0;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            background: rgba(24, 29, 36, 0.4);
            z-index: 0;
        }

        h2 {
            transition: all cubic-bezier(.74, 0, .24, .99) 0.2s;
            color: ${white};
            position: absolute;
            font-weight: 600;
            left: 40px;
            bottom: 40px;
            z-index: 1;
            margin: 0;

        }

        .icon {
            position: absolute;
            right: 20px;
            top: 20px;
            z-index: 1;

        }


        &:hover {
            h2, p, a {
                color: ${white};
            }

            #Rectangle_5479 {
                rx: 25px;
                //transform: translate(388px, 2181px) rotate(-90deg);
            }


        }
    }

    a {
        &:hover {
            .global-image {
                transform: scale(1.04)
            }
        }
    }
`;

export default React.memo(ProductSingle);














