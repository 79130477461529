import React from 'react';
import styled from 'styled-components';
import {softGray} from '@assets/css/globalStyleVars';

const DownloadIcon = () => {
  return (
    <StyledDownloadIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_20991" data-name="Group 20991" transform="translate(-388 -635)">
          <rect id="Rectangle_6585" data-name="Rectangle 6585" width="50" height="50" rx="5" transform="translate(388 635)" fill="#939498"/>
          <g id="Group_20990" data-name="Group 20990" transform="translate(-3 -1593)">
            <line id="Line_10" data-name="Line 10" x2="15.484" transform="translate(408 2263.258)" fill="none" stroke="#e1e4e6" strokeLinecap="round" strokeWidth="1"/>
            <g id="Group_13682" data-name="Group 13682" transform="translate(408 2242)">
              <line id="Line_3" data-name="Line 3" x2="15.484" transform="translate(7.742 15.484) rotate(-90)" fill="none" stroke="#e1e4e6" strokeLinecap="round" strokeWidth="1"/>
              <line id="Line_3580" data-name="Line 3580" x2="7.742" y2="7.742" transform="translate(0 8.516)" fill="none" stroke="#e1e4e6" strokeLinecap="round" strokeWidth="1"/>
              <line id="Line_3581" data-name="Line 3581" x1="7.742" y2="7.742" transform="translate(7.742 8.516)" fill="none" stroke="#e1e4e6" strokeLinecap="round" strokeWidth="1"/>
            </g>
          </g>
        </g>
      </svg>
    </StyledDownloadIcon>
  );
};

const StyledDownloadIcon = styled.div`

    #Rectangle_6585 {
        transition: 0.7s all ease;
    }

    &:hover {
        #Rectangle_6585 {
            fill: ${softGray};
            rx: 50px;
        }
    }
`;

export default React.memo(DownloadIcon);
