import React, {useEffect, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import ToDown from '@components/mir-ui/svg/ToDown';

// Initialize ScrollTrigger
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
const ScrollToSectionButton = ({targetSection}) => {
  const [offset, setOffset] = useState(90);

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth > 767) {
        const containerElement = document.querySelector('.container');
        if (containerElement) {
          setOffset(containerElement.offsetLeft);
        }
      }
    };

    updateOffset();

    // Update the offset when the window is resized
    window.addEventListener('resize', updateOffset);

    return () => {
      window.removeEventListener('resize', updateOffset);
    };
  }, [targetSection]);

  const scrollToSection = (e) => {
    e.preventDefault();
    if (targetSection) {
      gsap.to(window, {
        duration: 1,
        scrollTo: {y: '#' + targetSection, offsetY: 0},
      });
      ScrollTrigger.refresh();
    }
  };

  return (
    <div className={'goto-next-section'}
      style={{
        cursor: 'pointer',
        position: 'absolute',
        bottom: '40px',
        zIndex: 2,
        left: `${offset + 15}px`,
        animation: 'bounce 1.5s cubic-bezier(.74,0,.24,.99) infinite alternate',
      }}
      onClick={scrollToSection}
    >
      <ToDown/>
    </div>
  );
};

export default ScrollToSectionButton;
