import React, {useEffect} from 'react';
import LogoBig from '@components/mir-ui/svg/LogoBig';
import gsap, {CSSPlugin} from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(DrawSVGPlugin, CSSPlugin);


const PageLoader = ({onEnterComplete, onExitComplete}) => {

  useEffect(() => {
    var hide = '.page-loader';
    var logo_white = '.preloader-logo';

    var shapes = 'svg .first-load', tl = gsap.timeline({repeat: 0, yoyo: true});
    tl.fromTo(shapes, {drawSVG: '0'}, {duration: 2, drawSVG: '100%', stagger: 0.1})
      .to(shapes, {duration: 0.5, drawSVG: '100%'}, '-=1')
      .to(shapes, {duration: 0.8, fill: 'white'}, '-=0.2');


    var shapesTw = 'svg .second-load', tl2 = gsap.timeline({repeat: 0, yoyo: true});
    tl2.fromTo(shapesTw, {drawSVG: '0'}, {duration: 0.5, drawSVG: '100%', stagger: 0.2})
      .to(shapesTw, {duration: 1, drawSVG: '100%'}, '-=0.5')
      .to(shapesTw, {duration: 0.8, fill: 'white'}, '-=0.2')
      .to(logo_white, {duration: 0.5, alpha: 0}, '-=0.4')
      .to(hide, {duration: 0.5, height: '0'}, '-=0');

  }, [null]);



  return (<div className="page-loader big-preloader">
    <LogoBig/>
  </div>);
};

export default PageLoader;
