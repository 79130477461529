import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {CSSPlugin, gsap} from 'gsap';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger, CSSPlugin);

export const ImageReveal = (data) => {
  const location = useLocation();
  useEffect(() => {

    if(window?.innerWidth > 992){
      // let reveal = gsap.utils.toArray('.reveal');
      // let revealRight = gsap.utils.toArray('.reveal-right');
      // reveal.forEach((cont) => {
      //   let img = cont.querySelector('img');
      //   let tl = gsap.timeline({
      //     scrollTrigger: {
      //       trigger: cont,
      //       toggleActions: 'restart none none reset',
      //     },
      //   });
      //   // parallax
      //   // if () {
      //   gsap.to(img, {
      //     yPercent: 15,
      //     ease: 'none',
      //     scrollTrigger: {
      //       trigger: cont,
      //       scrub: true
      //     }
      //   });
      //   // }
      //
      //   // image reveal
      //   tl.fromTo(
      //     cont,
      //     1.5,
      //     {
      //       xPercent: -100,
      //       ease: 'Expo.easeInOut',
      //     },
      //     {
      //       xPercent: 0,
      //       ease: 'Expo.easeInOut',
      //     }
      //   );
      //
      //   tl.fromTo(
      //     img,
      //     1.5,
      //     {
      //       xPercent: 100,
      //       ease: 'Expo.easeInOut',
      //       scale: 1.1,
      //     },
      //     {
      //       delay: -1.5,
      //       xPercent: 0,
      //       scale: 1,
      //       ease: 'Expo.easeInOut',
      //     }
      //   );
      // });
      // revealRight.forEach((cont) => {
      //   let img = cont.querySelector('img');
      //   let tl = gsap.timeline({
      //     scrollTrigger: {
      //       trigger: cont,
      //       toggleActions: 'restart none none reset',
      //     },
      //   });
      //
      //   // parallax
      //   gsap.to(img, {
      //     yPercent: 15,
      //     ease: 'none',
      //     scrollTrigger: {
      //       trigger: cont,
      //       scrub: true
      //     }
      //   });
      //
      //   // image reveal (right to left)
      //   tl.fromTo(
      //     cont,
      //     1.5,
      //     {
      //       xPercent: 100, // Change from -100 to 100
      //       ease: 'Expo.easeInOut',
      //     },
      //     {
      //       xPercent: 0,
      //       ease: 'Expo.easeInOut',
      //     }
      //   );
      //
      //   tl.fromTo(
      //     img,
      //     1.5,
      //     {
      //       xPercent: -100, // Change from 100 to -100
      //       ease: 'Expo.easeInOut',
      //       scale: 1.1,
      //     },
      //     {
      //       delay: -1.5,
      //       xPercent: 0,
      //       scale: 1,
      //       ease: 'Expo.easeInOut',
      //     }
      //   );
      // });

    }
  }, [location.pathname,data]);
};