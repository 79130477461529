import React from 'react';
import styled from 'styled-components';

const DownIcon = () => {
  return (
    <StyledPlay>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <g id="Group_18504" data-name="Group 18504" transform="translate(-388 -2131)">
          <rect id="Rectangle_5479" data-name="Rectangle 5479" width="50" height="50" rx="5" transform="translate(388 2131)" fill="#c7c4b8"/>
          <g id="Group_18503" data-name="Group 18503" transform="translate(-174.734 1739.251) rotate(-45)">
            <path id="Path_9568" data-name="Path 9568" d="M0,8,8,0l8,8" transform="translate(128.904 718.277) rotate(180)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_9569" data-name="Path 9569" d="M118.9,714.117v-16" transform="translate(2 4.16)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          </g>
        </g>
      </svg>


    </StyledPlay>
  );
};

const StyledPlay = styled.div`
    #Rectangle_5479, #Group_18503 {
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;

    }

    &:hover {

        #Rectangle_5479 {
            rx: 25px;
            //transform: translate(388px, 2181px) rotate(-90deg);
        }


    }
`;

export default React.memo(DownIcon);
