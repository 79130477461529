import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {LightgalleryItem, LightgalleryProvider,} from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';


const Gallery = ({data}) => {

  const PhotoItem = ({image, thumb, group}) => (
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} alt=""/>
      {/*<img src={image} alt=""/>*/}
    </LightgalleryItem>

  );
  const sectionData = data?.section_data;
  const galleryList = data?.galleries?.list?.[0]?.items;

  return (
    <StyledGallery className="pt-150 pb-150">
      <Container>
        <Row>
          <Col>
            <h2>{sectionData?.title}</h2>
          </Col>
        </Row>
      </Container>

      <Container fluid className="p-0">
        <LightgalleryProvider
          lightgallerySettings={
            {
              download: false,
              thumbnail: false,
              fullScreen: false,
              share: false
            }
          }
        >
          <Row>
            {
              galleryList && galleryList?.length > 0 &&
              galleryList.map((item, index) => {
                return (
                  <Col key={index} sm={3}>
                    <div className="gallery-single">
                      <PhotoItem image={item?.full_path} group="group1"/>
                    </div>
                  </Col>


                );

              })
            }


          </Row>
        </LightgalleryProvider>


      </Container>
    </StyledGallery>
  );
};

const StyledGallery = styled.section`
    position: relative;
    background-color: #FFFFFF;


    h2 {
        margin-bottom: 60px;
    }

    .col-sm-3 {
        padding: 0 5px;
        margin-bottom: 10px;
    }

    .gallery-single {
        padding-top: calc(301 / 334 * 100%);
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media (max-width: 767px) {
        .bg-shadow {
            width: 100%;
        }

        .col-sm-3 {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 2px;
            margin-bottom: 2px;
        }

        .sub-title {
            margin-bottom: 60px;
        }
    }

`;
export default Gallery;
