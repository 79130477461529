import React from 'react';
import InnerBanner from '@components/mir-banner/InnerBanner';

const index = ({ noPara, title , imageSrc}) => {


  return (
    noPara ?
      <InnerBanner noPara title={title}
        img={imageSrc}/>
      :
      <InnerBanner  title={title}
        img={imageSrc}/>

  );};

export default index;
