import {theme} from '@styled/theme';
import {themeGet} from '@styled-system/theme-get';
import styled, {createGlobalStyle, css, keyframes, ThemeProvider, ServerStyleSheet} from 'styled-components';

export const devices = {
  xs: '@media screen and (max-width: 320px)',
  sm: '@media screen and (max-width: 767px)',
  md: '@media screen and (max-width: 992px)',
  lg: '@media screen and (max-width: 1280px)',
  xl: '@media screen and (max-width: 1440x)',
  xxl: '@media screen and (min-width: 1536px)',
  xlMin: '@media screen and (min-width: 1024px)',
  ResponsiveTab: ' @media (max-width: 992px) and (min-width: 768px)',
  CustomDeviceMin: '@media (min-width: 1800px)',
  CustomDeviceLargeMin: '@media (min-width: 1920px)',
};

export {
  css,
  theme,
  themeGet,
  keyframes,
  ThemeProvider,
  ServerStyleSheet,
  createGlobalStyle
};

export * from 'styled-system';
export default styled;