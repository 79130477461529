import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavigationList = ({ items }) => {
  const router = useLocation();



  return (
    <ul>
      {items.map(item => (

        <li key={item.path} className={router.pathname === item.path ? 'active' : ''}>
          <Link to={item.path}>{item.label}</Link>
        </li>
      ))}
    </ul>
  );
};

export default NavigationList;
