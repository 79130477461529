import React, {useRef} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import NavigationIcon from '@components/mir-ui/navigation-icon-slider';
import ScrollToSectionButton from '@components/mir-ui/buttons/ScrollToSectionButton';
import Slider, {Slide} from '@components/mir-ui/swiper';
import {SlideOne as SlideItem} from '@components/slider/slide/slide-one';
import useAutoplay from '@hooks/useAutoplay';
import {devices} from '@styled';
import {themeGet} from '@styled-system/theme-get';

const BannerCarousel = ({containerGap, data, settings, className}) => {


  // Refs for main home-slider and navigation home-slider
  const mainSliderRef = useRef(null);



  // variables
  const swiperRefTwo = useRef(null);
  var pagination_title = [''];
  // const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const sectionData = data?.posts?.list;
  useAutoplay(mainSliderRef); // Hook for autoplay

  // handle slider progress
  const handleProgress = (swiper, event) => {
    var interleaveOffset = 0.5;
    for (let i = 0; i < swiper.slides.length; i++) {
      var slideProgress = swiper.slides[i].progress;
      var innerOffset = swiper.width * interleaveOffset;
      var innerTranslate = slideProgress * innerOffset;
      swiper.slides[i].querySelector('.global-image').style.transform = 'translate3d(' + innerTranslate + 'px, 0, 0)';
    }

  };


  // handle image transition on change
  const handleSetTransition = (swiper, speed) => {
    for (let i = 0; i < swiper.slides.length; i++) {
      swiper.slides[i].style.transition = `${speed}ms`;
      swiper.slides[i]
        .querySelector('.global-image').style.transition = `${speed}ms`;
    }
  };


  // swiper pagination view handle
  const pagination = {
    clickable: true, renderBullet: function (index, className) {
      return '<div class="' + className + '"><span>' + pagination_title[index] + '</span></div>';
    },
  };


  // animation after click next or previous
  const handleSlideChange = (event) => {
    const newActiveSlideIndex = event.realIndex;
    // setActiveSlideIndex(newActiveSlideIndex);
    if (swiperRefTwo.current) {
      swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
    }
  };

  // Function to initialize swiper
  const handleSwiperInit = (swiper) => {
    return swiper;
  };


  return (<StyledBanner containerGap={containerGap} className={'Home-mir-banner'}>


    {/*main slider and items*/}

    {
      sectionData && sectionData?.length > 0 &&
      <Slider
        ref={mainSliderRef}
        settings={settings}
        className={className}
        onSwiperInit={handleSwiperInit}
        spaceBetween={0}
        loop={true}
        speed={2500}
        onSlideChange={handleSlideChange}
        onProgress={handleProgress}
        onSetTransition={handleSetTransition}
        grabCursor={false}
        watchSlidesProgress={true}
        mousewheelControl={true}
        keyboardControl={true}
        pagination={pagination}
        navigationLeft={'#mir-banner-prev'}
        navigationRight={'#mir-banner-next'}
      >
        {sectionData && sectionData?.length > 0 && sectionData.map((item, index) => (
          <Slide key={index}>
            <SlideItem
              title={item?.data?.title}
              description={item?.data?.short_desc}
              bannerLarge={item?.images[0]?.full_path}
              bannerSmall={item?.images[1]?.full_path}
              buttonLabel={item?.data.button_label}
              buttonLink={item?.data.button_link}
              buttonTarget={'self'}
            />
          </Slide>
        ))}
      </Slider>


    }


    {/*next prev navigation*/}
    {sectionData && sectionData?.length > 0 && <div className="navigation">
      <Container>
        <Row>
          <Col>
            <NavigationIcon banner navigationPrev={'mir-banner-prev'} navigationNext={'mir-banner-next'}/>
          </Col>
        </Row>
      </Container>
    </div>}


    <ScrollToSectionButton targetSection={'second-section'}/>

  </StyledBanner>);
};

const StyledBanner = styled.section`
    height: 100vh;
    position: relative;
    opacity: 1 !important;


    //all style for swiper slider 

    .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
        display: none;
    }

    .swiper-slide {
        overflow: hidden;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-button-disabled {
        opacity: 0.5 !important;
    }

    .swiper-slide-active, .swiper-slide-visible {
        .global-image {
            clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        .slide-inner {
            img {
                height: 120vh !important;
            }

            &__info {
                transition-delay: 0.3s;
                opacity: 1;
                transform: translateX(0) !important;

                .dc-btn {
                    transition-delay: 0.4s;
                    opacity: 1;
                    transform: translateX(0) !important;
                }
            }

        }
    }

    //style for next prev button


    //custom navigation

    .swipper-navigation-slider-custom {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        min-height: 75px;
        text-align: left;
        margin: 0 auto;
        padding-left: ${props => props.containerGap ? props.containerGap + 15 : '90'}px;
        padding-right: ${props => props.containerGap ? props.containerGap + 15 : '90'}px;
        justify-content: space-between;
        gap: 15px;
        flex-direction: row;

        .slide-inner-item {
            width: 100%;
            position: relative;
            background: transparent !important;
            opacity: 1 !important;
            padding: 20px 0 0;
            cursor: pointer;

            @media (min-width: 1536px) {
                padding-right: 15px;
            }

            span {
                font-size: 20px;
                font-weight: 500;
                color: ${themeGet('colors.white')};
                line-height: 28px;
                opacity: 1 !important;
                transition: 0.7s all ease;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 4px;
                background: ${themeGet('colors.hover')};
                width: 0;
                transition: 1s all cubic-bezier(.25, .74, .22, .99);
                opacity: 0;
            }

            &:hover {
                &:after {
                    width: 100%;
                    opacity: 1;
                }

            }

            &:before {
                content: '';
                background: transparent !important;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 99;
            }

            &.active {


                &:after {
                    width: 100%;
                    opacity: 1;
                }

            }

        }
    }


    //slider style 

    .slide-inner {
        position: relative;
        height: 100vh;

        .container {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
        }

        .global-image {
            transition: clipPath .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clipPath .8s cubic-bezier(.29, .73, .45, 1);
            will-change: clipPath;
            overflow: hidden;
            -webkit-clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);

            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 1;
                background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
            }
        }

        &__info {
            position: relative;
            overflow: hidden;
            transform: translateX(50%);
            transition: all 2s cubic-bezier(.25, .74, .22, .99);
            transition-delay: 1.2s;
            opacity: 0;
            @media (min-width: 1024px) {
                width: 70%;
            }

            h2, h1 {
                font-size: 60px !important;
                font-weight: 300;
                line-height: 60px !important;
                letter-spacing: 0.2px;
                color: ${themeGet('colors.white')};
                position: relative;
                margin: 0 0 20px;
            }


            p {
                font-size: 14px;
                line-height: 21px;
                color: ${themeGet('colors.white')};
                font-weight: 500;
            }

            .dc-btn {
                position: relative;
                transition-delay: 1.2s;
                transform: translateX(50%);
                transition: 2.4s all cubic-bezier(.25, .74, .22, .99);
                opacity: 0;
            }
        }
    }

    //slider style end 


    //navigation icon 

    .navigation {
        position: absolute;
        right: ${props => props.containerGap ? props.containerGap : '90'}px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        z-index: 1;

        ul {
            display: flex;
            flex-direction: column;

            li {
                cursor: pointer;

                &:first-child {
                    margin-right: 0;
                }

                &:first-child {
                    margin-bottom: 20px;
                }

                svg {

                }

                &:hover {
                    svg {

                    }
                }
            }
        }
    }


    //responsive
    ${devices.xxl} {
        .slide-inner {
            &__info {
                position: relative;
                overflow: hidden;

                h2, h1 {
                    font-size: 80px !important;
                    line-height: 100px !important;
                }

                .dc-btn {
                    a {
                        font-size: 22px;
                        line-height: 33px;
                    }
                }
            }
        }
    }
    ${devices.md} {
        .swipper-navigation-slider-custom {
            bottom: 250px;
        }
    }
    ${devices.ResponsiveTab} {
        height: 100vh;
        .swipper-navigation-slider-custom {
            bottom: 60px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }

        .slide-inner {
            height: 100vh;

            .container {
                top: 45%;
            }
        }

        .navigation {
            position: absolute;
            right: unset;
            top: unset;
            transform: unset;
            display: inline-block;
            z-index: 1;
            left: ${props => props.containerGap ? props.containerGap : '20px'}px;
            bottom: 180px;
            display: none;

            ul {
                flex-direction: row;

                li {
                    &:last-child {
                        margin-bottom: 0;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    ${devices.sm} {
        height: 100vh;
        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }

        .slide-inner {
            height: 100vh;

            .container {
                top: unset;
                transform: unset;
                bottom: 40%;
                @media(max-width: 380px){
                    bottom: 120px
                }
            }

            &__info {
                position: relative;
                overflow: hidden;


                h2, h1 {
                    font-size: 40px !important;
                    line-height: 48px !important;
                }

                .dc-btn {
                    margin-top: 30px;
                }
            }
        }

        .swipper-navigation-slider-custom {
            padding-left: 15px !important;
            padding-right: 15px !important;
            bottom: 30px;

            .swiper-initialized {
                padding-right: 30%;
            }
        }


        .scrollTo {
            left: 15px;
            right: unset;
            display: inline-block;
            bottom: 20px;
            transform: translateX(0);
        }

        .navigation {
            display: none;
        }


        .feature-box-container {

            display: none;
            position: relative;
            bottom: 150px;

            .feature-box-container_item {
                &:first-child {
                    order: 2;
                }

                &:nth-of-type(2) {
                    order: 3;
                }

                &:nth-of-type(3) {
                    order: 4;
                }

                &:last-child {
                    order: 1;

                    .content {
                    }
                }
            }

            .single-item-wrapper {
                border-right: none;
                border-bottom: 1px solid rgba(198, 198, 198, 0.5);

                .content {
                    align-items: flex-start !important;
                }

                &.last {
                    border: none;

                    .content {
                        align-items: center !important;
                    }
                }


            }

            .container {
                padding: 0;
            }
        }
    }



`;

export default React.memo(BannerCarousel);
