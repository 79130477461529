import React from 'react';
import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import {softGray} from '@assets/css/globalStyleVars';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import reactHtmlParser from 'react-html-parser';
import ThemeRow from '../../../theme/row';

const Overview = ({data}) => {

  const sectionData = data?.section_data;

  return (
    <StyledOverview className="pt-150 pb-150">
      <ThemeRow>
        <Col sm={12} className="about-text-top">
          <h2 className={'split-up'}>{sectionData?.subtitle ? sectionData?.subtitle : ''}</h2>
        </Col>
      </ThemeRow>
      <ThemeRow>
        <Col md={8}>
          <div className="homeAboutText">
            <h3 className={'split-up'}>
              {
                sectionData?.short_desc ?
                  reactHtmlParser(sectionData?.short_desc) : ''
              }
            </h3>
            <p className={'split-up'}>

              {
                sectionData?.description ?
                  reactHtmlParser(sectionData?.description)
                  : ''
              }
            </p>

          </div>
        </Col>

        <Col md={4}>
          <div className="image-wrapper parallax-img">
            <ImgLazyLoad radius={'10px'} alt={sectionData?.subtitle} src={data?.images?.list?.[0]?.full_path}/>
          </div>
        </Col>
      </ThemeRow>
    </StyledOverview>
  );
};

const StyledOverview = styled.section`
    background-color: ${softGray};

    h2 {
        margin-bottom: 70px;
    }

    h3 {
        margin-bottom: 40px;
    }

    .image-wrapper {
        position: relative;
        padding-top: calc(400 / 370 * 100%);
        height: 100%;
    }

    @media (max-width: 992px) {
        .col-md-8, .col-md-4 {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .image-wrapper {
            margin-top: 40px;
        }
    }

`;

export default Overview;




