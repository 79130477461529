import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {gray, white} from '@assets/css/globalStyleVars';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import reactHtmlParser from 'react-html-parser';

const ListBox = ({padding, directors, image, notfirst, reverse, first_data, data}) => {


  return (
    <StyledListBox className={`${padding ? padding : 'pt-150 pb-150'}`}>

      {
        notfirst ?
          ''
          :
          <Container>
            <Row>
              <Col sm={5} className="about-text-top">
                <h2 className={'split-up'}>
                  {
                    directors ? directors :
                      first_data?.section_data?.subtitle
                  }
                </h2>

              </Col>
              <Col md={{span: 6, offset: 1}}>
                <p>{first_data?.section_data?.short_desc}
                </p>
              </Col>
            </Row>
          </Container>

      }

      <Container className={'second-col'}>

        {
          reverse ?
            <Row className={'reverse'}>
              <Col md={{span: 6}}>
                <h2 className={'split-up'}>{data?.data?.title}</h2>
                <h3 className={'split-up'}>{data?.data?.subtitle}</h3>
                <p className={'split-up'}>{data?.data?.short_desc}</p>
                {
                  data?.data?.description &&
                  reactHtmlParser(data?.data?.description)
                }
              </Col>
              <Col sm={{span: 5, offset: 1}} className="about-text-top">
                <div className="image-wrapper reveal-right">
                  <ImgLazyLoad radius={'10px'} src={data?.images?.[0]?.full_path}/>
                </div>
              </Col>
            </Row>
            :
            <Row>
              <Col sm={5} className="about-text-top">
                <div className="image-wrapper reveal">
                  <ImgLazyLoad radius={'10px'} src={data?.images?.[0]?.full_path}/>
                </div>
              </Col>
              <Col md={{span: 6, offset: 1}}>
                <h2 className={'split-up'}>{data?.data?.title}</h2>
                <h3 className={'split-up'}>{data?.data?.subtitle}</h3>
                <p className={'split-up'}>{data?.data?.short_desc}</p>
                {
                  data?.data?.description &&
                  reactHtmlParser(data?.data?.description)
                }
              </Col>
            </Row>

        }

      </Container>

    </StyledListBox>
  );
};

const StyledListBox = styled.section`
    background: ${gray};


    p {
        color: ${white};
        margin-bottom: 64px;
    }

    h2, h3 {
        color: ${white};

        margin-bottom: 40px;
    }

    ul {
        li {
            border-top: 1px solid ${white};
            padding: 20px 30px;
            color: ${white};

            &:nth-of-type(odd) {
                background: rgba(199, 196, 184, 0.3);
            }

            &:nth-of-type(even) {
                background: transparent;
            }

            &:last-child {
                border-bottom: 1px solid ${white};
            }
        }
    }


    .image-wrapper {
        padding-top: calc(600 / 470 * 100%);
        position: relative;
        height: 100%;
    }


    @media (max-width: 992px) {


        .second-col {
            margin-bottom: 0 !important;

            .row {
                flex-direction: column-reverse;

                &.reverse {
                    flex-direction: column;
                }
            }

            .about-text-top {
                margin-top: 40px;
            }
        }

        .col-sm-5 {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .col-md-6 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0;
        }

        .col-md-4 {
            max-width: 50%;
            flex: 0 0 50%;
            margin: 0 0 30px;
        }
    }
    @media (max-width: 767px) {

        .navigation_button {
            margin-bottom: 40px;
        }

        .container:first-child {
            margin-bottom: 60px;
        }

        .col-sm-5 {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .col-md-6 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0;
        }

        .col-md-4 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 0 0;
        }
    }
`;

export default ListBox;




