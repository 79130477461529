import React, {useState} from 'react';
import styled from 'styled-components';
import {gray, white} from '@assets/css/globalStyleVars';
import DownIcon from '@components/mir-ui/svg/DownIcon';
import TextPopup from '@components/mir-ui/modal/TextPopup';

const HoverBox = ({title, des, reverse, data, nohover}) => {

  // variables
  const [show, setShow] = useState(false);
  const [popupId, setPopupId] = useState();

  // showing modal
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setPopupId(e);
  };


  return (

    <>
      <StyledHoverBox className={`hover-box ${nohover ? 'reverse' : ''}`}>
        {
          data &&
          <div onClick={() => handleShow(data)} className={'hover-single-mir-parts-link'}>
            <div className="box-wrapper">
              {
                title &&
                <h2 className={'split-up'}>{title ? title : 'SMS'}</h2>
              }
              {
                des &&
                <p className={'split-up'}>{des ? des : 'Mir Securities Limited provides the following SMS services:'}</p>
              }

              <div className="icon">
                <DownIcon/>
              </div>
            </div>
          </div>

        }
      </StyledHoverBox>
      <TextPopup show={show} data={popupId} handleClose={handleClose}/>
    </>

  );
};


const StyledHoverBox = styled.div`
    height: 100%;

    .hover-single-mir-parts-link {
        height: 100%;
    }

    .hover-box-link {
        cursor: pointer;

    }

    cursor: pointer;

    .box-wrapper {
        border-top: 1px solid ${gray};
        border-bottom: 1px solid ${gray};
        padding: 40px 30px 20px 0;
        position: relative;
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;
        height: 100%;

        h2 {
            transition: all cubic-bezier(.74, 0, .24, .99) 0.2s;
            position: relative;
            z-index: 1;
            margin-bottom: 20px !important;
        }

        p {
            min-height: 80px;
            margin-bottom: 90px;
            position: relative;
            z-index: 1;
            transition: all cubic-bezier(.74, 0, .24, .99) 0.2s;

        }

        .icon {
            text-align: right;
            padding-right: 20px;
            position: relative;
            z-index: 1;
        }

        &:after {
            background: ${gray};
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            content: '';
            height: 100%;
            width: 0;
            transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;

        }


        &:hover {
            //padding-left: 30px;

            &:after {
                //width: 100%;
            }

            h2, p, a {
                    //color: ${white};
            }

            #Rectangle_5479 {
                //rx: 25px;
                //transform: translate(388px, 2181px) rotate(-90deg);
            }

            #Group_18503 {
                //transform: translate(-297.277px, 2276.904px) rotate(-90deg);
            }
        }
    }


    &.reverse {
        .box-wrapper {
            padding-left: 30px;

            &:after {
                width: 100%;
            }

            h2, p, a {
                color: ${white};
            }

            #Rectangle_5479 {
                rx: 25px;
                transform: translate(388px, 2181px) rotate(-90deg);
            }

            #Group_18503 {
                transform: translate(-297.277px, 2276.904px) rotate(-90deg);
            }
        }
    }
`;

export default React.memo(HoverBox);














