export const ApiParam = {
  TYPE: 'type',
  Filter: 'filter',
  PAGE_ID: 'page_id',
  PAGE_SLUG: 'page_slug',
  VALUE : 'value',
  GET_SECTION : 'get_section',
  IMAGE : 'image',
  POST : 'post',
  FILE : 'file',
  GALLERY : 'gallery',
  LIMIT : 'limit',
  VARIATION : 'variation',
  SPECIFICATION : 'specification',
  KEYWORD : 'keyword',
  DIVISION_ID: 'division_id',
  RADIUS:'radius',
  KEY:'key',
  CATEGORY_ID:'category_id',
  DATE:'date',
  STARTDATE: 'start_date',
  ENDDATE: 'end_date',
};

