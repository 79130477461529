import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {gray} from '@assets/css/globalStyleVars';
import HoverBox from '@components/mir-ui/single-parts/HoverBox';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import NavigationIcon from '@components/mir-ui/navigaiton/NavigationIcon';
import reactHtmlParser from 'react-html-parser';

const ListBox = ({padding, directors, data, benefit}) => {
  const sectionData = data?.section_data;
  const servicesList = data?.posts?.list;

  return (
    <StyledListBox className={`${padding ? padding : 'pt-150 pb-150'}`}>
      <Container>
        <Row>
          <Col sm={5} className="about-text-top">
            <h2 className={''}>
              {
                directors ? directors :
                  sectionData?.title
              }
            </h2>

          </Col>
          <Col md={{span: 6, offset: 1}}>
            <p className={''}>
              {sectionData?.short_desc ? reactHtmlParser(sectionData?.short_desc) : ''}
            </p>
            {
              benefit ? '' :
                sectionData?.description &&
                reactHtmlParser(sectionData?.description)
            }
          </Col>
          {
            benefit ?
              <Col md={12} className={'full-width-menu-mir-parts'}>
                {
                  sectionData?.description &&
                  reactHtmlParser(sectionData?.description)
                }
              </Col>
              :
              ''
          }
        </Row>


      </Container>

      {
        benefit ? ''
          :
          <Container>
            <Row>
              <Col className={''} md={12}>
                <h2 className={'split-up'}>{sectionData?.short_desc ? reactHtmlParser(sectionData?.short_desc) : ''}</h2>
              </Col>
            </Row>


            {
              window?.innerWidth > 767 ?

                <Row className={'gap'}>
                  {
                    servicesList && servicesList?.length > 0 &&
                    servicesList.map((item, index) => {
                      return (
                        <Col md={4} key={index}>
                          <HoverBox title={item?.data?.title} data={item} des={item?.data?.short_desc}/>
                        </Col>
                      );
                    })
                  }


                </Row>

                : <Row>

                  <Col md={12}>
                    <NavigationIcon prev_id={'next-prev'} next_id={'story-next'} color={'#F1F0EE'}/>


                    <Swiper
                      modules={[Autoplay, Pagination, Navigation, EffectFade]}
                      slidesPerView={1}
                      speed={1500}
                      spaceBetween={50}
                      centeredSlides={true}

                      navigation={{
                        prevEl: '#story-prev',
                        nextEl: '#story-next',
                      }}

                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 15,

                        },
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 15,

                        },
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 15,
                        },
                      }}
                      loop={true}
                    >
                      {
                        servicesList && servicesList?.length > 0 &&
                        servicesList.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <HoverBox title={item?.data?.title} link={item?.data?.link ? item?.link : '/'} des={item?.data?.short_desc} reverse/>
                            </SwiperSlide>
                          );

                        })
                      }

                    </Swiper>
                  </Col>
                </Row>
            }
          </Container>

      }

    </StyledListBox>
  );
};

const StyledListBox = styled.section`
    background: #FAFAFA;
    .gap{
        gap: 30px;
        margin: 0;
        .col-md-4{
            flex: 0 0 calc(33.3333% - 30px);
            padding-left: 0;
            padding-right: 0;
        }
    }
    .container {
        &:first-child {
            margin-bottom: 150px;
        }
    }

    p {
        margin-bottom: 64px;
    }

    h2 {
        margin-bottom: 40px;
    }

    
    
    ul {
        li {
            border-top: 1px solid ${gray};
            padding: 20px 30px;

            &:nth-of-type(odd) {
                background: #FFFFFF;
            }

            &:nth-of-type(even) {
                background: #FAFAFA;
            }

            &:last-child {
                border-bottom: 1px solid ${gray};
            }
        }
    }

    .full-width-list {
        display: flex;
        justify-content: space-between;

        ul {
            flex: 0 0 calc(50% - 15px);
            width: calc(50% - 15px);
            
        }
    }

    
    .full-width-menu-parts{
        display: flex;
        justify-content: space-between;

        ul {
            flex: 0 0 calc(50% - 15px);
            width: calc(50% - 15px);
            &:nth-of-type(1) {
                li {
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
            
            li{
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .col-sm-5 {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .col-md-6 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0;
        }

        .col-md-4 {
            max-width: 50%;
            flex: 0 0 50%;
            margin: 0 0 30px;
        }
    }
    @media (max-width: 767px) {

        .navigation_button {
            margin-bottom: 40px;
        }

        .container:first-child {
            margin-bottom: 60px;
        }

        .col-sm-5 {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .col-md-6 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0;
        }

        .col-md-4 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 0 0;
        }
    }
`;

export default ListBox;




