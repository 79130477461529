import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {hover, white} from '@assets/css/globalStyleVars';
import {CSSPlugin, gsap, TimelineLite} from 'gsap';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import 'react-modal-video/css/modal-video.min.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Logo from '@components/mir-ui/svg/Logo';
import HamubrerLine from '@components/mir-ui/svg/HamubrerLine';
import Button from '@components/mir-ui/buttons/ButtonLink';
import Search from '@components/mir-ui/svg/Search';
import Social from '@components/social-icons/Social';
import {StyledMenu} from '@components/layouts/menu/desktop/menu.style';
import MenuClose from '@components/mir-ui/svg/MenuClose';
import menuData from '@data/menu.json';
import NavigationList from '@components/layouts/menu/desktop/menu-parts/NavigationList';
import SingleParts from '@components/layouts/menu/desktop/menu-parts/SingleParts';
import ShortMenu from '@components/layouts/menu/desktop/menu-parts/ShortMenu';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {fetchPosts} from '@api/redux/footer';
import {useDispatch, useSelector} from 'react-redux';

gsap.registerPlugin(CSSPlugin);

const Menu = ({offset}) => {
  const router = useLocation();
  let tl = new TimelineLite();
  let tl2 = new TimelineLite();
  let tll = new TimelineLite();
  let [menuOpen, setMenuOpen] = useState(false);
  const mainMenuClickRef = useRef(null);
  const searchClickRef = useRef();
  const searchItemRef = useRef();
  const [searchInput, setSearchInput] = useState('');
  const [scrollUp, setScrollUp] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  //desktop menu contact dropdown
  useEffect(() => {
    document.querySelector('.click-dropdown')?.addEventListener('click', () => {
      if (!document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
        document.querySelector('.main-menu__contact').classList.add('open-dropdown');
        tl.to('.dropdown-menu-mir-parts', {
          duration: .2,
          display: 'block',
        }).to('.dropdown-menu-mir-parts', {
          y: '20px',
          duration: .4,
          height: 'calc(100% - 20px)',
          alpha: 1,
          paddingTop: '33px',
          paddingBottom: '15px',
          ease: 'circ.out'
        });
      } else {
        document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
        tl.to('.dropdown-menu-mir-parts', {
          duration: .3,
          height: '0%',
          alpha: 0,
          paddingTop: '0',
          y: '0px',
          ease: 'circ.out'
        }).to('.dropdown-menu-mir-parts', {
          duration: .2,
          display: 'none',
        });
      }
    });
    window.addEventListener('click', (e) => {
      if (document.querySelector('.main-menu__contact') && document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
        if (!e.target.matches('.click-dropdown, .click-dropdown p, .click-dropdown svg, .click-dropdown line')) {
          tl.to('.dropdown-menu-mir-parts', {
            duration: .3,
            height: '0%',
            alpha: 0,
            paddingTop: '0',
            y: '0px',
            ease: 'circ.out'
          }).to('.dropdown-menu-mir-parts', {
            duration: .2,
            display: 'none',
          });

          document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
        }
      }
    });


  }, []);


  // menu fixed on scroll
  useEffect(() => {
    if (document.body.classList.contains('scroll-down')) {
      document.body.classList.remove('scroll-down');
    }
  });

  useEffect(() => {
    const body = document.body;
    const scrollDown = 'scroll-down';
    let lastScroll = 0;
    let howMuchScroll = 20;
    window.addEventListener('scroll', () => {
      let currentScroll = window.pageYOffset;

      if (currentScroll <= howMuchScroll) {
        // body.classList.remove(scrollUp);
        setScrollUp(false);
        setScrollDown(false);

        return;
      }
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        // body.classList.remove(scrollUp);
        // body.classList.add(scrollDown);
        setScrollDown(true);
        setScrollUp(false);

      } else if (
        currentScroll < lastScroll
        // body.classList.contains(scrollDown)
      ) {
        // up
        // body.classList.remove(scrollDown);
        // body.classList.add(scrollUp);
        setScrollUp(true);
        setScrollDown(false);

      }
      lastScroll = currentScroll;
    });
  }, []);


  // search handle
  const handleInput = (e) => {
    setSearchInput(e.target.value);
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    if (searchInput === '') {
      return;
    } else {
      window.location.href = `/search?keyword=${searchInput}`;
      document.querySelector('.search-desktop').classList.remove('search-open');
      gsap.to(searchItemRef.current, {
        duration: .4, height: 0, opacity: 0, overflow: 'hidden'
      });
      gsap.to(searchItemRef.current.querySelector('.container'), {
        opacity: 0,
      }, '-=.4');
      gsap.to(searchItemRef.current, {
        display: 'none'
      });
    }
    // setSearchInput('')


  };

  const closeSearchBox = () => {
    if (searchItemRef.current.classList.contains('search-open')) {
      document.querySelector('body').classList.remove('open_menu');
      document.body.classList.remove('menu-open');
      document.querySelector('body').classList.remove('menu-is-open');

      searchItemRef.current.classList.remove('search-open');
      gsap.to(searchItemRef.current, {
        duration: 0.4,
        height: 0,
        opacity: 0,
        overflow: 'hidden',
      });
      gsap.to(searchItemRef.current.querySelector('.container'), {
        opacity: 0,
      }, '-=.4');
      gsap.to(searchItemRef.current, {
        display: 'none',
      });

    }
  };


  useEffect(() => {
    // Function to close the search single-mir-parts
    const closeSearchBox = () => {
      if (searchItemRef.current.classList.contains('search-open')) {
        document.querySelector('body').classList.remove('open_menu');
        document.body.classList.remove('menu-open');
        searchItemRef.current.classList.remove('search-open');
        gsap.to(searchItemRef.current, {
          duration: 0.4,
          height: 0,
          opacity: 0,
          overflow: 'hidden',
        });
        gsap.to(searchItemRef.current.querySelector('.container'), {
          opacity: 0,
        }, '-=.4');
        gsap.to(searchItemRef.current, {
          display: 'none',
        });
        document.querySelector('body').classList.remove('menu-is-open');


      }
    };

    // Open the search single-mir-parts when the search button is clicked
    searchClickRef.current.addEventListener('click', () => {
      if (!searchItemRef.current.classList.contains('search-open')) {
        searchItemRef.current.classList.add('search-open');
        document.querySelector('body').classList.add('menu-is-open');
        gsap.to(searchItemRef.current, {
          display: 'flex',
        });
        gsap.to(searchItemRef.current, {
          duration: 0.5,
          height: 180,
          opacity: 1,
          overflow: 'visible',
        });
        gsap.to(searchItemRef.current.querySelector('.container'), {
          opacity: 1,
          delay: 0.2,
        });
        document.querySelector('body').classList.add('open_menu');

        // Add a mousedown event listener to the document to check if the click occurred within the search single-mir-parts, input field, or the excluded element
        document.addEventListener('mousedown', (e) => {
          if (
            !searchItemRef.current.contains(e.target) &&
            !searchClickRef.current.contains(e.target) &&
            !e.target.classList.contains('search-desktop') &&
            !e.target.classList.contains('search-open')
          ) {
            closeSearchBox();
          }
        });

        setMenuOpen(false);

        tl2.to('.main-menu__items__list', {
          duration: .2,
          y: '-40px',
          alpha: 0
        }).to('.main-menu__items', {
          opacity: '0',
          duration: '.3'
        }).to('.main-menu__items__img', {
          opacity: '0',
          duration: '.2'
        }, '-=.5')
          .to('.main-menu__items .left-side-banner', {
            duration: .2,
            opacity: '0',
          }, '-=.3')
          .to('.main-menu__items .left-side-banner .menu-mega-menu', {
            display: 'none',
            duration: 0.5,
            ease: 'power2.inOut'
          }, '-=0.5')
          .to('.main-menu__items__list', {
            duration: .2,
            y: '40px',
            alpha: 0,
          }, '-=.3').to('.main-menu__items', {
            duration: '.4',
            opacity: 0
          }).to('.main-menu__items .d-flex-menu-mir-parts', {
            display: 'none',
            duration: 0.5,
            paddingTop: 0,
            ease: 'power2.inOut'
          }, '-=0.5')
          .to('.main-menu__items__list ul', {
            height: 0,
            duration: 0.5,
            ease: 'power2.inOut'
          }, '-=0.5').to('.main-menu__items__list ul li', {
            display: 'none',
            duration: 0.5,
            ease: 'power2.inOut'
          }, '-=0.5');
      } else {
        // closeSearchBox();
      }
    });

    // Remove the event listener when the component unmounts
    return () => {
      setMenuOpen(false);

      document.removeEventListener('mousedown', closeSearchBox);
      tl2.to('.main-menu__items__list', {
        duration: .2,
        y: '-40px',
        alpha: 0
      }).to('.main-menu__items', {
        opacity: '0',
        duration: '.3'
      }).to('.main-menu__items__img', {
        opacity: '0',
        duration: '.2'
      }, '-=.5')
        .to('.main-menu__items .left-side-banner', {
          duration: .2,
          opacity: '0',
        }, '-=.3')
        .to('.main-menu__items .left-side-banner .menu-mega-menu', {
          display: 'none',
          duration: 0.5,
          ease: 'power2.inOut'
        }, '-=0.5')
        .to('.main-menu__items__list', {
          duration: .2,
          y: '40px',
          alpha: 0,
        }, '-=.3').to('.main-menu__items', {
          duration: '.4',
          opacity: 0
        }).to('.main-menu__items .d-flex-menu-mir-parts', {
          display: 'none',
          duration: 0.5,
          paddingTop: 0,
          ease: 'power2.inOut'
        }, '-=0.5')
        .to('.main-menu__items__list ul', {
          height: 0,
          duration: 0.5,
          ease: 'power2.inOut'
        }, '-=0.5').to('.main-menu__items__list ul li', {
          display: 'none',
          duration: 0.5,
          ease: 'power2.inOut'
        }, '-=0.5');
    };
  }, []);


  useEffect(() => {
    setMenuOpen(false);
  }, [router?.pathname]);


  // Function to handle the click event and toggle the class
  const toggleMenu = () => {

    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    const display = menuOpen ? 'none' : 'block';
    const displayflex = menuOpen ? 'none' : 'flex';
    const opacity = menuOpen ? '0' : '1';
    const y = menuOpen ? '100%' : '0';
    const height = menuOpen ? '0' : 'calc(100vh - 100px)';

    if (window.safari !== undefined) {
      document.body.classList.add('overflow');
    }

    // Hide/show scrollbar while preventing scroll
    // document.querySelector('html').style.overflow = menuOpen ? 'auto' : 'hidden';

    tll.to('.main-menu__items', {
      height: height,
      opacity: opacity,
      duration: 0.6,
      ease: 'power2.inOut'
    })
      .to('.main-menu__items__img', {
        opacity: opacity,
        duration: 0.3,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items .left-side-banner', {
        opacity: opacity,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5').to('.main-menu__items .left-side-banner .menu-mega-menu', {
        display: display,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items__list ul', {
        height: height,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items .d-flex-menu-mir-parts', {
        display: displayflex,
        duration: 0.5,
        paddingTop: 65,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items__list', {
        y: y,
        alpha: opacity,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5').to('.main-menu__items__list ul li', {
        display: display,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5');


    if (menuOpen) {
      setTimeout(() => {
        document.querySelector('.main-menu').classList.remove('menu-is-open');
        document.querySelector('body').classList.remove('menu-is-open');
      }, 300);
    } else {
      setTimeout(() => {
        document.querySelector('.main-menu').classList.add('menu-is-open');
        document.querySelector('body').classList.add('menu-is-open');
      }, 300);
    }
  };


  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };
  const dispath = useDispatch();
  const location = useLocation();



  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SETTINGS;
    dispath(fetchPosts([api_url]));

  }, [dispath, location?.pathname]);
  let getPost = useSelector(state => state.footer);

  const data = getPost?.posts?.data;

  return (
    <>

      <StyledMenu offset={offset}
        className={`main-menu menu-bar ${scrollDown ? 'scroll-down' : ''} ${scrollUp ? 'scroll-up' : ''} `}>


        {/*menu bar*/}
        <Container className={'menu-desktop-top'}>
          <Row>
            <Col md={3} className={'main-menu__logo justify-content-start d-flex'}>
              <div className="normal">
                <a onClick={handleClick}>
                  <Logo/>
                </a>
              </div>
              <div className="sticky">
                <Link to={'/'}>
                  <Logo reverse/>
                </Link>
              </div>
            </Col>
            <Col md={5} className={'main-menu__menu'}>
              <ShortMenu/>
            </Col>
            <Col md={4} className={'main-menu__search justify-content-end align-items-center d-flex'}>
              <div ref={searchClickRef} className="search_button hoverable">
                <Search/>
              </div>
              <div className="button">
                <Button margin={'0 20px'} src={'#'} target={'_blank'} hoverBackground={hover} hoverTextcolor={'white'}
                  background={'transparent'} color={'#F1F0EE'} borderColor={'#F1F0EE'}
                  text={'Open an Account'}/>
              </div>
              <div className={'main-menu__menu__inner  '}>
                <div className={'wrap hoverable '}>
                  <div className={'main-menu-click'} ref={mainMenuClickRef} onClick={toggleMenu}>
                    <HamubrerLine on close={menuOpen}/>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/*menu items*/}
        <div className="main-menu__items" style={{display: 'block'}}>
          <div className="d-flex">
            <Col sm={8} className={'left-side-banner'}>
              <div className="menu-mega-menu">
                <div className="address">
                  <SingleParts title={'Visit'} value={data?.office_location} linkUrl={data?.office_location_map} openInNewTab={true}/>
                  <SingleParts title={'Call'} value={data?.office_phone} linkUrl={`tel:${data?.office_phone}`} openInNewTab={false}/>
                  <SingleParts title={'Mail'} value={data?.info_email} linkUrl={`mailto:${data?.info_email}`} openInNewTab={false}/>
                </div>
                <div className="social-wrapper">
                  <p>Socials</p>
                  <Social facebook={data?.facebook} twitter={data?.twitter} instra={data?.instagram} linkedin={data?.linkedin} youtube={data?.youtube} iconColor={white}/>
                </div>
                <div className={'menu-banner-wrap'}>
                  <ImgLazyLoad src={data?.menu_image ? 'https://project.bestinbd.com/projects/web/2308MST/dev/admin/'+ data?.menu_image : '/images/dynamic/menu-banner@2x.jpg'}/>
                </div>
              </div>
            </Col>
            <Col sm={4} className={'p-0'}>
              <div className="main-menu__items__list d-flex-list">
                <Col className={'menu-col'} sm={{span: 12}}>
                  <NavigationList items={menuData}/>
                </Col>
              </div>
            </Col>
          </div>
        </div>


        {/*search */}
        <div className="search-desktop" ref={searchItemRef}>
          <Container>
            <div onClick={closeSearchBox} className="clickto clickto_close">
              <MenuClose/>
            </div>
            <Form onSubmit={searchSubmit}>
              <Form.Group className="search-input form-group">
                <Form.Control value={searchInput} onChange={e => handleInput(e)} type="text"
                  placeholder="Type here for your search
                                                  "/>
                <button type="submit">Search</button>
              </Form.Group>
            </Form>
          </Container>
        </div>
        <div className="container border-container"></div>
        <div className="overlay"></div>
      </StyledMenu>
    </>
  );
};


export default Menu;
