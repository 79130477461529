import React from 'react';
import styled from 'styled-components';

const ToDown = () => {
  return (
    <StyledToDown>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_18498" data-name="Group 18498" transform="translate(-98 -688)">
          <g id="Ellipse_18" data-name="Ellipse 18" transform="translate(98 688)" fill="none" stroke="#fff" strokeWidth="1">
            <circle cx="20" cy="20" r="20" stroke="none"/>
            <circle className={'circle'} cx="20" cy="20" r="19.5" fill={'none'}/>
          </g>
          <g id="Group_18497" data-name="Group 18497" transform="translate(-2.904 -2.277)">
            <path id="Path_9568" data-name="Path 9568" d="M0,8,8,0l8,8" transform="translate(128.904 718.277) rotate(180)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_9569" data-name="Path 9569" d="M118.9,714.117v-16" transform="translate(2 4.16)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          </g>
        </g>
      </svg>

    </StyledToDown>
  );
};

const StyledToDown = styled.div`
    .circle {
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;

    }

    &:hover {
        .circle {
            r: 20px;
        }
    }
`;

export default React.memo(ToDown);
