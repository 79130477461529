export const apiEndPoints = {
  POSTS: 'get-req-data/posts',
  PHOTOS: 'get-req-data/photos',
  PAGE_DATA:'get-req-data/page-data',
  SECTIONS: 'get-req-data/sections',
  SERVICERESEARCH: 'get-req-data/research-documents-all',
  JOB: 'get-req-data/job-menu-mir-parts',
  TEAM: 'get-req-data/team-menu-mir-parts',
  CHILD_PAGE: 'get-req-data/child-pages',
  PAGE_POST_DATA: 'get-req-data/page-post-data',
  SEARCH : 'get-req-data/search',
  ALL_CATEGORY: 'get-req-data/all-category',
  CHILD_CATEGORY: 'get-req-data/child-category',
  PRODUCT_DATA: 'get-req-data/product-data',
  ALL_PRODUCTS: 'get-req-data/all-products',
  POST_FORM: 'post-req-data/mir-form-submit',
  SERVICE_FORM: 'post-req-data/mir-form-submit',
  GETINTOUCH_FORM: 'post-req-data/mir-form-submit',
  FIND_DEALER: 'get-req-data/dealer-location',
  SETTINGS: 'get-req-data/settings-data',
  COURSEDATA: 'get-req-data/course-data',
  BlogData: 'get-req-data/blog-data',
  BLOGLIST: 'get-req-data/blog-list',

  RESEARCHLIST: 'get-req-data/research-menu-mir-parts',
  ADDSUBSCRIP: 'post-req-data/add-subscriber',
  SUBMITFORM: 'post-req-data/form-submit',
  INVESTOR: 'get-req-data/investor-data',
  REARCHLISTCAT: 'get-req-data/research-documents',
  ABOUT: 'get-req-data/about-us',
  SUBSCRIBENEWSLETTER: 'get-req-data/about-us'
};
