import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import {Col} from 'react-bootstrap';
import {downloadBg, hover} from '@assets/css/globalStyleVars';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import Button from '@components/mir-ui/buttons/ButtonLink';
import {VECTOR2} from '@api/network/baseUrl';
import ThemeRow from '../../../theme/row';

const Index = ({padding, data}) => {

  const sectionData = data?.section_data;
  const sectionImages = data?.images;

  return (
    <StyledOverview3 className={`${padding ? padding : 'pt-150 pb-150'} overview`}>
      <ThemeRow>
        <Col sm={5} className="overview__left">
          <h2 className={'split-up'}>{sectionData?.title ? sectionData?.title : ''}</h2>

          {
            sectionData?.description &&
            <p className={'split-up'}>{ReactHtmlParser(sectionData?.description)}</p>

          }
          {
            sectionData?.subtitle &&
            <Button src={sectionData?.subtitle} background={'transparent'} margin={'40px 0 0'} color={hover} hoverBackground={hover} hoverTextcolor={'white'} borderColor={hover} text={sectionData?.short_desc ? sectionData?.short_desc : 'Investment Calculator'}/>

          }


        </Col>
        <Col sm={{span: 5, offset: 2}} className="overview__right">
          <div className="image-wrapper">
            <ImgLazyLoad radius={'10px'} alt={sectionData?.title} src={sectionImages?.list?.[0]?.full_path}/>
          </div>
          {
            window?.innerWidth > 992 ?
              <div className="fixed-image box">
                <img src={sectionImages?.list?.[1]?.full_path} alt={sectionData?.titless}/>
              </div>
              :
              ''
          }
        </Col>
      </ThemeRow>

      <div className="bottom-bg">
        <img src={VECTOR2}/>
      </div>
    </StyledOverview3>
  );
};

const StyledOverview3 = styled.section`
    background-color: ${downloadBg};
    position: relative;

    .container {
        position: relative;
        z-index: 1;
    }

    .overview__left {
        h2 {
            margin-bottom: 30px;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
        }
    }

    .overview__right {
        position: relative;

        .image-wrapper {
            position: relative;
            padding-top: calc(600 / 470 * 100%);
        }

        .fixed-image {
            position: absolute;
            bottom: 40px;
            left: -25%;

            img {
                border-radius: 10px;
            }
        }
    }

    .bottom-bg {
        position: absolute;
        left: 0;
        bottom: 120px;
        z-index: 0;
        opacity: 0.1;
        @media (max-width: 992px) {
            display: none !important;
            img {
                display: none !important;
            }
        }
    }

    @media (max-width: 992px) {
        .overview__left, .overview__right {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 !important;
        }

        .overview__right {
            margin-top: 40px !important;
        }
    }
`;

export default Index;




