import React from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import {Col, Container, Row} from 'react-bootstrap';
import Social from '@components/social-icons/Social';
import CloseIcon from '@components/mir-ui/svg/CloseIcon';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import reactHtmlParser from 'react-html-parser';

const PeoplePopup = ({
  show,
  handleClose,
  no_img,
  item,
  title,
  description,
  data,
  subtitle,
  img
}) => {


  const popupData = data;


  return (

    <StyledModal>
      <Modal
        show={show}
        item={item}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="gph_modal popup-version-one"
      >
        <div className="noise"></div>

        <div className="main_scroll" style={{height: '100vh'}}>
          <Modal.Body>

            <Container>
              <Row className={'for-close'}>
                <div onClick={handleClose} className="modal-close ">
                  <CloseIcon/>
                </div>

              </Row>
            </Container>
            <Container>
              <Row>
                <div className="modal-data modal-main-content d-flex">
                  <Col sm={8} className="">
                    <div className="profile-details">

                      {
                        reactHtmlParser(popupData?.data?.description ? popupData?.data?.description : popupData?.data?.body)
                      }
                    </div>
                  </Col>
                  <Col sm={4} className="">
                    <div className="profile-info">
                      <div className="img-wrp">
                        <ImgLazyLoad src={popupData?.images?.[0]?.full_path ? popupData?.images?.[0]?.full_path : popupData?.images?.list?.[0]?.full_path}/>
                      </div>
                      <h3>{popupData?.data?.title}</h3>
                      <p>{popupData?.data?.subtitle ? popupData?.data?.subtitle : popupData?.data?.designation}</p>

                      <Social linkedin={data?.data?.linkedin} facebook={data?.data?.facebook} youtube={data?.data?.youtube} instra={data?.data?.instragram}/>

                    </div>
                  </Col>


                </div>
              </Row>
            </Container>
          </Modal.Body>
        </div>
      </Modal>
    </StyledModal>

  );
};


const StyledModal = styled.div`

    .modal-dialog {
        margin: 0;
    }

    h4 {
        letter-spacing: 1.5px;
    }

`;


export default React.memo(PeoplePopup);