import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {CSSPlugin, gsap, TimelineLite} from 'gsap';
gsap.registerPlugin(CSSPlugin);


const useHideMenu = () => {

  const location = useLocation();
  let tl2 = new TimelineLite();

  useEffect(() => {
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let getItems = document.querySelectorAll('.main-item');
    let getBacks = document.querySelectorAll('.sub-menu-back');

    // menu open toggle
    document.body?.classList.remove('menu-on');
    document.querySelector('.main-menu-mobile')?.classList.remove('menu-is-open');
    getMobileMenuBar?.classList.remove('menu-open');
    document.body?.classList.remove('stop-scroll');
    tl2.to(getItemsParent, {
      duration: .2,
      display: 'none',
    }).to(getItemsParent, {
      duration: .2,
      x: '100%'
    }, '-=.2');



    // sub menu toggle
    getItems?.forEach(i => {
      i.addEventListener('click', () => {
        getItemsParent.classList.add('active');
        i.classList.add('active');
      });
    });

    getBacks?.forEach(i => {
      i.addEventListener('click', (e) => {

        getItemsParent.classList.remove('active');
        i.parentNode.parentElement.classList.remove('active');
        e.stopPropagation();
      });
    });
  }, [location?.pathname]);
};

export default useHideMenu;
