import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {gray, text, white} from '@assets/css/globalStyleVars';
import TeamBox from '@components/mir-ui/single-parts/TeamBox';

const DirectorsList = ({padding, data}) => {

  return (
    <StyledDirectorsList className={`${padding ? padding : 'pt-80 pb-80'}`}>
      <Container>
        <Row>
          {
            data?.posts?.list && data?.posts?.list?.length > 0 ?
              data?.posts?.list?.map((item, index) => {
                return (
                  <Col key={index} md={4}>
                    <TeamBox description={item?.data?.description} tcolor={text} data={item} dcolor={gray} title={item?.data?.title} img={item?.images?.[0]?.full_path} des={item?.data?.subtitle}/>
                  </Col>
                );
              })
              :
              data?.posts?.list?.map((item, index) => {
                return (
                  <Col key={index} md={4}>
                    <TeamBox description={item?.data?.description} tcolor={text} data={item} dcolor={gray} title={item?.data?.title} img={item?.images?.[0]?.full_path} des={item?.data?.subtitle}/>
                  </Col>
                );
              })

          }


        </Row>

      </Container>

    </StyledDirectorsList>
  );
};

const StyledDirectorsList = styled.section`
    background-color: #FAFAFA;
    margin-top: -1px;

    h2 {
        margin-bottom: 40px;
        color: ${white};
    }

    .col-md-4 {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {
        .col-md-4 {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

export default  React.memo(DirectorsList);




