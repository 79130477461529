import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts} from '../../api/redux/contact';
import {useDispatch, useSelector} from 'react-redux';
import gsap, {CSSPlugin} from 'gsap';
import {SplitText} from 'gsap/SplitText';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {useLocation} from 'react-router-dom';
import AddressContact from './contacts-address/AddressContact';
import MapForm from './contact-forms/MapForm';
import {ApiParam} from '../../api/network/apiParams';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../lib/PageAnimation';
import ContactsBanner from '@pages/contacts/contacts-banner';
import ContactsCta from '@pages/contacts/contacts-cta';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const CONTACTUS = () => {


  const dispath = useDispatch();
  const location = useLocation();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'contact',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));

  }, [dispath, location?.pathname]);


  let getPost = useSelector(store => store.contact);

  let page_data = getPost?.posts?.data?.page_data;


  const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-banner');
  const address = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'address');
  const form = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'form');
  const contact_cta = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'cta-contact');


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'182'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>
        <ContactsBanner noPara title={banner?.section_data?.subtitle}
          imageSrc={banner?.images?.list?.[0]?.full_path}/>
        <AddressContact data={address}/>
        <MapForm data={form}/>
        <ContactsCta cta={contact_cta}/>
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`
`;

export default CONTACTUS;
