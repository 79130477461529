import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from 'react-bootstrap';
import Button from '@components/mir-ui/buttons/ButtonLink';
import {useForm} from 'react-hook-form';
import {gray, softGray, text, white} from '@assets/css/globalStyleVars';
import reactHtmlParser from 'react-html-parser';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {postForm} from '@api/redux/contact';
const MapForm = ({padding, data, id}) => {


  let [offset, setOffset] = useState(90);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 15);
    }
  }, [data]);


  const dispatch = useDispatch();
  const responseData = useSelector(state => state.contact);

  const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});


  // --- form submit
  const success = (msg) => toast.success(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  const error = (msg) => toast.error(msg, {
    position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,

  });

  // const handleBlur = () => {
  // };

  // const handleChange = () => {
  // };
  // const [formSubmitted, setFormSubmitted] = useState(false);


  const onSubmit = async (e) => {

    let api_services = apiEndPoints.SUBMITFORM;
    var formData = new FormData();
    formData.append('name', e?.name);
    formData.append('email', e?.email);
    formData.append('phone', e?.phone);
    formData.append('message', e?.message);
    formData.append('form_id', 'contact-form');

    if (e.email !== '' && e.name !== '' && e.phone !== '') {
      dispatch(postForm([api_services, formData]));
      reset();

    }

    e.preventDefault();

  };
  let count = 0;
  const onError = (errors) => {
    Object.values(errors).forEach((error) => {
      count++;
    });
    if (count > 0) {
      toast.error('please fill out the correct inputs');
    }
    count = 0;
  };


  useEffect(() => {
    if (responseData && responseData?.error !== '') {
      error(responseData?.error?.message);
      reset();
    }
    if (responseData && responseData?.success !== '') {
      success(responseData?.success);
      reset();
    }
  }, [responseData]);


  return (<StyledListWithForm id={`${id ? id : 'ListWithForm'}`}
    className={`list_with_form ${padding ? padding : ''} `}>
    <Container fluid>
      <Row className={'align-items-center'}>
        <Col md={5} className={'left-col '} style={{paddingLeft: offset ? offset + 'px' : '90px'}}>
          <h2>{data?.section_data?.short_desc ? reactHtmlParser(data?.section_data?.short_desc) : ''}</h2>
          <p>{data?.section_data?.description ? reactHtmlParser(data?.section_data?.description) : ''}</p>
        </Col>
        <Col className={'pr-0 pl-0'} md={{span: 7}}>
          <div style={{paddingRight: offset ? offset + 'px' : '90px'}} className="form_wrapper">
            <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)} >

              <input name={'spam_protector'} type="hidden"/>
              <input name={'form_id'} value={'contact-form'} type="hidden"/>
              <div className="form-group">
                <Form.Group controlId="formBasicEmail">

                  <Form.Control
                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                    {...register('name', {
                      required: 'Name is required',

                    })}
                    type="text"
                    placeholder="Name*"
                  />
                </Form.Group>
              </div>
              <div className="form-group">
                <Form.Group controlId="formBasicEmail">

                  <Form.Control
                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                    {...register('email', {
                      required: {
                        value: true, message: 'please enter your email',
                      }, pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'please enter a valid email address',
                      },
                    })}
                    type="email"
                    placeholder="Email*"/>
                </Form.Group>
              </div>
              <div className="form-group">
                <Form.Group controlId="formBasicPhone">

                  <Form.Control
                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                    {...register('phone', {
                      required: {
                        value: true, message: 'please enter your phone first',
                      }, pattern: {
                        value: /^01[0-9]{9}$/,
                        message: 'please enter a valid 11 digit phone number',
                      },
                    })}
                    type="number"
                    placeholder="Phone Number*"/>
                </Form.Group>
              </div>
              <div className="form-group">
                <Form.Group controlId="formBasicPhone">
                  <textarea
                    className={formState?.errors?.email?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                    {...register('message', {
                      // required: {
                      //     // value:true,
                      //     message: 'please enter your Message',
                      // },
                    })}
                    placeholder="Message (Optional)"
                    rows={4} // You can adjust the number of rows as needed
                  />
                </Form.Group>

              </div>

              <div className={'form-group width-fit'}>
                <div onClick={handleSubmit(onSubmit, onError)}>
                  <Button src={'#'} color={'#F1EEE9'} icon_border={'#F1EEE9'}
                    text={'Submit Query'}/>
                </div>

              </div>

            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  </StyledListWithForm>);
};

const StyledListWithForm = styled.div`
    background: #FFFFFF;

    .form_wrapper {
        background: ${gray};
        padding: 60px;
        height: 100%;
        position: relative;
        // overview_bg

        .left-col {
            div {
                height: 100%;
            }

            p {
                margin-top: 40px;
                font-size: 24px;
                line-height: 32px;
                font-weight: 500;

            }
        }

        @media (max-width: 992px) and (min-width: 768px) {
            padding: 30px;
        }

        form {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
        }

        .textarea {
            min-height: 120px;
        }

        .title {
            font-size: 24px;
            font-weight: 4000;
            line-height: 28px;
            color: #F9F9F9;
            margin: 0 0 40px;
        }

        .form-group {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                display: block;
            }

            .form-control {
                border-radius: 10px !important;
                box-shadow: none;
                outline: 0;
                background: transparent;
                border-color: rgba(255, 255, 255, 0.5);
                padding: 15px 20px;
                height: 50px;
                line-height: 21px;
                color: ${white};
                font-size: 12px;
                font-weight: 500;

                svg {
                    display: block;
                }

                &::placeholder {
                    color: ${white};
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            .dc-btn {
                a {
                    min-width: 250px;
                    display: flex;
                    align-items: center;
                    color: ${text};
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    padding: 20px 30px;
                    line-height: 21px;
                    text-transform: capitalize;
                    height: 50px;
                    background-color: ${softGray};
                    position: relative;
                    border-radius: 5px !important;
                    z-index: 0;
                    transition: all .5s cubic-bezier(.54, .32, .05, .96);
                    border: 1px solid ${gray};
                    overflow: hidden;
                    text-align: center;
                    justify-content: center;

                }


            }

        }


    }

    .left-col {
        div {
            height: 100%;
        }

        p {
            margin-top: 40px;
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;

        }
    }

    @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 0;
        .width-fit {
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;
            display: block !important;
        }

        .dc-btn {
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;

            a {
                max-width: 100% !important;
                width: 100% !important;
                min-width: 100% !important;
            }
        }

        .pr-0 {
            padding-left: 0 !important;
        }

        .left-col {
            padding-top: 60px;
            padding-left: 15px !important;
            padding-bottom: 40px;
        }

        .form_wrapper {
            padding: 60px 15px !important;
            margin-top: 0px;
        }

        .left-col {
            padding-right: 0px;
        }

        .container {
            padding: 0;
        }

        .width-fit {
            display: flex;
        }
    }
`;

export default React.memo(MapForm);
