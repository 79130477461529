import React from 'react';
import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

const NavigationIconBold = ({next_id, prev_id, color, banner}) => {


  return (

    <StyledNavigationIconBold className={'navigation_button'}>
      <ul>
        <li className="hover_left hoverable" id={prev_id ? prev_id : 'service-prev'}>

          {
            banner ?
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Group_21120" data-name="Group 21120" transform="translate(-388 -2131)">
                  <g id="Rectangle_5479" data-name="Rectangle 5479" transform="translate(388 2131)" fill="none" stroke="#e6e5df" strokeWidth="1">
                    <rect width="50" height="50" rx="5" stroke="none"/>
                    <rect x="0.5" y="0.5" width="49" height="49" rx="4.5" fill="none"/>
                  </g>
                  <g id="Group_18503" data-name="Group 18503" transform="translate(-174.734 1739.251) rotate(-45)">
                    <path id="Path_9568" data-name="Path 9568" d="M0,8,8,0l8,8" transform="translate(128.904 718.277) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <path id="Path_9569" data-name="Path 9569" d="M118.9,714.117v-16" transform="translate(2 4.16)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  </g>
                </g>
              </svg>

              :

              <svg id="Group_18599" data-name="Group 18599" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <rect id="Rectangle_5479" data-name="Rectangle 5479" width="50" height="50" rx="5" fill="#e6e5df"/>
                <g id="Group_18504" data-name="Group 18503" transform="translate(25 36.314) rotate(-135)">
                  <path id="Path_9568" data-name="Path 9568" d="M0,0,8,8l8-8" transform="translate(16 8) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  <path id="Path_9569" data-name="Path 9569" d="M0,0V16" transform="translate(8)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                </g>
              </svg>

          }


        </li>
        <li className="hover_right hoverable" id={next_id ? next_id : 'service-next'}>


          {
            banner ?
              <svg id="Group_21121" data-name="Group 21121" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Rectangle_5479" data-name="Rectangle 5479" fill="none" stroke="#e6e5df" strokeWidth="1">
                  <rect width="50" height="50" rx="5" stroke="none"/>
                  <rect x="0.5" y="0.5" width="49" height="49" rx="4.5" fill="none"/>
                </g>
                <g id="Group_18503" data-name="Group 18503" transform="translate(25 13.686) rotate(45)">
                  <path id="Path_9568" data-name="Path 9568" d="M0,0,8,8l8-8" transform="translate(16 8) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  <path id="Path_9569" data-name="Path 9569" d="M0,0V16" transform="translate(8)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                </g>
              </svg>

              :
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <rect id="Rectangle_5479" data-name="Rectangle 5479" width="50" height="50" rx="5" fill="#e6e5df"/>
                <g id="Group_18503" data-name="Group 18503" transform="translate(-391.749 612.734) rotate(-135)">
                  <path id="Path_9568" data-name="Path 9568" d="M0,8,8,0l8,8" transform="translate(128.904 718.277) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  <path id="Path_9569" data-name="Path 9569" d="M118.9,714.117v-16" transform="translate(2 4.16)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                </g>
              </svg>

          }

        </li>
      </ul>
    </StyledNavigationIconBold>

  );
};


const StyledNavigationIconBold = styled.div`
    position: relative;
    z-index: 10;

    ul {
        display: flex;

        li {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;
            padding: 0 !important;
            border: none !important;
            background: transparent !important;

            &:nth-of-type(1) {
                margin-right: 20px;
            }

            #Rectangle_5479 rect {
                transition: ${themeGet('transition')};

            }

            #Rectangle_5479, #Group_18503, #Group_18504 {
                transition: ${themeGet('transition')};

            }

            &:hover {
                #Rectangle_5479 {
                    rx: 25px;
                    //transform: translate(388px, 2181px) rotate(-90deg);
                }

                #Group_18503 {
                    //transform: translate(67%, 67%) rotate(-90deg);

                }

                #Group_18504 {
                    //transform: translate(30%, -67%) rotate(-90deg);
                }
            }

        }
    }


    @media (max-width: 767px) {
        #Rectangle_5479 rect {
            rx: 25px !important;
            //transform: translate(0, 50px) rotate(-90deg) !important;
        }

        #Group_18503 {
        }
    }

`;

export default React.memo(NavigationIconBold);














