import React from 'react';
import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import {gray, white} from '@assets/css/globalStyleVars';
import NavigationIcon from '@components/mir-ui/navigaiton/NavigationIcon';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';
import ProductSingle from '@components/mir-ui/single-parts/ProductSingle';
import ReactHtmlParser from 'react-html-parser';
import ThemeContainer from '../../../theme/container';
import ThemeRow from '../../../theme/row';

const HomeProduct = ({padding, data}) => {

  const sectionData = data?.section_data;
  const servicesList = data?.posts?.list;

  return (
    <StyledHomeProduct className={`${padding ? padding : 'pt-150 pb-150'}`}>

      <ThemeContainer colProps={{md: 12}} colClassName={'title-wraper'}>
        <h2 className={'split-up'}>{sectionData?.title ? sectionData?.title : ''}</h2>
        {
          sectionData?.description &&
          <p className={'p1-font split-up'}>{ReactHtmlParser(sectionData?.description)}</p>

        }
      </ThemeContainer>


      {
        window?.innerWidth > 767 ?
          <ThemeRow>

            {
              servicesList && servicesList?.length > 0 &&
              servicesList.map((item, index) => {
                return (
                  <Col key={index} md={4}>
                    <ProductSingle link={item?.data?.subtitle} title={item?.data?.title} img={item?.images?.[0]?.full_path}/>
                  </Col>
                );

              })
            }
          </ThemeRow>

          :
          <ThemeContainer colProps={{md: 12}}>
            {
              servicesList && servicesList?.length > 0 &&
              <NavigationIcon prev_id={'next-prev'} next_id={'story-next'} color={gray}/>


            }

            {
              servicesList && servicesList?.length > 0 &&
              <Swiper
                modules={[Autoplay, Pagination, Navigation, EffectFade]}
                slidesPerView={1}
                speed={1500}
                spaceBetween={50}
                centeredSlides={true}

                navigation={{
                  prevEl: '#story-prev',
                  nextEl: '#story-next',
                }}

                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,

                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 15,

                  },
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
                loop={true}
              >


                {
                  servicesList && servicesList?.length > 0 &&
                  servicesList.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <ProductSingle link={item?.data?.subtitle} title={item?.data?.title} img={item?.images?.[0]?.full_path}/>
                      </SwiperSlide>
                    );

                  })
                }

              </Swiper>
            }


          </ThemeContainer>
      }



    </StyledHomeProduct>
  );
};

const StyledHomeProduct = styled.section`
    background-color: ${white};


    p {
        margin-bottom: 40px;
    }

    h2, h3 {
        margin-bottom: 20px;
    }

    @media (max-width: 767px) {

        .navigation_button {
            margin-bottom: 40px;
        }


        .col-md-4 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 0 0;
        }
    }


`;

export default React.memo(HomeProduct);




