import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {hover, text, white} from '@assets/css/globalStyleVars';
import SelectField from '@components/mir-ui/input/SelectField';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.css';
import moment from 'moment/moment';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {ApiParam as ApiParamKey} from '@api/network/apiParams';
import {fetchResearch} from '@api/redux/research';
import {useDispatch, useSelector} from 'react-redux';

const TableText = ({padding, data, types}) => {
  const dateRangePickerRef = useRef(null);
  // const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  // Get research data from Redux
  const getPost = useSelector(store => store.research);
  const [dataFilter, setFilter] = useState(getPost?.researchFilter?.data || data?.data);
  const [currentReport, setCurrentReport] = useState(1);
  const [dateRange, setDateRange] = useState(null);


  const dispatch = useDispatch();


  // Fetch and filter data on initial load and when researchFilter changes
  useEffect(() => {
    if (!getPost?.researchFilter?.data && types?.length > 0) {
      handleResearch(types[0].value); // Default to first type if available
    }
  }, []);

  useEffect(() => {
    setFilter(getPost?.researchFilter?.data);
  }, [getPost?.researchFilter?.data]);

  // Handle research API call
  const handleResearch = (type) => {
    if (type) {
      const api_url_search = apiEndPoints.REARCHLISTCAT;
      const param = {
        [ApiParamKey.Filter]: type,
        [ApiParamKey.TYPE]: 1
      };
      dispatch(fetchResearch([api_url_search, param]));
      setCurrentReport(type);
    }
  };


  const formatDate = (date) => {
    // Helper function to format the date as yyyy-mm-dd.
    return date.toISOString().slice(0, 10);
  };

  const handleSelect = (dates) => {
    let start = formatDate(dates?.[0]);
    let end = formatDate(dates?.[1]);

    let api_url_search = apiEndPoints.REARCHLISTCAT;
    let param = {
      [ApiParamKey.TYPE]: 1,
      [ApiParamKey.Filter]: currentReport,
      [ApiParamKey.STARTDATE]: start,
      [ApiParamKey.ENDDATE]: end,

    };
    dispatch(fetchResearch([api_url_search, param]));
    setDateRange(dates);
  };


  // Handle focus and blur to lock scroll when date picker is active
  const handleFocus = () => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  };

  const handleBlur = () => {
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
  };



  return (
    <StyledTableText className={`table-custom ${padding ? padding : ' pb-150'}`}>
      <Container>
        <Row className={'align-items-end'}>

          <Col md={3}>
            <p className="filter">Filter by</p>
            <SelectField onChange={(e) => handleResearch(e.value,)} option={types} selection={types} placeholder={'Select Topic'}/>
          </Col>
          <Col md={3}>
            {/*<SelectField/>*/}
          </Col>
          <Col md={{span: 3, offset: 3}} className={'last-content'}>

            {
              window.innerWidth > 767 ?

                <p className="filter">Filter by</p>
                :

                ''
            }
            < DateRangePicker editable={true} ref={dateRangePickerRef}
              cleanable={false}
              onChange={handleSelect}
              onFocus={handleFocus} showMeridian
              onBlur={handleBlur} character={' to '} value={dateRange} preventOverflow={true}
              placement={'auto'}
              placeholder={'Start Date to End Date'}/> </Col>

        </Row>
        <Row>
          <Col md={12} className={'content'}>
            <table className="table">
              <tbody>
                <tr>
                  <td>Serial No</td>
                  <td>Date</td>
                  <td>Title</td>
                  <td>File</td>
                </tr>


                {
                  dataFilter && dataFilter?.length > 0 ?
                    dataFilter?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item?.date ? item?.date : '27, Sep 2021').format('DD MMMM YYYY')}</td>
                          <td>{item?.title}</td>
                          <td><a href={item?.attachment} className={'link'} target={'_blank'} rel="noreferrer">Download</a></td>

                        </tr>
                      );
                    })
                    :
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                }
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </StyledTableText>
  );
};

const StyledTableText = styled.section`
    padding-top: 80px;

    .filter {
        font-weight: 500;
        line-height: 21px;
        font-size: 14px;
        color: ${text};
        margin-bottom: 20px;
    }

    h4 {
        color: rgba(34, 34, 34, 0.5);
        line-height: 30px;
        font-weight: 600;
        margin: 0px 0px 25px;
        font-size: 20px;
        text-transform: uppercase;
    }

    .content {
        margin-top: 60px;
    }

    .last-content {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .table {
        margin: 0px 0px 25px;
        border: 1px solid rgba(199, 196, 184, 0.3) !important;

        tr {
            width: 100%;
            display: flex;
            flex-wrap: unset;
            flex-direction: row;
            padding: 0 !important;
            border: none !important;
            font-size: 16px;
            font-weight: 500;
            line-height: 27px;
            color: ${white} !important;
            gap: 0 !important;
            justify-content: space-between;

            &:first-child {
                width: 100%;
                display: flex;
                padding: 0px;
                border: none;
                background: ${hover} !important;

                td {
                    color: ${white};
                    border-right: 1px solid white;

                    &:last-child {
                        border-right: none;
                    }
                }
            }

            &:nth-of-type(2n+1) {
                background: #FFFFFF;
            }

            &:nth-of-type(2n) {
                background: #FAFAFA;
            }

            td {
                padding: 20px 35px;
                width: 100%;
                display: inline-flex;
                text-align: center;
                border-top: none !important;
                border-bottom: none !important;
                border-right: 1px solid rgba(199, 196, 184, 0.3) !important;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                max-width: unset;
                flex: unset;
                font-size: 16px;
                font-weight: 500;
                line-height: 27px;
                color: ${text};

                &:nth-of-type(3) {
                    min-width: 40%;
                }

                &:last-child {
                    border-right: none;
                }

                a {
                    color: ${text};
                }
            }
        }
    }

    @media (max-width: 767px) {
        .last-content {
            display: block;
        }

        .content {
            margin-top: 30px;
        }

        .col-md-3 {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    @media (max-width: 767px) {
        table.table {
            display: block;
            overflow-x: scroll;

            tbody {
                width: 100%;
            }

            td {

                min-width: 225px !important;
                padding: 20px 15px;
            }
        }
    }
`;


export default React.memo(TableText);
