import React from 'react';
import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import {gray, whiteSoft} from '@assets/css/globalStyleVars';
import HoverBox from '@components/mir-ui/single-parts/HoverBox';
import NavigationIcon from '@components/mir-ui/navigaiton/NavigationIcon';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';
import ReactHtmlParser from 'react-html-parser';
import ThemeContainer from '../../../theme/container';
import ThemeRow from '../../../theme/row';

const OurService = ({padding, data}) => {

  const sectionData = data?.section_data;
  const servicesList = data?.posts?.list;

  return (
    <StyledOurService className={`${padding ? padding : 'pt-150 pb-150'}`}>

      <ThemeContainer colClassName={'title-wraper'} colProps={{md: 12}}>
        {
          sectionData?.title &&
          <h2 className={'split-up'}>{sectionData?.title}</h2>
        }
        {
          sectionData?.short_desc &&
          <p className={'p1-font split-up'}>{ReactHtmlParser(sectionData?.short_desc)}</p>
        }

      </ThemeContainer>

      {
        window?.innerWidth > 767 ?
          <ThemeRow rowClass={'gap'}>
            {
              servicesList && servicesList.length > 0 &&
              servicesList.map((item, index) => {
                return (
                  <Col md={4} key={index}>
                    {
                      (index + 1) % 2 === 0 ? (
                        <HoverBox title={item?.data?.title} data={item} des={item?.data?.short_desc} nohover/>
                      ) : (
                        <HoverBox title={item?.data?.title} data={item} des={item?.data?.short_desc}/>
                      )
                    }
                  </Col>
                );
              })
            }
          </ThemeRow>
          :
          <ThemeContainer colProps={{md: 12}}>
            <NavigationIcon prev_id={'next-prev'} next_id={'story-next'} color={gray}/>


            <Swiper
              modules={[Autoplay, Pagination, Navigation, EffectFade]}
              slidesPerView={1}
              speed={1500}
              spaceBetween={50}
              centeredSlides={true}

              navigation={{
                prevEl: '#story-prev',
                nextEl: '#story-next',
              }}

              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 15,

                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 15,

                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
              }}
              loop={true}
            >

              {
                servicesList && servicesList?.length > 0 &&
                servicesList.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <HoverBox title={item?.data?.title} data={item} des={item?.data?.short_desc} reverse={'reverse'}/>
                    </SwiperSlide>
                  );

                })
              }

            </Swiper>
          </ThemeContainer>

      }


    </StyledOurService>
  );
};

const StyledOurService = styled.section`
    background-color: ${whiteSoft};
    background-image: url('/images/static/service-bg.svg') !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .gap {
        gap: 30px;
        margin: 0;

        .col-md-4 {
            flex: 0 0 calc(33.3333% - 30px);
            padding-left: 0;
            padding-right: 0;
        }
    }

    p {
        margin-bottom: 40px;
    }

    h2, h3 {
        margin-bottom: 20px;
    }

    @media (max-width: 767px) {

        .navigation_button {
            margin-bottom: 40px;
        }


        .col-md-4 {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 0 0;
        }
    }


`;

export default React.memo(OurService);




