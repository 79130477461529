import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {white} from '@assets/css/globalStyleVars';
import BlogBox from '@components/mir-ui/single-parts/BlogBox';

const Directors = ({padding, data}) => {

  return (
    <StyledDirectors className={`${padding ? padding : 'pt-150 pb-150'}`}>
      <Container>

        <Row>
          {
            data && data?.length > 0 &&
            data?.map((item, index) => {


              let thumb = item?.images?.list.find(f => f?.thumb === 'on');

              return (
                <Col key={index} md={4}>
                  <BlogBox category={item?.category?.category_data?.title} date={item?.data?.date} link={'/news/'+item?.data?.slug} image={thumb?.full_path} title={item?.data?.title}/>
                </Col>
              );
            })
          }


        </Row>

      </Container>

    </StyledDirectors>
  );
};

const StyledDirectors = styled.section`
    background-color: ${white};
    margin-top: -1px;

    h2 {
        margin-bottom: 40px;
        color: ${white};
    }


    @media (max-width: 767px) {
        .col-md-4 {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

export default Directors;




