import React from 'react';
import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import {gray, white} from '@assets/css/globalStyleVars';
import TeamBox from '@components/mir-ui/single-parts/TeamBox';
import ThemeRow from '../../../theme/row';
import ThemeContainer from '../../../theme/container';

const Directors = ({padding, directors, data}) => {

  const List = data?.posts?.list;

  return (
    <StyledDirectors className={`${padding ? padding : 'pt-150 pb-150'}`}>

      <ThemeContainer colClassName={'about-text-top'}>
        <h2 className={'split-up'}>
          {
            directors ? directors :
              'Our Board of Directors'
          }
        </h2>

      </ThemeContainer>
      <ThemeRow>

        {
          List && List?.length > 0 &&
          List.map((item, index) => {

            return (

              <Col md={4} key={index}>
                <TeamBox data={item}  title={item?.data?.title} img={item?.images?.[0]?.full_path} description={item?.data?.description} des={item?.data?.subtitle}/>
              </Col>
            );

          })
        }
      </ThemeRow>

    </StyledDirectors>
  );
};

const StyledDirectors = styled.section`
    background-color: ${gray};
    margin-top: -1px;

    h2 {
        margin-bottom: 40px;
        color: ${white};
    }

    .col-md-4{
        margin-bottom: 30px;
    }


    @media (max-width: 767px) {
        .col-md-4 {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

export default Directors;




