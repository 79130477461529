import React from 'react';
import styled from 'styled-components';
import gsap, {CSSPlugin} from 'gsap';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

gsap.registerPlugin(CSSPlugin);

export const ImgLazyLoad = ({
  radius,
  src,
  position,
  objectFit,
  height,
  width,
  banner,
  alt,
  left,
  margin,
  right,
  top,
  bottom,
  once,
  offset
}) => {

  const DefaultBanner = '/images/static/blurbg.svg';


  return (
    <StyledImgLazyLoad
      className="global-image"
      radius={radius}
      objectFit={objectFit}
      margin={margin}
      position={position}
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      height={height}
      width={width}
    >

      <LazyLoadImage
        effect="blur"
        placeholderSrc={DefaultBanner}
        wrapperProps={{
          // If you need to, you can tweak the effect transition using the wrapper style.
          style: {transitionDelay: '0.7s'},
        }}
        src={src || DefaultBanner}
        alt={alt || ''}
      />


    </StyledImgLazyLoad>
  )
  ;
};


const StyledImgLazyLoad = styled.div`
    position: ${(props) => props.position || 'absolute'};
    height: ${(props) => props.height || '100%'};
    width: ${(props) => props.width || '100%'};
    top: ${(props) => props.top || 0};
    left: ${(props) => props.left || 0};
    bottom: ${(props) => props.bottom || 0};
    right: ${(props) => props.right || 0};
    margin: ${(props) => props.margin || 0};
    border-radius: ${(props) => props.radius || 0};
    overflow: hidden;

    .lazy-load-image-background {
        position: ${(props) => props.position || 'absolute'};
        height: ${(props) => props.height || '100%'};
        width: ${(props) => props.width || '100%'};
        top: ${(props) => props.top || 0};
        left: ${(props) => props.left || 0};
        bottom: ${(props) => props.bottom || 0};
        right: ${(props) => props.right || 0};
        margin: ${(props) => props.margin || 0};
        border-radius: ${(props) => props.radius || 0};


    }

    img {
        border-radius: ${(props) => props.radius || 0};
        width: 100%;
        height: 100%;
        object-fit: ${(props) => props.objectFit || 'cover'};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}`;
