const breakpoints = ['576px', '768px', '992px', '1200px', '1400px'];

export const theme = {
  colors: {
    primary: '#ff7004',
    text: '#181D24',
    hover: '#293A8A',
    gray: '#939498',
    white: '#FFFFFF',
    softGray: '#C7C4B8',
    downloadBg: '#F8F8F8',
    serviceBg: '#C7C4B8',
    whiteSoft: '#FAFAFA',
    serviceBox: '#293A8A',
    serviceBoxBlue: '#04133F',
    testimonial: '#E6E5DF'
  },
  fonts: {
    body: 'Gilroy',
    heading: 'Gilroy',
    arial: 'Arial',
    helvitica: 'Helvetica',
    freesans: 'freesans',
    sanserif: 'sans-serif',
  },
  fontSizes: {
    body: '18px',
    standard: '14px',
    h1: '80px',
    h2: '48px',
    h3: '32px',
    h4: '18px',
    h5: '16px',
    h6: '14px',
  },
  fontWeights: {
    body: 400,
    medium: 500,
    subHeading: 600,
    heading: 700,
    regular: 400,
    light: 300,
  },
  lineHeights: {
    body: '27px',
    h1: '92px',
    h2: '60px',
    h3: '40px',
    h4: '27px',
    h5: '24px',
    h6: '21px',
  },
  radii: {
    sm: '3px',
    md: '5px',
    lg: '8px',
    rounded: '4px',
    circle: '50%',
    pill: '500px',
  },
  shadows: {
    default: '0 0 12px 3px rgba(0, 0, 0, 0.06)',
    sm: '0px -1px 1px 0px rgba(0,0,0, .2)',
    lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
    input: '0 0 0 0.2rem rgb(1 104 250 / 25%)',
  },
  breakpoints: [...breakpoints],
  transition: 'all 0.8s cubic-bezier(0.25, 0.74, 0.22, 0.99) 0s',
  transitionDelay: '0.5s',
  clippath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',

};