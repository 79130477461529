import React from 'react';

const SingleParts = ({ title, value, linkUrl, openInNewTab }) => {
  return (
    <div className="singleItem">
      <p>{title}</p>
      <a href={linkUrl} target={openInNewTab ? '_blank' : '_self'} rel={openInNewTab ? 'noopener noreferrer' : undefined}>
        {value}
      </a>
    </div>
  );
};

export default SingleParts;
