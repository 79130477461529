import React from 'react';
import styled from 'styled-components';
import {ImageParallax} from '../mir-ui/images/ImageParallax';
import {Col} from 'react-bootstrap';
import {white} from '@assets/css/globalStyleVars';
import SubscribeForm from '@components/mir-form/SubscribeForm';
import ThemeRow from '../../theme/row';


const Cta = ({img, data, text}) => {

  const sectionData = data?.section_data;


  return (

    <StyledCTA>
      <div className="image-wrapper">
        <ImageParallax no_para src={data?.images?.list?.[0]?.full_path}/>
        <ThemeRow>
          <Col md={{span: 8, offset: 2}} className={'text-center'}>
            {
              sectionData?.subtitle
                ?
                <h2 className={''}>{sectionData?.subtitle}</h2>
                :
                ''
            }
            <SubscribeForm/>
          </Col>
        </ThemeRow>
        {/*<ThemeRow containerClass={'mir-form-container'}>*/}
        {/*  <Col md={{span: 8, offset: 2}} className={'text-center'}>*/}
        {/*   */}
        {/*  </Col>*/}
        {/*</ThemeRow>*/}

      </div>
    </StyledCTA>

  );
};


const StyledCTA = styled.div`
    position: relative;

    .image-wrapper {
        padding-top: calc(500 / 1366 * 100%);
        position: relative;
        @media (max-width: 992px) {
            padding-top: 50%;
        }

        @media (max-width: 600px) {
            padding-top: 166.3333%;

        }
    }

    .container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 80px;
        z-index: 99;
        @media (max-width: 600px) {
            bottom: 50px;

        }

        &.form-container {
            bottom: -60px;
        }
    }

    h2 {
        color: ${white};
        margin-bottom: 70px;
        font-size:32px !important;
        line-height: 40px !important;
    }



`;

export default React.memo(Cta);














