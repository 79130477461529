import React, {useRef} from 'react';
import styled from 'styled-components';
import {Form} from 'react-bootstrap';
import {hover, text, white} from '@assets/css/globalStyleVars';
import Select, {components} from 'react-select';

const SelectField = ({next_id, clear, onChange, disabled, prev_id, color, placeholder, selection, option}) => {
  const selectLocationInputRef = useRef();

  const types = [
    {value: 'commercial', label: 'Commercial'},
    {value: 'residential', label: 'Residential'},
  ];


  const customStyles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s cubic-bezier(.74,0,.24,.99)',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      color: state.isSelected ? `${white}` : text,
      backgroundColor: state.isSelected ? hover : white,
      margin: 0,
      fontSize: 14,
      cursor: 'pointer',
      paddingLeft: 10,
      paddingRight: 10,
      fontWeight: state.isSelected ? 700 : 400,
      '&:hover': {
        backgroundColor: `${hover}`,
        color: `${white}`,
        cursor: 'pointer'
      },

    }), menu: (provided, state) => ({
      ...provided,
      color: '#191818',
      backgroundColor: state.isSelected ? `${white}` : white,
      margin: 0,
      borderRadius: 10,
      fontSize: 14,
      zIndex: 10
      // width: 200,
    }), menuList: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? `${white}` : white,
      borderRadius: 10,
      fontWeight: '400',
      color: '#FFF',
      fontSize: 14,
    }),

  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon/>
      </components.DropdownIndicator>
    );
  };
  const CaretDownIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="18.828" height="10.414" viewBox="0 0 18.828 10.414">
      <path id="Path_9568" data-name="Path 9568" d="M0,8,8,0l8,8" transform="translate(17.414 9.414) rotate(180)" fill="none" stroke="#939498" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </svg>
    ;
  };


  const handleLocation = (e) => {
    // Remove the following line:
    // setSelectLocation(e);

    // Invoke the onChange callback with the selected value
    if (onChange) {
      onChange(e);
    }
  };


  return (
    <StyledSelectField>
      <div className="form-group">
        <Form.Group controlId="formBasicPhone">
          <Select isDisabled={disabled ? true : false}
            classNamePrefix="filter"
            ref={selectLocationInputRef}
            isSearchable={false} 
            isClearable={clear ? true : false}
            options={selection ? selection : types}
            onChange={e => {
              handleLocation(e);
            }}
            placeholder={`${placeholder ? placeholder : 'Select Location'}`} styles={customStyles}
            components={{DropdownIndicator}}
          />
        </Form.Group>
      </div>
    </StyledSelectField>
  );
};


const StyledSelectField = styled.div`
    position: relative;

    .form-group {
        margin: 0;
    }

    //react select

    .filter--is-disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }

    .filter__control {
        height: 50px;
        font-size: 16px;
        outline: none !important;
        border-color: rgba(147, 148, 152, 0.3) !important;
        box-shadow: none !important;
        border: 1px solid;
        padding: 0 10px 0 20px;
        cursor: pointer;
        background: transparent;
        border-radius: 10px !important;


        .filter__value-container, .filter__indicator {
            padding-left: 0;
            padding-right: 0;
        }

        .filter__placeholder {
            color: ${text};
            outline: none;
            font-size: 12px;
            font-weight: 500;
            line-height: 21px;
        }

        .filter__single-value {
            color: ${text};
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
        }

        .filter__indicator-separator {
            display: none;
        }

        .filter__indicators, .css-1gtu0rj-indicatorContainer {
            margin-right: 10px;
        }
    }

    .filter__control.filter__control--is-focused {
        border-color: ${hover} !important;

        .filter__indicator {
            svg {
                line {
                    stroke: #f1f0ee !important;;
                }
            }
        }
    }

    .css-2613qy-menu {
        border-radius: 0 !important;
        margin-top: 0 !important;
    }

    //animation class


    .info-window {
        max-width: 200px;
    }

    .gm-style-iw {
        border-radius: 0 !important;
    }

    .swiper-pagination-bullet {
        outline: none;
    }

    .css-nmuc1a-menu {
        z-index: 5 !important;
    }




`;

export default React.memo(SelectField);














