import React, {useEffect} from 'react';
import styled from 'styled-components';

import {useLocation, useParams} from 'react-router-dom';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {fetchPostDetail, fetchPosts} from '@api/redux/news';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import Details from './news-details/Details';
import {ApiParam} from '@api/network/apiParams';
import {motion} from 'framer-motion';
import {PageAnimation} from '@lib/PageAnimation';
import NewsCta from '@pages/news/news-cta';

gsap.registerPlugin(CSSPlugin);

const ProjectDetails = () => {

  const dispatch = useDispatch();
  let {slug} = useParams();

  // api call
  useEffect(() => {
    let api_url = apiEndPoints.BlogData;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: slug,

    };

    let api_url_news = apiEndPoints.SECTIONS;
    let param_news = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'news-events',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };
    dispatch(fetchPosts([api_url_news, param_news]));

    dispatch(fetchPostDetail([api_url, param]));
  }, [slug, dispatch]);

  const getData = useSelector(store => store.news);




  const detail = getData?.detail?.data;
  const cta = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'cta-news');

  const location = useLocation();

  useEffect(() => {
    document.querySelector('body').classList.add('news-details');
  }, location?.pathname);

  return (

    <HelmetProvider>
      {/*dynamic title */}
      <Helmet>
        <meta charSet="utf-8"/>

        <title>{`${detail?.data?.title ? detail?.data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          detail?.data?.meta_title &&
          <meta name="title" content={detail?.data?.meta_title ? detail?.data?.meta_title : ''}/>

        }
        {
          detail?.data?.meta_description &&
          <meta name="description" content={detail?.data?.meta_description}/>

        }
        {
          detail?.data?.og_title &&
          <meta property="og:title" content={detail?.data?.og_title}/>

        }
        {
          detail?.data?.og_description &&
          <meta property="og:description" content={detail?.data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'186'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>
        <Details data={detail}/>
        <NewsCta cta={cta}/>
      </StyledComponent>
    </HelmetProvider>

  );
};

const StyledComponent = styled.section`

    padding-top: 100px;
`;

export default ProjectDetails;
