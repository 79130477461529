import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTags = ({ pageData }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageData?.title || 'Mir Securities Ltd - Provides institutional brokerage services'}</title>
      {pageData?.meta_title && <meta name="title" content={pageData.meta_title} />}
      {pageData?.meta_description && <meta name="description" content={pageData.meta_description} />}
      {pageData?.og_title && <meta property="og:title" content={pageData.og_title} />}
      {pageData?.og_description && <meta property="og:description" content={pageData.og_description} />}
    </Helmet>
  );
};

export default MetaTags;
