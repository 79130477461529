import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {gray, hover, white} from '@assets/css/globalStyleVars';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';

const SocialShare = ({direction}) => {


  let [shareUrl, setShareUrl] = useState('');


  useEffect(() => {
    setShareUrl(window.location.href);
  }, [shareUrl]);

  return (

    <StyledSocial direction={direction}>
      <p>Share:</p>
      <ul className={'social'}>
        <li>
          <FacebookShareButton url={shareUrl}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
              viewBox="0 0 25 25">
              <g id="Group_19953" data-name="Group 19953"
                transform="translate(-398 -664)">
                <circle id="Ellipse_447" data-name="Ellipse 447" cx="12.5"
                  cy="12.5" r="12.5"
                  transform="translate(398 664)" fill={'transparent'} stroke={gray}/>
                <circle className={'hover_circle'} data-name="Ellipse 594"
                  cx="12.5" cy="12.5" r="0"
                  transform="translate(398 664)" fill={hover} stroke={hover}/>
                <path id="Path_2115" data-name="Path 2115"
                  d="M1204.9,102.967l.339-2.21h-2.12V99.323a1.1,1.1,0,0,1,1.246-1.194h.964V96.248a11.752,11.752,0,0,0-1.711-.149,2.7,2.7,0,0,0-2.887,2.974v1.684h-1.941v2.21h1.941v5.341h2.388v-5.341Z"
                  transform="translate(-791.62 574.567)" fill={gray}/>
              </g>
            </svg>

          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url={shareUrl}>

            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
              viewBox="0 0 25 25">
              <g id="Group_19952" data-name="Group 19952"
                transform="translate(-438 -664)">
                <circle id="Ellipse_93" data-name="Ellipse 93" cx="12.5"
                  cy="12.5" r="12.5"
                  transform="translate(438 664)" fill={'transparent'} stroke={gray}/>
                <circle className={'hover_circle'} data-name="Ellipse 594"
                  cx="12.5" cy="12.5" r="0"
                  transform="translate(438 664)" fill={hover} stroke={hover}/>

                <g id="layer1" transform="translate(444.667 671.5)">
                  <path id="path1009"
                    d="M281.527,167.31l4.26,5.7-4.287,4.632h.965l3.754-4.055,3.033,4.055h3.284l-4.5-6.017,3.991-4.311h-.965l-3.457,3.735-2.793-3.735Zm1.419.711h1.509l6.661,8.907h-1.509Z"
                    transform="translate(-281.5 -167.31)" fill={gray}/>
                </g>
              </g>
            </svg>


          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton url={shareUrl}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
              viewBox="0 0 25 25">
              <g id="Group_19949" data-name="Group 19949"
                transform="translate(-558 -664)">
                <circle id="Ellipse_594" data-name="Ellipse 594" cx="12.5"
                  cy="12.5" r="12.5"
                  transform="translate(558 664)" fill={'transparent'} stroke={gray}/>
                <circle className={'hover_circle'} data-name="Ellipse 594"
                  cx="12.5" cy="12.5" r="0"
                  transform="translate(558 664)" fill={hover} stroke={hover}/>
                <g id="_x31_0.Linkedin" transform="translate(565.5 671.5)">
                  <path id="Path_2123" data-name="Path 2123"
                    d="M44.965,42.352V38.575c0-1.856-.4-3.275-2.565-3.275a2.239,2.239,0,0,0-2.024,1.109H40.35v-.941H38.3v6.884h2.14V38.936c0-.9.168-1.766,1.276-1.766s1.109,1.018,1.109,1.818v3.352h2.14Z"
                    transform="translate(-34.652 -32.038)"
                    fill={gray}/>
                  <path id="Path_2124" data-name="Path 2124"
                    d="M11.3,36.6h2.14v6.884H11.3Z"
                    transform="translate(-11.132 -33.171)"
                    fill={gray}/>
                  <path id="Path_2125" data-name="Path 2125"
                    d="M11.238,10a1.244,1.244,0,1,0,1.238,1.238A1.238,1.238,0,0,0,11.238,10Z"
                    transform="translate(-10 -10)" fill={gray}/>
                </g>
              </g>
            </svg>
          </LinkedinShareButton>
        </li>
        <li>
          <EmailShareButton url={shareUrl}>

            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
              viewBox="0 0 25 25">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_6283" data-name="Rectangle 6283"
                    width="13" height="13"
                    transform="translate(14289 121.282)" fill={'transparent'} stroke={gray}/>
                </clipPath>
              </defs>
              <g id="Group_23859" data-name="Group 23859"
                transform="translate(5928 -353)">
                <circle id="Ellipse_612" data-name="Ellipse 612" cx="12.5"
                  cy="12.5" r="12.5" transform="translate(-5928 353)"
                  fill={'transparent'} stroke={gray}/>
                <circle className={'hover_circle'} id="Ellipse_613" data-name="Ellipse 612" cx="12.5"
                  cy="12.5" r="0" transform="translate(-5928 353)"
                  fill={hover} stroke={hover}/>
                <g id="Mask_Group_19945" data-name="Mask Group 19945"
                  transform="translate(-20211 237.718)"
                  clipPath="url(#clip-path)">
                  <g id="Icon_feather-link-2" data-name="Icon feather-link-2"
                    transform="translate(14289.585 129.66) rotate(-45)">
                    <path id="Path_173" data-name="Path 173"
                      d="M7.267,0H8.824a2.734,2.734,0,0,1,2.6,2.855,2.734,2.734,0,0,1-2.6,2.855H7.267m-3.114,0H2.6A2.734,2.734,0,0,1,0,2.855,2.734,2.734,0,0,1,2.6,0H4.153"
                      fill="none" stroke={gray} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.2"/>
                    <path id="Path_174" data-name="Path 174" d="M0,0H4.841"
                      transform="translate(3.289 2.855)" fill="none"
                      stroke={gray} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.2"/>
                  </g>
                </g>
              </g>
            </svg>


          </EmailShareButton>

        </li>
      </ul>
    </StyledSocial>

  );
};

const StyledSocial = styled.div`
    margin-bottom: 0;

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: ${gray};
        margin-bottom: 20px;
    }

    .social {
        display: flex;
        flex-direction: ${props => props.direction ? 'column' : 'row'};
        gap: 10px;

        li {
            list-style: none !important;

            &:after {
                display: none !important;
            }

            &:before {
                display: none !important;
            }

            a {
                &:after {
                    display: none !important;
                }

                &:before {
                    display: none !important;
                }
            }
        }

        svg {
            overflow: hidden;
            border-radius: 50%;
            height: 30px;
            width: 30px;

            .hover_circle, path, #Rectangle_6283 {
                transition: 0.5s all cubic-bezier(.25, .74, .22, .99);
            }
        }

        li {
            margin-left: 0px;
            margin-bottom: 0 !important;

            &:first-child {
                margin-left: 0;
            }

            button {
                &:hover {
                    svg {
                        .hover_circle {
                            r: 12.5px;
                            opacity: 1;
                        }

                        path {
                            fill: ${white};
                        }

                        #Path_173 {
                            stroke: ${white}
                        }

                    }
                }
            }
        }
    }

    @media (max-width: 992px) and (min-width: 767px) {
        .social {
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 10px;

            li {
                margin-left: 0;
            }
        }
    }
    @media (max-width: 767px) {
        margin-top: 30px;
        .social {
            flex-direction: row;
        }
    }
`;

export default React.memo(SocialShare);














