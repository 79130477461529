import React from 'react';
import styled from 'styled-components';
import {CSSPlugin, gsap, TimelineLite} from 'gsap';

gsap.registerPlugin(CSSPlugin);

const Hamburger = ({close}) => {
  let tl = new TimelineLite();

  const handleHover = () => {
    tl.to('#Rectangle_5469', {

      transformOrigin: 'center',
      duration: 0.2,
      rx: '25',
      ease: 'power2.inOut'
    });

  };

  const handleLeave = () => {
    tl.to('#Rectangle_5469', {


      duration: 0.2,
      rx: '5',
      ease: 'power2.inOut'
    });
  };


  return (
    <StyledHamburger className={''} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
      <svg id="Menu" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <rect id="Rectangle_5469" data-name="Rectangle 5469" width="50" height="50" rx="5" fill="#fff"/>

        <g id="Group_18486" data-name="Group 18486" transform="translate(-1218 -30)" opacity={close ? 0 : 1}>
          <line id="Line_3996" data-name="Line 3996" x2="20" transform="translate(1233 55)" fill="none" stroke="#293a8a" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_3997" data-name="Line 3997" x2="20" transform="translate(1233 48)" fill="none" stroke="#293a8a" strokeLinecap="round" strokeWidth="2"/>
          <line id="Line_3998" data-name="Line 3998" x2="20" transform="translate(1233 62)" fill="none" stroke="#293a8a" strokeLinecap="round" strokeWidth="2"/>
        </g>
        <g id="Group_18683" data-name="Group 18683" transform="translate(-1225.757 -105.757)" opacity={close ? 1 : 0}>
          <path id="Path_9629" data-name="Path 9629" d="M0,19.8V0" transform="translate(1243.757 137.757) rotate(-135)" fill="none" stroke="#293a8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Path_9630" data-name="Path 9630" d="M0,19.8V0" transform="translate(1243.757 123.757) rotate(-45)" fill="none" stroke="#293a8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>

      </svg>


    </StyledHamburger>
  );
};

const StyledHamburger = styled.div`

    position: relative;
    cursor: pointer;
    #Rectangle_5469, g {
        transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;
    }

    &:hover {
        #Rectangle_5469 {
            //rx: 25px;
            //transform: rotate(-90deg);
            //transform-origin: center;
        }

    }
`;

export default React.memo(Hamburger);
