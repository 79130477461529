import React, {useEffect} from 'react';
import styled from 'styled-components';
import gsap, {CSSPlugin} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import TableText from './research-tab/TableText';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DirectorsList from '../../research/research-director/DirectorsList';
import {ApiParam} from '../../api/network/apiParams';
import {fetchExpert, fetchNewsFlash, fetchPosts, fetchResearch} from '../../api/redux/research';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../lib/PageAnimation';
import ResearchBanner from '@pages/research/research-banner';
import ResearchCta from '@pages/research/research-cta';
import TableTextNews from '@pages/research/research-tab/TableTextNews';

gsap.registerPlugin(CSSPlugin, ScrollTrigger, SplitText);


const Insights = () => {

  const dispath = useDispatch();


  // api call
  useEffect(() => {

    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'research-center',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    let api_url_search = apiEndPoints.REARCHLISTCAT+'?type=1';
    let api_url_new = apiEndPoints.REARCHLISTCAT+'?type=2';


    let api_url_expert = apiEndPoints.SECTIONS;
    let paramExter = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'about-us',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchResearch([api_url_search]));
    dispath(fetchNewsFlash([api_url_new]));
    dispath(fetchPosts([api_url, param]));
    dispath(fetchExpert([api_url_expert, paramExter]));


  }, [dispath]);

  let getPost = useSelector(store => store.research);


  let page_data = getPost?.posts?.data?.page_data;
  let report = getPost?.researchFilter;
  let newsflash = getPost?.news;
  let expertlist =  getPost?.team?.data?.sections?.find(f => f?.section_data?.slug === 'our-experts');




  const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'research-center-banner');
  const cta = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'cta-research');


  const types = [
    {value: '1', label: 'Economy'},
    {value: '2', label: 'Money Market Update'},
    {value: '3', label: 'Macro-Regular update'},
    {value: '4', label: 'Daily Market Update'},
    {value: '5', label: 'Weekly Market Update'},
    {value: '6', label: 'FMCG'},
    {value: '7', label: 'Pharma'},
    {value: '8', label: 'Financials'},
    {value: '9', label: 'Telecom'},
    {value: '10', label: 'Market and Others'},
    {value: '11', label: 'Initiation Coverage'},
    {value: '12', label: 'Valuation Update'},
    {value: '13', label: 'Flash Notes and Earnings Update'},
  ];

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'187'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledSection>
        <ResearchBanner noPara title={banner?.section_data?.subtitle}
          imageSrc={banner?.images?.list?.[0]?.full_path}/>



        <section className="tab-wrapper">
          <Tabs
            defaultActiveKey="report"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="report" title="Report">
              <TableText types={types} data={report}/>
            </Tab>
            <Tab eventKey="news" title="News Flash">
              <TableTextNews types={types} data={newsflash}/>
            </Tab>
            <Tab eventKey="expert" title="Research Experts">
              <DirectorsList data={expertlist}/>
            </Tab>
          </Tabs>
        </section>
        <ResearchCta cta={cta}/>
      </StyledSection>
    </HelmetProvider>

  );
};

const StyledSection = styled.section`
    .single-item {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .logo-brand {
        opacity: 1 !important;
        visibility: visible !important;
    }

    .tab-wrapper {
        .nav-tabs {
            display: flex;
            justify-content: space-between;

            .nav-item {
                flex: 0 0 33.333333%;
                height: 80px;
                background: #939498;
                border-right: 1px solid white;

                button {
                    border-radius: 0;
                    background: transparent;
                    border: none;
                    width: 100%;
                    height: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 18px;
                    color: #FFFFFF;
                    letter-spacing: 0.2px;

                    &.active {
                        background: #293A8A;
                    }
                    
                }

                &:last-child {
                    border: none !important;
                }
            }
        }
    }

`;

export default Insights;
