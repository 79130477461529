import styled from 'styled-components';
import {gray, hover, serviceBg, text, white} from '@assets/css/globalStyleVars';

export const StyledMobileMenu = styled.section`
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    transition: transform 0.2s cubic-bezier(.74, 0, .24, .99);
    transition-delay: .3s;
    //background: rgba(0, 0, 0, 0.3);
    height: 100px;


    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: ${gray};
        width: calc(100% - 30px);
        margin: 0 auto;
        content: '';
    }

    .mute-toggle-mobile {
        position: absolute;
        left: 15px;
        top: 12px;
        //bottom: 0;
        margin: auto;
        cursor: pointer;
        height: fit-content;
        display: none;
        //align-items: center;

        path {
            fill: ${hover}
        }

        line {
            display: none;
            stroke: ${hover};
        }

        svg {
            cursor: pointer;
        }

        &.sound {
            line {
                display: block;
            }
        }
    }

    &.menu-is-open {
        transform: none !important;

        #close-click {
            opacity: 1;
            display: block;
        }

        #open-click {
            opacity: 0;
            display: none;

        }
    }

    @media (min-width: 989px) {
        display: none;
    }

    #open-click {
        transition: all .4s cubic-bezier(.74, 0, .24, .99);
        display: block;

    }

    #close-click {
        opacity: 0;
        display: none;

    }

    .main-menu-mobile__bar {

        display: flex;
        justify-content: space-between;
        //align-items: center;
            //border-bottom: 1px solid ${hover};
        padding: 0 15px;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        //background-color: rgba(34, 34, 34, 0.30);
        z-index: 9999;
        transition: all .4s cubic-bezier(.74, 0, .24, .99);
        height: 100px;

        &__logo {
            position: relative;
            margin: auto;
            width: fit-content;

            img {
                height: 50px;
            }

        }

        .search-icon-mobile {
            margin-right: 25px;
        }

        &__hamburger {

            width: 100%;
            padding-top: 0;
            height: 100%;
            align-items: center;


            svg {
                opacity: 1;
                transition: 1s all cubic-bezier(.74, 0, .24, .99);
            }
        }


    }


    .main-menu-mobile__items {
        padding: 0 0 70px;
        position: relative;
        transform-origin: top left;
        transition: all .3s cubic-bezier(.54, .32, .05, .96);
        height: calc(100vh - 100px);
        overflow-y: auto;
        overflow-x: hidden;
        width: 100vw;
        transform: translateX(100%);
        display: none;
        z-index: 999;
        background: ${white};
        top: 100px;


        &__body {
            height: auto;
            width: 100%;
            padding: 50px 20px;
            position: relative;
            right: 0;
            margin: 0 0 0 auto;
            background: ${serviceBg};
            display: flex;
            flex-direction: column;

            &:last-child {
                background: ${white};
            }

            .menu-list {
                padding: 60px 0;
            }

            ul {
                width: 100%;

                &.menu-list {
                    //height: calc(100vh - 300px);
                    //overflow-y: auto;
                    //width: 100%;
                    height: 100%;
                    width: 100%;

                    a {
                        width: 100%;
                        display: flex;
                    }
                }

                .main-item {
                    ul {
                        li {
                            a {
                                pointer-events: auto !important;
                            }

                            &:nth-of-type(1) {
                                a {
                                    position: relative;
                                    display: flex;


                                    &:after {
                                        content: '';
                                        position: absolute;
                                        height: 24px;
                                        width: 24px;
                                        background-color: ${hover};
                                        right: -30px;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                        border-radius: 100%;
                                        //background-image: url();
                                        //background-repeat: no-repeat;
                                        //background-position: center;
                                    }
                                }
                            }


                        }
                    }
                }


                li {
                    text-transform: capitalize;
                    display: block;
                    width: 100%;


                    a {
                        color: ${text};
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 500;
                    }

                    &:not(:nth-last-of-type(1)) {
                        margin-bottom: 25px;

                    }

                    &.active {
                        a {
                            border-bottom: 1px solid ${hover};
                            width: fit-content;
                            pointer-events: none;
                        }
                    }

                    //sub menus

                    ul {
                        left: 0;
                        list-style: none;
                        margin: 0;
                        position: absolute;
                        top: 113px;
                        padding: 0 15px;
                        //height: 100vh;
                        opacity: 0;
                        transform: translate3d(100vw, 0, 0);
                        transition: all .3s cubic-bezier(.54, .32, .05, .96);
                        //padding-bottom: 40px;
                        width: 100vw;
                        padding-bottom: 25px;
                        height: auto !important;
                        overflow: hidden !important;

                        li {
                            width: auto !important;
                            background-color: transparent !important;
                            height: auto !important;
                            display: block !important;
                            text-align: left !important;

                            &:nth-of-type(1) {
                                margin-bottom: 80px !important;
                            }

                            &:not(:nth-of-type(1)) {
                                a {

                                    text-transform: capitalize;
                                    width: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    font-size: 30px;
                                    line-height: 30px;

                                    span {
                                        min-width: 100%;
                                        font-size: 12px;
                                        line-height: 23px;
                                        display: block;
                                        margin-bottom: 13px;
                                        color: #b2a89f;
                                    }
                                }
                            }

                        }
                    }

                    &.active {
                        ul {
                            opacity: 1;
                            z-index: 2;
                        }

                    }

                }
            }


            &.active {
                transform: translate3d(-100vw, 0, 0) !important;

                .main-menu-mobile__items__head {
                    transform: translate3d(100vw, 0, 0) !important;

                    .sub-menu-back {
                        display: block;
                    }

                    a {
                        display: none;
                    }
                }
            }

            .bottom {
                .singleItem {
                    margin-bottom: 25px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        margin-bottom: 10px;
                        color: ${gray};
                    }

                    a {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 25px;
                        color: ${text};
                    }

                }
            }

        }
    }

    .sticky {
        display: none;
    }

    #Rectangle_5469 {
        rx: 5px !important;
    }



`;
