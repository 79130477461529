import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import reactHtmlParser from 'react-html-parser';
import {softGray} from '@assets/css/globalStyleVars';
import ThemeRow from '../../../theme/row';

const MissionVision = ({data}) => {
  const [BgHeight, setBgHeight] = useState(false);
  const ref = useRef(null);
  const refMinus = useRef(null);

  useEffect(() => {
    let height = refMinus.current.offsetHeight / 2;
    height = ref.current.offsetHeight - height;

    if (window.innerWidth < 767) {
      setBgHeight(height - 80);

    } else {
      setBgHeight(height - 150);

    }

  }, [data]);
  // const sectionData = data?.section_data;
  const List = data?.posts?.list;

  return (
    <StyledMissionVision ref={ref} className={'pt-150 pb-150'} BgHeight={BgHeight}>
      <ThemeRow rowRef={refMinus}>

        {
          List && List?.length > 0 &&
          List.map((item, index) => {
            return (

              <Col className="mission_vision_single" key={index} md={6}>
                <div className="content_wrapper">
                  <h2 className={'split-up'}>{item?.data?.title}</h2>
                  <p className={'split-up'}>{reactHtmlParser(item?.data?.description)}</p>

                </div>
              </Col>
            );

          })
        }
      </ThemeRow>
    </StyledMissionVision>
  );
};

const StyledMissionVision = styled.section`
    position: relative;


    .content_wrapper {
        padding: 70px;
        height: 100%;
        background: ${softGray};
        border-radius: 10px;

        h2 {
            margin-bottom: 40px;
        }
    }

    .container {
        position: relative;
        z-index: 1;
    }


    @media (max-width: 992px) {
        .mission_vision_single {
            max-width: 100%;
            flex: 0 0 100%;

            &:last-child {
                margin-top: 30px;
            }
        }
    }

    @media (max-width: 767px) {
        .content_wrapper {
            padding: 30px 15px;

            h2 {
                margin-bottom: 20px;
            }
        }
    }

`;

export default MissionVision;
