import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {ImgLazyLoad} from '../images/ImgLazyLoad';

import {hover, text} from '@assets/css/globalStyleVars';
import moment from 'moment';


const MyComponent = ({image, date, title, link, category}) => {


  return (
    <StyledComponent className="">
      <Link to={link ? link : '/news/' + 'details'}>
        <div className="presslist__single">
          <div className="presslist__single__img">
            <ImgLazyLoad src={image ? image : ''}/>
          </div>
          <div className="presslist__single__content">
            {
              category &&
              <p className={'category'}>{category}</p>

            }

            {
              date ?
                <p>{moment(date ? date : '27, Sep 2021').format('DD MMMM YYYY')}</p>
                :
                ''

            }


            <div className="presslist__single__content__text">
              {
                title ?
                  <p>{'Mir Securities Launches Groundbreaking Sustainable Investment Fund'} </p>

                  :

                  ''
              }
            </div>
            <div className="presslist__single__content__link">
              <Link className={'link'} to={link ? link : '/blog/details'}>Learn More</Link>

            </div>
          </div>
        </div>
      </Link>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
    .presslist {

        &__single {
            margin-bottom: 30px;
            transition: 1s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            border: 1px solid transparent;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            &:hover {
                border: 1px solid gray;
                transition: 1s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
                box-shadow: 0 5px 30px rgba(147, 148, 152, 0.3);


            }

            .link {
                text-decoration: none;
            }

            &__img {
                position: relative;
                padding-top: calc(250 / 370 * 100%);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

            }

            &__content {
                padding: 30px;
                position: relative;
                background-color: #F8F8F8;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                @media (max-width: 992px) {
                    font-size: 18px;
                    line-height: 27px;
                };

                p {
                    color: ${hover};
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 21px;
                    margin-bottom: 20px;
                }
                
                .category{
                    margin-bottom: 0;
                }

                &__text {
                    min-height: 135px;

                    p {
                        color: ${text};
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 27px;
                    }
                }

                &__link {
                    position: absolute;
                    bottom: 31px;
                }

            }

            @media (max-width: 767px) {
                margin-bottom: 20px !important;
                :last-child {
                    margin-bottom: 0px;
                }
            }

        }
    }
`;

export default MyComponent;
