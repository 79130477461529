import {configureStore} from '@reduxjs/toolkit';
import homeReducer from '../redux/home';
import aboutReducer from '../redux/about';
import servicesReducer from '../redux/services';
import contactReducer from '../redux/contact';
import searchReducer from '../redux/search';
import newsletterReducer from '../redux/newsletter';
import downloadReducer from '../redux/download';
import careerReducer from '../redux/career';
import newsReducer from '../redux/news';
import researchReducer from '../redux/research';
import footerReducer from '../redux/footer';

const store = configureStore({
  reducer: {
    home: homeReducer,
    about: aboutReducer,
    contact: contactReducer,
    search: searchReducer,
    services: servicesReducer,
    newsletter: newsletterReducer,
    download: downloadReducer,
    career: careerReducer,
    news: newsReducer,
    research: researchReducer,
    footer:footerReducer

  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;

