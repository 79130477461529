import React, {useEffect, useState} from 'react';
import {CSSPlugin, gsap, TimelineLite} from 'gsap';
import 'react-modal-video/css/modal-video.min.css';
import {Link, useLocation} from 'react-router-dom';
import Logo from '@components/mir-ui/svg/Logo';
import HamubrerLine from '@components/mir-ui/svg/HamubrerLine';
import Search from '@components/mir-ui/svg/Search';
import {StyledMobileMenu} from '@components/layouts/menu/mobile/mobilemenu.style';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {fetchPosts} from '@api/redux/footer';
import {useDispatch, useSelector} from 'react-redux';
import reactHtmlParser from 'react-html-parser';

gsap.registerPlugin(CSSPlugin);

const MobileMenu = ({offset}) => {
  const router = useLocation();
  let tl = new TimelineLite();
  let tl2 = new TimelineLite();


  //desktop menu contact dropdown
  useEffect(() => {
    document.querySelector('.click-dropdown')?.addEventListener('click', () => {
      if (!document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
        document.querySelector('.main-menu__contact').classList.add('open-dropdown');
        tl.to('.dropdown-menu-mir-parts', {
          duration: .2,
          display: 'block',
        }).to('.dropdown-menu-mir-parts', {
          y: '20px',
          duration: .4,
          height: 'calc(100% - 20px)',
          alpha: 1,
          paddingTop: '33px',
          paddingBottom: '15px',
          ease: 'circ.out'
        });
      } else {
        document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
        tl.to('.dropdown-menu-mir-parts', {
          duration: .3,
          height: '0%',
          alpha: 0,
          paddingTop: '0',
          y: '0px',
          ease: 'circ.out'
        }).to('.dropdown-menu-mir-parts', {
          duration: .2,
          display: 'none',
        });
      }
    });
    window.addEventListener('click', (e) => {
      if (document.querySelector('.main-menu__contact') && document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
        if (!e.target.matches('.click-dropdown, .click-dropdown p, .click-dropdown svg, .click-dropdown line')) {
          tl.to('.dropdown-menu-mir-parts', {
            duration: .3,
            height: '0%',
            alpha: 0,
            paddingTop: '0',
            y: '0px',
            ease: 'circ.out'
          }).to('.dropdown-menu-mir-parts', {
            duration: .2,
            display: 'none',
          });

          document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
        }
      }
    });


  }, []);


  //----- mobile menu action
  useEffect(() => {
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let getItems = document.querySelectorAll('.main-item');
    let getBacks = document.querySelectorAll('.sub-menu-back');
    let getHamburgerClick = document.querySelector('#open-click');
    let getHamburgerCloseClick = document.querySelector('#close-click');

    // menu open toggle
    getHamburgerClick.addEventListener('click', () => {
      document.body.classList.add('menu-on');
      document.querySelector('.main-menu-mobile').classList.add('menu-is-open');
      getMobileMenuBar.classList.add('menu-open');
      document.body.classList.add('stop-scroll');
      tl2.to(getItemsParent, {
        duration: .2,
        display: 'block',
      }).to(getItemsParent, {
        duration: .2,
        x: 0
      }, '-=.2');
    });

    getHamburgerCloseClick?.addEventListener('click', () => {
      getMobileMenuBar.classList.remove('menu-open');
      document.body.classList.remove('menu-on');
      document.querySelector('.main-menu-mobile').classList.remove('menu-is-open');
      document.body.classList.remove('stop-scroll');
      if (document.querySelector('.main-item.active')) {
        getItemsParent.classList.remove('active');
        document.querySelector('.main-item.active').classList.remove('active');
      }

      tl2.to(getItemsParent, {
        duration: .2,
        x: '100%'
      }).to(getItemsParent, {
        duration: .2,
        display: 'none'
      });
    });


    // sub menu toggle
    getItems.forEach(i => {
      i.addEventListener('click', () => {
        getItemsParent.classList.add('active');
        i.classList.add('active');
      });
    });

    getBacks.forEach(i => {
      i.addEventListener('click', (e) => {

        getItemsParent.classList.remove('active');
        i.parentNode.parentElement.classList.remove('active');
        e.stopPropagation();
      });
    });

  }, []);


  // menu fixed on scroll
  useEffect(() => {
    if (document.body.classList.contains('scroll-down')) {
      document.body.classList.remove('scroll-down');
    }
  });


  const [scrollUp, setScrollUp] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  useEffect(() => {
    const body = document.body;
    const scrollDown = 'scroll-down';
    let lastScroll = 0;
    let howMuchScroll = 20;
    window.addEventListener('scroll', () => {
      let currentScroll = window.pageYOffset;

      if (currentScroll <= howMuchScroll) {
        // body.classList.remove(scrollUp);
        setScrollUp(false);
        setScrollDown(false);

        return;
      }
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        // body.classList.remove(scrollUp);
        // body.classList.add(scrollDown);
        setScrollDown(true);
        setScrollUp(false);

      } else if (
        currentScroll < lastScroll
        // body.classList.contains(scrollDown)
      ) {
        // up
        // body.classList.remove(scrollDown);
        // body.classList.add(scrollUp);
        setScrollUp(true);
        setScrollDown(false);

      }
      lastScroll = currentScroll;
    });
  }, []);

  const dispath = useDispatch();
  const location = useLocation();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SETTINGS;
    dispath(fetchPosts([api_url]));

  }, [dispath, location?.pathname]);
  let getPost = useSelector(state => state.footer);

  const data = getPost?.posts?.data;



  return (
    <>

      <StyledMobileMenu
        className={`main-menu-mobile ${scrollDown ? 'scroll-down-mb' : ''} ${scrollUp ? 'scroll-up-mb' : ''}`}>


        <div className="main-menu-mobile__bar">
          {/*sound toggle */}
          <div className="mute-toggle-mobile">

            <svg width="16.4" height="16">
              <g id="Group_16461" data-name="Group 16461" transform="translate(-150 1177)">
                <g id="Group_16460" data-name="Group 16460" transform="translate(-11 -1)">
                  <g id="Group_16459" data-name="Group 16459"
                    transform="translate(-63 -1208)">
                    <path id="volume"
                      d="M9.229,0A6.557,6.557,0,0,0,4.591,1.921L2.713,3.8.345,4.983A.625.625,0,0,0,0,5.542v4.917a.625.625,0,0,0,.346.559L2.713,12.2l1.878,1.877A6.557,6.557,0,0,0,9.229,16a.625.625,0,0,0,.625-.625V.625A.625.625,0,0,0,9.229,0ZM8.6,14.714a5.269,5.269,0,0,1-3.13-1.519C3.4,11.132,3.524,11.209,3.363,11.129L1.25,10.072V5.928L3.363,4.872A19.679,19.679,0,0,0,5.474,2.805,5.27,5.27,0,0,1,8.6,1.286Z"
                      transform="translate(224 32)" fill="#34342e"/>
                    <line id="Line_13" data-name="Line 13" y1="5"
                      transform="translate(236.9 37.5)" fill="none" stroke="#34342e"
                      strokeLinecap="round" strokeWidth="1"/>
                    <line id="Line_14" data-name="Line 14" y1="10"
                      transform="translate(239.9 34.5)" fill="none" stroke="#34342e"
                      strokeLinecap="round" strokeWidth="1"/>
                  </g>
                </g>
              </g>
            </svg>

          </div>


          <div className="main-menu-mobile__bar__logo">
            <div className="normal">
              <Link to={'/'}>
                <Logo/>
              </Link>
            </div>
            <div className="sticky">
              <Link to={'/'}>
                <Logo reverse/>
              </Link>
            </div>
          </div>

          <div className="main-menu-mobile__bar__hamburger d-flex justify-content-end">
            <div className="search-icon-mobile">
              <Link to={'/search'}>
                <Search/>

              </Link>
            </div>
            <div className="" id={'open-click'}>
              <HamubrerLine close={false}/>

            </div>
            <div id={'close-click'}>
              <HamubrerLine close={true}/>

            </div>
          </div>
        </div>

        <div className="main-menu-mobile__items">

          <div className="main-menu-mobile__items__body col-md-12">
            <ul className={'menu-menu-mir-parts'}>
              <li className={`${router.pathname === '/about' ? 'active' : ''}`}><Link to="/about">About Us</Link>
              </li>
              <li className={`${router.pathname === '/services' ? 'active' : ''}`}><Link
                to="/services">Service</Link></li>
              <li className={`${router.pathname === '/research' ? 'active' : ''}`}><Link
                to="/research">Research Center</Link></li>
              <li className={`${router.pathname === '/career' ? 'active' : ''}`}><Link
                to="/career">Career</Link></li>
              <li className={`${router.pathname === '/news' ? 'active' : ''}`}><Link
                to="/news">News & Events</Link></li>
              <li className={`${router.pathname === '/downloads' ? 'active' : ''}`}>
                <Link to="/downloads">Download</Link></li>
              <li className={`${router.pathname === '/contacts' ? 'active' : ''}`}>
                <Link to="/contacts">Contacts</Link></li>
            </ul>
          </div>
          <div className="main-menu-mobile__items__body col-md-12">

            <div className="bottom">
              <div className="address">
                <div className="singleItem">
                  <p>Visit</p>
                  <a href={data?.office_location_map} target={'_blank'} rel="noreferrer">
                    {reactHtmlParser(data?.office_location)}
                  </a>
                </div>
                <div className="singleItem">
                  <p>Call</p>
                  <a href={`tel:${data?.office_phone}`}>
                    {data?.office_phone} </a>
                </div>
                <div className="singleItem">
                  <p>Call</p>
                  <a href={`mailto:${data?.info_email}`}>
                    {data?.info_email} </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </StyledMobileMenu>
    </>

  )
  ;
};


export default MobileMenu;
