import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
// import {toast} from 'react-toastify';
// import {useForm} from 'react-hook-mir-form';
// import {useDispatch} from 'react-redux';
import {gray, softGray, text, white} from '@assets/css/globalStyleVars';
import {ImgLazyLoad} from '@components/mir-ui/images/ImgLazyLoad';
import reactHtmlParser from 'react-html-parser';

const AddressContact = ({padding, data, id}) => {


  let [offset, setOffset] = useState(90);

  useEffect(() => {
    if (window.innerWidth > 767) {
      setOffset(document.querySelector('.container').offsetLeft + 15);
    }
  }, [null]);


  // const dispatch = useDispatch();
  // const responseData = useSelector(state => state.contact);
  // const responseData = '';

  // const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

  //--- mir-form submit
  // const success = (msg) => toast.success(msg, {
  //   position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,
  //
  // });

  // const error = (msg) => toast.error(msg, {
  //   position: 'top-right', autoClose: 4000, closeOnClick: true, progress: undefined,
  //
  // });

  // const handleBlur = () => {
  // };

  // const handleChange = () => {
  // };
  // const [formSubmitted, setFormSubmitted] = useState(false);

  // const onSubmit = async (e) => {
  //   // let api_services = apiEndPoints.SUBMITFORM;
  //   var formData = new FormData();
  //   formData.append('name', e?.name);
  //   formData.append('email', e?.email);
  //   formData.append('phone', e?.phone);
  //   formData.append('message', e?.message);
  //   formData.append('form_id', 'contact-mir-form');
  //
  //   if (e.email !== '' && e.name !== '' && e.phone !== '') {
  //     // dispatch(postForm([api_services, formData]));
  //     reset();
  //     try {
  //       // const response = await dispatch(postForm([api_services, formData]));
  //       //
  //       // if (response && response.payload.result === 'success') {
  //       //   success(response.payload.message);
  //       // } else if (response && response.error && response.error) {
  //       //   error(response.payload.message);
  //       // }
  //     } catch (err) {
  //       console.error('API Error:', err); // Check for any errors in the console
  //       error('An error occurred while submitting the mir-form.');
  //     }
  //   }
  // };
  // let count = 0;
  // const onError = (errors) => {
  //   Object.values(errors).forEach((error) => {
  //     count++;
  //   });
  //   if (count > 0) {
  //     toast.error('please fill out the correct inputs');
  //   }
  //   count = 0;
  // };


  // useEffect(() => {
  //   if (responseData && responseData?.error !== '') {
  //     error(responseData?.error?.message);
  //     reset();
  //   }
  //   if (responseData && responseData?.success !== '') {
  //     success(responseData?.success);
  //     reset();
  //   }
  // }, [responseData, formSubmitted]);

  const sectionData = data?.section_data;
  const phoneData = data?.section_data?.subtitle;
  const emailData = data?.section_data?.short_desc;
  const sectionDataImage = data?.images;

  return (<StyledListWithForm id={`${id ? id : 'ListWithForm'}`}
    className={`list_with_form ${padding ? padding : ''} `}>
    <Container fluid>
      <Row className={'align-items-center'}>
        <Col md={5} className={'left-col '} style={{paddingLeft: offset ? offset + 'px' : '90px'}}>
          <h2>Address</h2>
          <div className="address-wrapper">
            <div className="single-item">
              {
                sectionData?.description &&
                <div>
                  <p>Visit</p>
                  <a href={sectionDataImage?.list?.[0]?.short_desc} target={'_blank'} rel="noreferrer">
                    {reactHtmlParser(sectionData?.description)}
                  </a>
                </div>
              }

            </div>
            <div className="single-item">
              <p>Call</p>
              <a href={`tel:${phoneData}`}>{phoneData}</a>

            </div>
            <div className="single-item">
              <p>Mail</p>
              <a href={`mailto:${emailData}`}>{emailData}</a>

            </div>


          </div>
        </Col>
        <Col className={'pr-0'} md={{span: 7}}>
          <a href={sectionDataImage?.list?.[0]?.short_desc} target={'_blank'} rel="noreferrer">
            <div className="image-wrapper">
              <ImgLazyLoad src={sectionDataImage?.list?.[0]?.full_path} alt={'map'}/>
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  </StyledListWithForm>);
};

const StyledListWithForm = styled.div`
    background: ${softGray};

    .image-wrapper {
        padding-top: calc(600 / 767 * 100%);
        positive: relative;
    }

    h2 {
        margin-bottom: 60px;
    }

    .address-wrapper {

        .single-item {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 10px;
                color: rgba(24, 29, 36, 0.5);
            }

            a {
                font-size: 14px;
                font-weight: 500;
                line-height: 25px;
                color: ${text};
            }
        }
    }

    .form_wrapper {
        background: ${gray};
        padding: 60px;
        height: 100%;
        position: relative;
        // overview_bg

        .left-col {
            div {
                height: 100%;
            }
        }

        @media (max-width: 992px) and (min-width: 768px) {
            padding: 30px;
        }

        form {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
        }

        .textarea {
            min-height: 120px;
        }

        .title {
            font-size: 24px;
            font-weight: 4000;
            line-height: 28px;
            color: #F9F9F9;
            margin: 0 0 40px;
        }

        .form-group {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                display: block;
            }

            .form-control {
                border-radius: 10px !important;
                box-shadow: none;
                outline: 0;
                background: transparent;
                border-color: rgba(255, 255, 255, 0.5);
                padding: 15px 20px;
                height: 50px;
                line-height: 21px;
                color: ${white};
                font-size: 12px;
                font-weight: 500;

                svg {
                    display: block;
                }

                &::placeholder {
                    color: ${white};
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            .dc-btn {
                a {
                    min-width: 250px;
                    display: flex;
                    align-items: center;
                    color: ${text};
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    padding: 20px 30px;
                    line-height: 21px;
                    text-transform: capitalize;
                    height: 50px;
                    background-color: ${softGray};
                    position: relative;
                    border-radius: 5px !important;
                    z-index: 0;
                    transition: all .5s cubic-bezier(.54, .32, .05, .96);
                    border: 1px solid ${gray};
                    overflow: hidden;
                    text-align: center;
                    justify-content: center;

                }


            }

        }


    }


    @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 0;


        h2 {
            margin-bottom: 30px;
        }

        .address-wrapper {

            .single-item {
                margin-bottom: 25px;

            }
        }

        .width-fit {
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;
            display: block !important;
        }

        .dc-btn {
            max-width: 100% !important;
            min-width: 100% !important;
            width: 100% !important;

            a {
                max-width: 100% !important;
                width: 100% !important;
                min-width: 100% !important;
            }
        }

        .pr-0 {
            padding-left: 0 !important;
        }

        .left-col {
            padding-top: 60px;
            padding-left: 15px !important;
            padding-bottom: 40px;
        }

        .form_wrapper {
            padding: 60px 15px !important;
            margin-top: 0px;
        }

        .left-col {
            padding-right: 0px;
        }

        .container {
            padding: 0;
        }

        .width-fit {
            display: flex;
        }
    }
`;

export default React.memo(AddressContact);
