import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts} from '../../api/redux/about';
import {useDispatch, useSelector} from 'react-redux';
import {SplitText} from 'gsap/SplitText';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import AboutOvervew from './about-overview/Overview';
import AboutMision from './about-mision/TextColumn';
import {ApiParam} from '../../api/network/apiParams';
import {PageAnimation} from '../../lib/PageAnimation';
import {motion} from 'framer-motion';
import AboutCore from '@pages/about/about-core';
import AboutBanner from '@pages/about/about-banner';
import AboutBod from '@pages/about/about-bod';
gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const ABOUT = () => {


  const dispath = useDispatch();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'about-us',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));

  }, [dispath]);


  let getPost = useSelector(store => store.about);

  let page_data = getPost?.posts?.data?.page_data;


  const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-banner');
  const overview = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'overview');
  const mision_vision = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mission-vision');
  const makes = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'core-values');
  const directors = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-board-of-directors');
  const experts = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'our-experts');


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <motion.div key={'185'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>
        <AboutBanner noPara title={banner?.section_data?.subtitle} imageSrc={banner?.images?.list?.[0]?.full_path}/>

        {
          overview &&
          <AboutOvervew data={overview}/>

        }
        {
          mision_vision &&
          <AboutMision data={mision_vision}/>

        }
        {
          makes &&
          <AboutCore data={makes}/>

        }

        {
          directors &&
          <AboutBod directors={directors?.section_data?.title} data={directors}/>

        }
        {
          experts &&
          <AboutBod directors={experts?.section_data?.title} data={experts} padding={'pb-150'}/>
        }
      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`

`;

export default ABOUT;
