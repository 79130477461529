import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts, fetchPostsChild} from '../../api/redux/news';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {ApiParam} from '../../api/network/apiParams';
import BlogList from './news-list/BlogList';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../lib/PageAnimation';
import NewsBanner from '@pages/news/news-banner';
import NewsCta from '@pages/news/news-cta';
import reactHtmlParser from 'react-html-parser';

gsap.registerPlugin(CSSPlugin);

const Products = () => {

  const dispath = useDispatch();
  const location = useLocation();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let api_url_child = apiEndPoints.BLOGLIST;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'news-events',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };


    dispath(fetchPosts([api_url, param]));
    dispath(fetchPostsChild([api_url_child]));

  }, [dispath, location?.pathname]);


  let getPost = useSelector(store => store.news);

  let page_data = getPost?.posts?.data?.page_data;



  let child_data = getPost?.child?.data;
  const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'news-banner');
  const cta = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'cta-news');


  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? reactHtmlParser(page_data?.title) + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'184'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>
        <NewsBanner noPara title={banner?.section_data?.subtitle}
          imageSrc={banner?.images?.list?.[0]?.full_path}/>
        <BlogList data={child_data}/>
        <NewsCta cta={cta}/>

      </StyledComponent>
    </HelmetProvider>

  );
};

const StyledComponent = styled.section`


`;

export default Products;
