import styled, {themeGet} from '@styled';

export const StyledNavigationIcon = styled.div`
  position: relative;
  z-index: 10;
  ul {
    display: flex;
    li {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      &:nth-of-type(1) {
        margin-right: 20px;
      }

      svg {
        color: ${themeGet('colors.navigationblack')};
        z-index: 2;
        height: 45px;
        width: 45px;
        .Hover_Prev,
        .Hover_Next {
          transform: rotate(-90deg);
          stroke-dasharray: 0 260px;
          transform-origin: 50% 50%;
          will-change: stroke-dashoffset, transform;
        }

        g, rect {
          transition: ${themeGet('transition')};
        }
      }

      &:hover {
        .Hover_Prev {
          stroke-dasharray: 310px 310px;
          transform: none;
          opacity: 1;
        }

        .Hover_Next {
          stroke-dasharray: 310px 310px;
          transform: none;
          opacity: 1;
        }
      }
    }
      
      .hover_left:hover{
          #Rectangle_5479{
              rect{
                  rx: 25px
              }
          }
      }
      .hover_right:hover{
          #Rectangle_5479{
              rect{
                  rx: 25px
              }
          }
      }
  }
`;
