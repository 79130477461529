import React, {Fragment, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import GlobalStyle from '@assets/css/globalStyle';
import {ImageReveal} from '@components/mir-animations/imageReveal';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollSmoother} from 'gsap/ScrollSmoother';
import {SmootherContext} from '@components/SmootherContext';
import {ToastContainer} from 'react-toastify';
import CallButton from '@components/mir-ui/buttons/CallButton';
import Routes from '@routes/index';
import PageLoaderNew from '@components/mir-ui/loaders/PageLoaderNew';
import {useSelector} from 'react-redux';
import {SplitUp} from '@components/mir-animations/TextAnimation';
import {AnimatePresence} from 'framer-motion';
import BootstrapProvider from '@bootstrap-styled/provider';
import { ThemeProvider, theme } from '@styled';
import Menu from '@components/layouts/menu/desktop/Menu';
import useContainerOffset from '@hooks/useContainerOffset';
import useSmoothScroll from '@hooks/useSmoothScroll';
import useHideMenu from '@hooks/useHideMenu';
import MobileMenu from '@components/layouts/menu/mobile/Menu';
import useHideMenuMobile from '@hooks/useHideMenuMobile';
import useBodyClass from '@hooks/useBodyClass';
import useCanonicalLink from '@hooks/useCanonicalLink';


// import css
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'react-toastify/dist/ReactToastify.css';



gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
function App() {

  const location = useLocation();
  const main = useRef();
  const smoother = useRef();
  const store = useSelector(s => s);
  let getPost = useSelector(state => state.home);
  // smooth scroll
  useSmoothScroll(main);
  // route change scroll top & page class
  useBodyClass(location.pathname);
  SplitUp(store);
  ImageReveal(store);
  useHideMenu();
  useHideMenuMobile();
  useCanonicalLink();
  const containerGap = useContainerOffset('.container');


  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <BootstrapProvider reset={true}>
          <GlobalStyle/>
          <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
          {location?.pathname === '/' ? <PageLoaderNew data={getPost} /> : ''}
          <CallButton/>
          <Menu offset={containerGap}/>
          <MobileMenu/>
          <SmootherContext.Provider value={smoother.current}>
            <div className="App" ref={main} id="smooth-wrapper">
              <div id="smooth-content">
                <AnimatePresence exitBeforeEnter>
                  <Routes/>
                </AnimatePresence>
              </div>
            </div>
          </SmootherContext.Provider>
        </BootstrapProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
