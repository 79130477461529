import React from 'react';
import styled from 'styled-components';
import {Col} from 'react-bootstrap';
import {softGray, white} from '@assets/css/globalStyleVars';
import ReactHtmlParser from 'react-html-parser';
import ThemeRow from '../../../theme/row';

const AboutCore = ({padding, data}) => {

  const sectionData = data?.section_data;
  const servicesList = data?.posts?.list;

  return (
    <StyledOverview2 className={`${padding ? padding : 'pt-150 pb-150'}`}>
      <ThemeRow>
        <Col className={'title-content'} md={{span: 5, offset: 7}}>

          <h2 className={'split-up'}>{sectionData?.title ? sectionData?.title : ''}</h2>
          {
            sectionData?.description &&
            <p className={'p1-font split-up'}>{ReactHtmlParser(sectionData?.description)}</p>

          }
        </Col>
        <Col className={'description-content'} md={{span: 12}}>
          <ul>
            {
              servicesList && servicesList?.length > 0 &&
              servicesList.map((item, index) => {
                return (
                  <li key={index}>
                    <h4 className={'split-up'}>{item?.data?.title}</h4>
                    <p className={'split-up'}>{ReactHtmlParser(item?.data?.short_desc)}</p>
                  </li>

                );

              })
            }


          </ul>
        </Col>
      </ThemeRow>
    </StyledOverview2>
  );
};

const StyledOverview2 = styled.section`
    background-color: ${softGray};
    position: relative;
    background-image: url('/images/static/service_new_gradient.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .title-content {
        margin-bottom: 60px;

        h2, h3 {
            margin-bottom: 40px;
            color: ${white};
        }

        p {
            color: ${white};

        }
    }

    .description-content {
        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            li {
                width: calc(25% - 30px);
                flex: 0 0 calc(25% - 30px);
                border: 1px solid #FFFFFF;
                padding: 20px;

                @media (min-width: 1600px) {
                    min-height: 200px;
                }

                h4 {
                    margin-bottom: 25px;
                    color: ${white};
                    line-height: 24px;
                    @media (min-width: 1024px) {
                        max-width: 70%;
                    }

                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 18px;
                    color: ${white};

                }
            }
        }
    }


    @media (max-width: 992px) {
        .title-content {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 0 60px !important;
        }

        .description-content {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 !important;
        }
    }
    @media (max-width: 767px) {

        .title-content {
            max-width: 100%;
            flex: 0 0 100%;
            margin: 0 0 40px !important;
        }

        .description-content {
            ul {
                li {
                    width: calc(100% - 20px);
                    flex: 0 0 calc(100% - 20px);
                    margin-bottom: 10px;
                }
            }
        }

    }


`;

export default React.memo(AboutCore);




