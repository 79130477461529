import React from 'react';
import styled from 'styled-components';

import {downloadBg, hover, softGray, text} from '@assets/css/globalStyleVars';
import DownloadIcon from '@components/mir-ui/svg/DownloadIcon';


const DownloadBox = ({image, date, title, link}) => {


  return (
    <StyledComponent className="download_box">
      <a href={link ? link : ''} target="_blank" rel="noreferrer">
        <div className="download_box_wrapper">
          <div className="d-flex align-items-center download_box_wrapper_single justify-content-between">
            <div className="info">
              <p className="date">{date ? date : '30 Jun 2020'}</p>
              <p>{title ? title : 'Subscription Refund Money NRB Investors USD against 15240'}</p>
            </div>
            <div className="icon">
              <DownloadIcon/>
            </div>
          </div>
        </div>
      </a>
    </StyledComponent>
  );
};

const StyledComponent = styled.section`
    background: ${downloadBg};
    border-radius: 10px;
    transition: 0.7s all ease;

    .info {
        padding-right: 10px;
    }

    a {
        .download_box_wrapper {
            padding: 30px;

            .download_box_wrapper_single {
                p {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.2px;
                    color: ${text};
                    transition: 0.7s all ease;

                    &.date {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 21px;
                        color: rgba(24, 29, 36, 0.5);
                        margin-bottom: 8px;
                    }
                }

                #Rectangle_6585 {
                    transition: 0.7s all ease;
                }
            }
        }
    }

    &:hover {
        box-shadow: 0 5px 10px rgba(147, 148, 152, 0.3);

        p {
            color: ${hover}
        }

        #Rectangle_6585 {
            fill: ${softGray};
            rx: 50px;
        }
    }
`;

export default React.memo(DownloadBox);
