import { useEffect } from 'react';
import {useLocation} from 'react-router-dom';

const useCanonicalLink = () => {
  const location = useLocation();
  useEffect(() => {
    const currentUrl = window.location.origin + location.pathname + location.search;
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = currentUrl;
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [location.pathname, location.search]);
};

export default useCanonicalLink;
