import React from 'react';
import {StyledNavigationIcon} from './navigationIcon.style';
import PropTypes from 'prop-types';

const NavigationIcon = ({banner, navigationNext, navigationPrev, width, height, navigationNextClass, navigationPrevClass, onClickPrev, onClickNext}) => {


  return (
    <StyledNavigationIcon className={'navigation_button'}>
      <ul>
        <li className={`hover_left ${navigationPrevClass}`} id={navigationPrev ? navigationPrev : 'service-prev'}>
          {
            banner ?
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Group_21120" data-name="Group 21120" transform="translate(-388 -2131)">
                  <g id="Rectangle_5479" data-name="Rectangle 5479" transform="translate(388 2131)" fill="none" stroke="#e6e5df" strokeWidth="1">
                    <rect width="50" height="50" rx="5" stroke="none"/>
                    <rect x="0.5" y="0.5" width="49" height="49" rx="4.5" fill="none"/>
                  </g>
                  <g id="Group_18503" data-name="Group 18503" transform="translate(-174.734 1739.251) rotate(-45)">
                    <path id="Path_9568" data-name="Path 9568" d="M0,8,8,0l8,8" transform="translate(128.904 718.277) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                    <path id="Path_9569" data-name="Path 9569" d="M118.9,714.117v-16" transform="translate(2 4.16)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  </g>
                </g>
              </svg>

              :

              <svg id="Group_21123" data-name="Group 21123" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Rectangle_5479" data-name="Rectangle 5479" fill="none" stroke="#e6e5df" strokeWidth="1">
                  <rect width="50" height="50" rx="5" stroke="none"/>
                  <rect x="0.5" y="0.5" width="49" height="49" rx="4.5" fill="none"/>
                </g>
                <g id="Group_18503" data-name="Group 18503" transform="translate(35 26.314) rotate(140)">
                  <path id="Path_9568" data-name="Path 9568" d="M0,0,8,8l8-8" transform="translate(16 8) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  <path id="Path_9569" data-name="Path 9569" d="M0,0V16" transform="translate(8)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                </g>
              </svg>
          }
        </li>
        <li  className={`hover_right ${navigationNextClass}`} id={navigationNext ? navigationNext : 'service-next'}>

          {
            banner ?
              <svg id="Group_21121" data-name="Group 21121" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Rectangle_5479" data-name="Rectangle 5479" fill="none" stroke="#e6e5df" strokeWidth="1">
                  <rect width="50" height="50" rx="5" stroke="none"/>
                  <rect x="0.5" y="0.5" width="49" height="49" rx="4.5" fill="none"/>
                </g>
                <g id="Group_18503" data-name="Group 18503" transform="translate(25 13.686) rotate(45)">
                  <path id="Path_9568" data-name="Path 9568" d="M0,0,8,8l8-8" transform="translate(16 8) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  <path id="Path_9569" data-name="Path 9569" d="M0,0V16" transform="translate(8)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                </g>
              </svg>

              :

              <svg id="Group_21123" data-name="Group 21123" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <g id="Rectangle_5479" data-name="Rectangle 5479" fill="none" stroke="#e6e5df" strokeWidth="1">
                  <rect width="50" height="50" rx="5" stroke="none"/>
                  <rect x="0.5" y="0.5" width="49" height="49" rx="4.5" fill="none"/>
                </g>
                <g id="Group_18503" data-name="Group 18503" transform="translate(25 36.314) rotate(-135)">
                  <path id="Path_9568" data-name="Path 9568" d="M0,0,8,8l8-8" transform="translate(16 8) rotate(180)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                  <path id="Path_9569" data-name="Path 9569" d="M0,0V16" transform="translate(8)" fill="none" stroke="#c7c4b8" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                </g>
              </svg>
          }

        </li>
      </ul>
    </StyledNavigationIcon>
  );
};


// Define prop types for the component
NavigationIcon.propTypes = {
  navigationNext: PropTypes.string,
  navigationPrev: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// Set default props for the component
NavigationIcon.defaultProps = {
  width: '80',
  height: '80',
};

export default React.memo(NavigationIcon);
