import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import gsap, {CSSPlugin} from 'gsap';
import {apiEndPoints} from '../../api/network/apiEndPoints';
import {fetchPosts} from '../../api/redux/download';
import {useDispatch, useSelector} from 'react-redux';
import {SplitText} from 'gsap/SplitText';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {useLocation} from 'react-router-dom';
import DownloadList from './download-list/DownloadList';
import {ApiParam} from '../../api/network/apiParams';
import {motion} from 'framer-motion';
import {PageAnimation} from '../../lib/PageAnimation';
import DownloadsBanner from '@pages/downloads/downloads-banner';
import DownlaodCta from '@pages/downloads/downlaod-cta';

gsap.registerPlugin(ScrollTrigger, CSSPlugin, SplitText);

const ABOUT = () => {


  const dispath = useDispatch();
  const location = useLocation();


  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SECTIONS;
    let param = {
      [ApiParam.TYPE]: 'slug',
      [ApiParam.VALUE]: 'downloads',
      [ApiParam.GET_SECTION]: 'yes',
      [ApiParam.IMAGE]: 'yes',
      [ApiParam.POST]: 'yes',
      [ApiParam.GALLERY]: 'yes',
    };

    dispath(fetchPosts([api_url, param]));

  }, [dispath, location?.pathname]);

  let getPost = useSelector(store => store.download);

  let page_data = getPost?.posts?.data?.page_data;
  let download_list = getPost?.posts?.download_list;


  const banner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'download-banner');
  const cat = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'download-cta');

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{`${page_data?.title ? page_data?.title + ' - Mir Securities Ltd' : 'Mir Securities Ltd - Provides institutional brokerage services'}`}</title>
        {
          page_data?.meta_title &&
          <meta name="title" content={page_data?.meta_title ? page_data?.meta_title : 'Mir Securities Ltd'}/>

        }

        {
          page_data?.meta_description &&
          <meta name="description" content={page_data?.meta_description}/>

        }
        {
          page_data?.og_title &&
          <meta property="og:title" content={page_data?.og_title}/>

        }
        {
          page_data?.og_description &&
          <meta property="og:description" content={page_data?.og_description}/>

        }
      </Helmet>
      <motion.div key={'183'} className="page-loader pagetransition-loader" exit="exit" animate="anim"
        variants={PageAnimation}
        initial="init">
      </motion.div>
      <StyledComponent>
        <DownloadsBanner noPara title={banner?.section_data?.subtitle}
          imageSrc={banner?.images?.list?.[0]?.full_path}/>

        <DownloadList data={download_list}/>
        <DownlaodCta cta={cat} />

      </StyledComponent>
    </HelmetProvider>
  );
};

const StyledComponent = styled.section`

`;

export default ABOUT;
