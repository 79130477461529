import React from 'react';
import styled from 'styled-components';
import {serviceBg, white} from '@assets/css/globalStyleVars';
import {Link} from 'react-router-dom';
import UpIcon from '@components/mir-ui/svg/UpIcon';
import {themeGet} from '@styled-system/theme-get';

const ServiceSmall = ({title, bgColor, link, icon}) => {

  // variables


  return (

    <StyledServiceSmall bgColor={bgColor} className={'service-small-single-mir-parts'}>
      <Link to={link ? link : '/'}>
        <div className="service-small-single">
          <div className="icon">
            <UpIcon/>
          </div>
          <div className="icon-small">
            <img height={30} width={30} src={icon ? icon : ''} alt=""/>
          </div>
          <h3 className={'split-up'}>{title ? title : 'Title'}</h3>
        </div>
      </Link>
    </StyledServiceSmall>

  );
};


const StyledServiceSmall = styled.div`

    height: 100%;
    


    a {
        height: 100%;
        .service-small-single {
            background: ${props => props.bgColor ? props.bgColor : serviceBg};
            padding: 30px;
            border-radius: 10px;
            position: relative;
            transition: ${themeGet('transition')};
            height: 100%;
        

            .icon {
                position: absolute;
                right: 15px;
                top: 15px;

                svg {
                    box-shadow: rgba(24, 29, 36, 0.2) 5px 10px 30px 0;
                    border-radius: 10px;
                }
            }

            #Rectangle_6525, #Group_18503 {
                transition: ${themeGet('transition')};

            }

            h3 {
                color: ${white};
                margin-top: 30px;
            }
        }

        #Rectangle_6525, #Group_18503 {
            transition: ${themeGet('transition')};

        }

        &:hover {
            .service-small-single {
                box-shadow: 0 5px 10px rgba(147, 148, 152, 0.3);
            }

            #Rectangle_6525 {
                rx: 30px;
                //transform: translate(388px, 2181px) rotate(-90deg);
            }

            #Group_18503 {
            }

        }
    }

    @media (max-width: 767px) {
        a {
            .service-small-single {
                padding: 20px;
            }
        }
    }
`;

export default React.memo(ServiceSmall);














