import ErrorPage from '@components/mir-ui/error/404';
import gsap, {CSSPlugin} from 'gsap';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import React from 'react';

gsap.registerPlugin(CSSPlugin);

const Eror = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{'404 - Mir Securities Ltd - Provides institutional brokerage services'}</title>
        <meta name="title" content="404 - Mir Securities Ltd - Provides institutional brokerage services"/>
        <meta name="description" content="Welcome to MIR SECURITIES LTD. where financial expertise meets client commitment. As a
premier provider of securities services, we are dedicated to navigating the complexities of the
financial markets to empower our clients with tailored investment solutions and strategic
insights."/>
        <meta name="og:title" content="404 - Mir Securities Ltd - Provides institutional brokerage services"/>
        <meta name="og:description" content="Welcome to MIR SECURITIES LTD. where financial expertise meets client commitment. As a
premier provider of securities services, we are dedicated to navigating the complexities of the
financial markets to empower our clients with tailored investment solutions and strategic
insights."/>

      </Helmet>
      <ErrorPage/>
    </HelmetProvider>
  );


};


export default Eror;



