import React from 'react';
import styled from 'styled-components';
import {ImgLazyLoad} from '../images/ImgLazyLoad';
import ReactHtmlParser from 'react-html-parser';
import {themeGet} from '@styled-system/theme-get';

const ServiceBig = ({img, label, title, link}) => {

  // variables


  return (

    <StyledServiceBig className={'service-big-single-mir-parts'}>
      <a className={'wrapper_link'} target={'_blank'} href={link ? link : '/'} rel="noreferrer">

        <div className="image-wrapper">
          {
            img &&
            <ImgLazyLoad src={img ? img : '/images/dynamic/services-big@2x.jpg'} alt={''} radius={'10px'}/>

          }
          <div className="white-box">
            <div className={'d-flex align-items-end justify-content-between'}>
              {
                title &&
                <p className={'split-up'}>{title ? ReactHtmlParser(title) : ReactHtmlParser('Unlock Opportunities <br/>\n' +
                  '                                with Mir Securities Limited.')}</p>
              }

              {
                link && label &&
                <a className={'link'} target={'_blank'} href={link ? link : '/'} rel="noreferrer">
                  {label ? label : 'Open Account'}
                </a>
              }

            </div>
          </div>
        </div>
      </a>
    </StyledServiceBig>

  );
};


const StyledServiceBig = styled.div`
    height: 100%;
    width: 100%;

    .wrapper_link {
        position: relative;
        height: 100%;
        width: 100%;

    
        .global-image {
            img {
                transition: ${themeGet('transition')};
                transform: scale(1.01);
            }
        }

        &:hover {
            .link{
                text-decoration: none;
            }
            .global-image {
                img {
                    transform: scale(1.04);

                }
            }
        }
    }

    .image-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
        //padding-top: calc(380 / 470 * 100%);

        .white-box {
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            width: calc(100% - 60px);
            margin: 0 auto;
            padding: 30px 40px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.9);

            p {
                font-size: 24px;
                letter-spacing: 0.2px;
                line-height: 32px;
                font-weight: 500;
            }
        }

        @media (max-width: 992px) {
            padding-top: calc(380 / 470 * 100%);
        }

        @media (max-width: 767px) {
            .white-box {
                width: calc(100% - 30px);
                bottom: 15px;
                padding: 20px 15px;

                .d-flex {
                    flex-direction: column !important;
                    align-items: flex-start !important;

                    a {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
`;

export default React.memo(ServiceBig);














