import { useEffect } from 'react';

const useBodyClass = (pathname) => {
  useEffect(() => {
    if (pathname === '/') {
      document.body.classList.add('in-home-page');
      document.body.classList.remove('news-details');
    } else if (pathname.includes('/news/') || pathname.includes('/privacy-policy') || pathname.includes('/terms-and-conditions')) {
      document.body.classList.remove('in-home-page');
      document.body.classList.add('news-details');
    } else if (pathname === '/search') {
      document.body.classList.remove('in-home-page');
      document.body.classList.add('news-details');
      document.body.classList.add('search-page');
    } else {
      document.body.classList.remove('in-home-page');
      document.body.classList.remove('news-details');
      document.body.classList.remove('search-page');

    }

    // Always scroll to the top when pathname changes
    window.scroll(0, 0);
  }, [pathname]);
};

export default useBodyClass;
