import {useEffect} from 'react';
import {SplitText} from 'gsap/SplitText';
import {gsap, TimelineLite} from 'gsap';

export const AfterPageOpen = ({data, location}) => {


  useEffect(() => {
    let reveal = gsap.utils.toArray('.reveal');
    let revealRight = gsap.utils.toArray('.reveal-right');


    gsap.utils.toArray('.split-up').forEach((item, i) => {
      const parentSplit = new SplitText(item, {
        linesClass: 'split-parent'
      });

      gsap.from(parentSplit.lines, {
        duration: 1,
        yPercent: 100,
        // stagger: 0.1,
        scrollTrigger: {
          trigger: item,
          toggleActions: 'restart none none reset',
          // start: "top 60%",
          // markers: true
        }
      });
    });


    const box = document.querySelectorAll('.single-mir-parts');
    const boxplus = document.querySelectorAll('.boxr');
    const cta = document.querySelectorAll('.cta');
    const box_up = document.querySelectorAll('.single-mir-parts-up');
    const box_down = document.querySelectorAll('.single-mir-parts-down');


    if (window.innerWidth > 767) {
      box.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -150,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
      box_up.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -250,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
      box_down.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 250,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
      boxplus.forEach((el, index) => {
        gsap.fromTo(el, {
          y: 150,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
      cta.forEach((el, index) => {
        gsap.fromTo(el, {
          y: -50,
          ease: 'none',
        }, {
          y: 0,
          autoAlpha: 1,
          ease: 'power2',
          duration: 1,
          scrollTrigger: {
            id: `${index + 1}`,
            trigger: el,
            scrub: true
          }
        });
      });
    }


    reveal.forEach((cont) => {
      let img = cont.querySelector('img');
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: cont,
          toggleActions: 'restart none none reset',
        },
      });
      // parallax
      // if () {
      gsap.to(img, {
        yPercent: 15,
        ease: 'none',
        scrollTrigger: {
          trigger: cont,
          scrub: true
        }
      });
      // }

      // image reveal
      tl.fromTo(
        cont,
        1.5,
        {
          xPercent: -100,
          ease: 'Expo.easeInOut',
        },
        {
          xPercent: 0,
          ease: 'Expo.easeInOut',
        }
      );

      tl.fromTo(
        img,
        1.5,
        {
          xPercent: 100,
          ease: 'Expo.easeInOut',
          scale: 1.1,
        },
        {
          delay: -1.5,
          xPercent: 0,
          scale: 1,
          ease: 'Expo.easeInOut',
        }
      );
    });
    revealRight.forEach((cont) => {
      let img = cont.querySelector('img');
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: cont,
          toggleActions: 'restart none none reset',
        },
      });

      // parallax
      gsap.to(img, {
        yPercent: 15,
        ease: 'none',
        scrollTrigger: {
          trigger: cont,
          scrub: true
        }
      });

      // image reveal (right to left)
      tl.fromTo(
        cont,
        1.5,
        {
          xPercent: 100, // Change from -100 to 100
          ease: 'Expo.easeInOut',
        },
        {
          xPercent: 0,
          ease: 'Expo.easeInOut',
        }
      );

      tl.fromTo(
        img,
        1.5,
        {
          xPercent: -100, // Change from 100 to -100
          ease: 'Expo.easeInOut',
          scale: 1.1,
        },
        {
          delay: -1.5,
          xPercent: 0,
          scale: 1,
          ease: 'Expo.easeInOut',
        }
      );
    });
    let tl2 = new TimelineLite();

    document.body.classList.remove('menu-is-open');


    tl2.to('.main-menu__items__list', {
      duration: .2,
      y: '-40px',
      alpha: 0
    }).to('.main-menu__items', {
      opacity: '0',
      duration: '.3'
    }).to('.main-menu__items__img', {
      opacity: '0',
      duration: '.2'
    }, '-=.5')
      .to('.main-menu__items .left-side-mir-banner', {
        duration: .2,
        opacity: '0',
      }, '-=.3')
      .to('.main-menu__items .left-side-mir-banner .menu-mega-menu', {
        display: 'none',
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items__list', {
        duration: .2,
        y: '40px',
        alpha: 0,
      }, '-=.3').to('.main-menu__items', {
        duration: '.4',
        opacity: 0
      }).to('.main-menu__items .d-flex-menu-mir-parts', {
        display: 'none',
        duration: 0.5,
        paddingTop: 0,
        ease: 'power2.inOut'
      }, '-=0.5')
      .to('.main-menu__items__list ul', {
        height: 0,
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5').to('.main-menu__items__list ul li', {
        display: 'none',
        duration: 0.5,
        ease: 'power2.inOut'
      }, '-=0.5');
  }, [location, data]);


};