import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import {gray, hover, text, white} from '@assets/css/globalStyleVars';
import {gsap} from 'gsap';
import Social from '@components/social-icons/Social';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import LogoBlue from '@components/mir-ui/svg/LogoBlue';
import {VECTOR3} from '@api/network/baseUrl';
import {useDispatch, useSelector} from 'react-redux';
import {apiEndPoints} from '@api/network/apiEndPoints';
import {fetchPosts} from '@api/redux/footer';
import reactHtmlParser from 'react-html-parser';


gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

const FooterXom = (offset) => {


  useEffect(() => {

    // Add click event to all links
    const allLinks = document.querySelectorAll('a[href*="#"]');
    if (!allLinks.length) {
      return;
    }

    allLinks.forEach(link => {
      link.addEventListener('click', function (event) {
        // event.preventDefault(); // Prevent the default link behavior (navigating to the href)

        const href = link.getAttribute('href');
        if (!href || !href.includes('#')) {
          return;
        }

        const targetSelector = href.slice(href.indexOf('#')); // Extract the # value from the href
        const targetElement = document.querySelector(targetSelector);

        if (targetElement) {
          setTimeout(() => {


            // Revert body overflow to its original value on blur
            document.body.style.overflow = 'auto';
            const html = document.querySelector('html');
            html.style.overflow = 'auto';

            // Use GSAP ScrollTo plugin to smoothly scroll to the target section
            gsap.to(window, {
              duration: 1,
              scrollTo: {y: targetElement.offsetTop - 100} // Adjust the offset as needed
            });
          }, 600);
        }

        // if (!targetElement) {
        //     console.error(`Target element with selector '${targetSelector}' not found.`);
        //     // return;
        // }


      });
    });
  });

  const dispath = useDispatch();
  const location = useLocation();



  // api call
  useEffect(() => {
    let api_url = apiEndPoints.SETTINGS;
    dispath(fetchPosts([api_url]));

  }, [dispath, location?.pathname]);
  let getPost = useSelector(state => state.footer);

  const data = getPost?.posts?.data;


  return (
    <>

      <Footer className={'footer'}>

        <div className="footer__top">
          <div className={'bottom-image'}>
            <img src={VECTOR3}/>
          </div>
          <Container>
            <Row className={'align-items-end logo-wrapper-footer'}>
              <Col md={4} className={'logo-col'}>
                <LogoBlue/>
              </Col>
              <Col md={8}>
                <p className={'p1-font'}>{reactHtmlParser(data?.slogan)}</p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>


              <Col md={4}>
                <div className="footer__top__info">
                  <p className="phone">
                    <span>Visit</span>
                    <a href={data?.office_location_map} target={'_blank'} rel="noreferrer">
                      {reactHtmlParser(data?.office_location)}
                    </a>
                    <span>Call</span>
                    <a href={data?.office_phone}>
                      {data?.office_phone}
                    </a>
                    <span>Mail</span>
                    <a href={data?.contact_email}>
                      {data?.contact_email}
                    </a>

                  </p>


                </div>
              </Col>
              <Col md={2} className={'footer__top_col border'}>
                <div className="footer__top__menu">
                  <div className="hide-desktop">
                    {/*<p>Products</p>*/}
                    {/*<ul>*/}
                    {/*  <li><Link to={'/'}>SMS</Link></li>*/}
                    {/*  <li><Link to={'/'}>BEFTN</Link></li>*/}
                    {/*  <li><Link to={'/'}>DP</Link></li>*/}
                    {/*</ul>*/}
                    <p>Services</p>
                    <ul>
                      <li><Link to={'/services'}>IPO</Link></li>
                      <li><Link to={'/services'}>CTA</Link></li>
                      <li><Link to={'/services'}>MTA</Link></li>
                    </ul>
                  </div>

                </div>
              </Col>
              <Col md={3} className={'footer__top_col border'}>
                <div className="footer__top__menu">
                  <div className="hide-desktop">
                    <p>Menu</p>
                    <ul>
                      <li><Link to={'/about'}>About</Link></li>
                      <li><Link to={'/research'}>Research</Link></li>
                      <li><Link to={'/career'}>Career</Link></li>
                      <li><Link to={'/contacts'}>Contact</Link></li>
                    </ul>
                  </div>

                </div>

              </Col>
              <Col md={3} className={'footer__top_col'}>
                <div className="footer__top__menu">
                  <div className="hide-desktop">
                    <ul className={'privacy'}>
                      <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                      <li><Link to={'/terms-and-conditions'}>Terms and Conditions</Link></li>

                    </ul>
                    <div className={'share-icon-wrapper'}>
                      <p>Social</p>
                      <Social facebook={data?.facebook} youtube={data?.youtube} linkedin={data?.linkedin} instra={data?.instagram} twitter={data?.twitter} iconColor={white}/>
                    </div>

                  </div>

                </div>

              </Col>

            </Row>
          </Container>
        </div>
        <div className="footer__bottom">
          <Container>
            <Row>

              <Col md={6} className={'one'}>
                <p>© {new Date().getFullYear()} Mir Securities Limited.
                  <br/> All Rights Reserved.</p>

              </Col>
              <Col md={6} className={'two text-right'}>

                <p><a target={'blank'} href={'https://dcastalia.com'}>Design & Developed by
                  Dcastalia</a></p>
              </Col>

            </Row>
          </Container>
        </div>
      </Footer>
    </>
  );
};

const Footer = styled.section`
    position: relative;
    padding-top: 160px;
    z-index: 2;

    .content_notices {
        p {
            margin: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .footer {
        &__top {
            position: relative;

            .bottom-image {
                position: absolute;
                left: 0;
                bottom: 0;
            }

            .container {
                position: relative;
                padding: 0 15px 0;

            }

            .p1-font {
                color: ${gray};
            }

            .logo-wrapper-footer {
                margin-bottom: 60px;
            }

            &__info {
                p {
                    font-size: 14px; /* 15px / 15px = 1rem */
                    font-weight: 500;
                    line-height: 20px; /* 20px / 15px = 1.3333rem */
                    color: ${text};
                    letter-spacing: 0.2px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    span {
                        font-size: 14px; /* 15px / 15px = 1rem */
                        font-weight: 500;
                        line-height: 20px; /* 20px / 15px = 1.3333rem */
                        color: ${gray};
                        letter-spacing: 0.2px;
                        margin-bottom: 10px;
                        display: block;
                    }

                    a {
                        font-size: 14px; /* 15px / 15px = 1rem */
                        font-weight: 500;
                        line-height: 20px; /* 20px / 15px = 1.3333rem */
                        color: ${text};
                        letter-spacing: 0.2px;
                        display: inline-block;
                        margin-bottom: 25px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:hover {
                            color: ${hover};

                        }
                    }
                }
            }

            .border {
                border: none !important;
            }

            &__menu {
                p {
                    font-size: 14px; /* 15px / 15px = 1rem */
                    font-weight: 500;
                    line-height: 20px; /* 20px / 15px = 1.3333rem */
                    color: ${gray};
                    letter-spacing: 0.2px;
                    margin-bottom: 10px;
                    display: block;
                }

                ul {
                    margin-bottom: 45px;

                    li {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 14px; /* 15px / 15px = 1rem */
                            font-weight: 500;
                            line-height: 20px; /* 20px / 15px = 1.3333rem */
                            color: ${text};
                            letter-spacing: 0.2px;
                            display: inline-block;

                            &:hover {
                                color: ${hover};

                            }
                        }

                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .social {
                margin-bottom: 60px;
            }
        }

        &__bottom {
            margin-top: 60px;
            padding: 15px 0 70px;
            position: relative;

            .container {
                position: relative;

                &:after {
                    content: "";
                    height: 1px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: calc(100% - 30px);
                    top: -15px;
                    margin: 0 auto;
                    background: #939498;
                }
            }

            .footer_title_bottom {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: ${text};
                margin-bottom: 30px;
                display: block;
            }

            @media (min-width: 1024px) {
                .row {
                    display: flex;
                    align-items: flex-end;
                }
            }

            p {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: ${text};
            }

            a {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: ${text};
            }
        }
    }

    //responsive

    .hide-mobile {
        display: none;
    }


    @media (max-width: 767px) {
        padding-top: 115px;
        .logo-col {
            margin-bottom: 20px;
        }

        .footer__top_col {
            max-width: 50%;
            flex: 0 0 50%;

            &:last-child {
                margin-top: 35px;
                max-width: 100%;
                flex: 0 0 100%;

                .hide-desktop {
                    display: flex;
                    justify-content: space-between;

                    .privacy, .share-icon-wrapper {
                        max-width: calc(50% - 15px);
                        flex: 0 0 calc(50% - 15px);
                    }
                }
            }
        }

        .footer__top__menu ul {
            margin-bottom: 35px;
        }

        .footer__top__info {
            margin-bottom: 35px;
        }
    }

    .footer__bottom {
        padding-bottom: 60px;

        .col-md-6 {
            max-width: 50%;
            flex: 0 0 50%;
            text-align: left !important;
        }
    }

`;


export default React.memo(FooterXom);
