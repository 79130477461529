import React, {useState} from 'react';
import styled from 'styled-components';
import {softGray, white} from '@assets/css/globalStyleVars';
import {Img} from '../images/Img';
import PeoplePopup from '@components/mir-ui/modal/PeoplePopup';
import reactHtmlParser from 'react-html-parser';

const ConcernBox = ({img, tcolor, dcolor, title, link, des, logo, md, data , description}) => {

  // variables
  const [show, setShow] = useState(false);
  const [popupId, setPopupId] = useState();

  // showing modal
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setPopupId(e);
  };

  return (

    <StyledConcernBox tcolor={tcolor} dcolor={dcolor} className={'team-single-mir-parts'} md={md}>
      {/*modal */}
      {
        description &&
        <PeoplePopup show={show} data={popupId} handleClose={handleClose}/>

      }
      <div className="wrapper">

        {
          description ?

            md ?

              <div className={'pointer '} onClick={() => handleShow(data)}>
                <div className={'img-wrapper '}>
                  <Img radius={'10px'} src={img ? img : '/images/dynamic/smith@2x.jpg'}/>

                </div>
                <div className="information">
                  <h3 className={'split-up'}>{title ? title : 'Person Name'}</h3>
                  <p className={'split-up'}>{des ? reactHtmlParser(des) : 'Person Designation'}</p>
                </div>
              </div>

              :
              <div className={'pointer '} onClick={() => handleShow(data)}>
                <div className={'img-wrapper '}>
                  <Img radius={'10px'} src={img ? img : '/images/dynamic/smith@2x.jpg'}/>

                </div>
                <div className="information">
                  <h3 className={'split-up'}>{title ? title : 'Person Name'}</h3>
                  <p className={'split-up'}>{des ? reactHtmlParser(des) : 'Person Designation'}</p>
                </div>
              </div>

            :

            md ?

              <div className={' '}>
                <div className={'img-wrapper '}>
                  <Img radius={'10px'} src={img ? img : '/images/dynamic/smith@2x.jpg'}/>

                </div>
                <div className="information">
                  <h3 className={'split-up'}>{title ? title : 'Person Name'}</h3>
                  <p className={'split-up'}>{des ? reactHtmlParser(des) : 'Person Designation'}</p>
                </div>
              </div>

              :
              <div className={' '}>
                <div className={'img-wrapper '}>
                  <Img radius={'10px'} src={img ? img : '/images/dynamic/smith@2x.jpg'}/>

                </div>
                <div className="information">
                  <h3 className={'split-up'}>{title ? title : 'Person Name'}</h3>
                  <p className={'split-up'}>{des ? reactHtmlParser(des) : 'Person Designation'}</p>
                </div>
              </div>
        }



      </div>
    </StyledConcernBox>

  );
};


const StyledConcernBox = styled.div`
    .wrapper {
        position: relative;

        .pointer {
            cursor: pointer;

            &:hover {
                .img-wrapper {
                    .global-image img {
                        transform: scale(1.05);
                    }
                }
            }

        }

        .img-wrapper {
            padding-top: ${props => props.md ? 'calc(400 / 310 * 100%)' : 'calc(400 / 310 * 100%)'};
            position: relative;
            overflow: hidden;

            .global-image img {
                transform: ${props => props.md ? 'scale(1)' : 'scale(1.01)'};
                transition: all 0.7s ease-in;
                transform: scale(1);

            }

        }

        .information {
            margin-top: 20px;
            text-align: ${props => props.md ? 'left' : 'left'};

            h3 {
                color: ${props => props.tcolor ? props.tcolor : white};
                margin-bottom: 10px;
            }

            p {
                margin: 0 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                color: ${props => props.dcolor ? props.dcolor : softGray};
                text-transform: none;
            }
        }

    }

    .reveal {
        overflow: hidden;
    }
`;

export default React.memo(ConcernBox);














